const roles = {
  ADMIN: 'admin',
  DEVELOPER: 'developer',
  CLIENT: 'client',
  all() {
    const allRoles = Object.values(this);
    allRoles.pop();
    return allRoles;
  },
};

export default roles;
