import { testFieldsActions } from './testFieldsActions';

const initialState = {
  testFields: [],
  isFetchingTestFields: false,
  fetchTestFieldsError: null,
};

const testFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case testFieldsActions.START_FETCH_TEST_FIELDS:
      return { ...state, isFetchingTestFields: true };
    case testFieldsActions.SUCCESS_FETCH_TEST_FIELDS:
      return { ...state, testFields: action.payload, isFetchingTestFields: false };
    case testFieldsActions.ERROR_FETCH_TEST_FIELDS:
      return { ...state, fetchTestFieldsError: action.payload, isFetchingTestFields: false };
    default:
      return state;
  }
};

export default testFieldsReducer;
