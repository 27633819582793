import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { Button, Hint } from '../../shared/components';
import { alerts } from '../../shared/utils';
import PasswordService from '../services/passwordService';

import styles from './UserProfile.module.scss';

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFilled, setIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleCurrentPassword = () => {
    setShowCurrentPassword(current => !current);
  };

  const toggleNewPassword = () => {
    setShowNewPassword(current => !current);
  };

  const handleChangeCurrentPassword = event => {
    setCurrentPassword(event.target.value);
  };

  const handleChangeNewPassword = event => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmPassword = event => {
    setConfirmPassword(event.target.value);
  };

  const validate = () => {
    let valid = true;
    if (newPassword !== confirmPassword) {
      setErrorMessage('The passwords does not match.');
      valid = false;
    }

    if (newPassword === currentPassword) {
      setErrorMessage('The new password is the same as the current password entered.');
      valid = false;
    }

    return valid;
  };

  const cleanError = () => {
    setIsFilled(false);
    setErrorMessage('');
  };

  const changePassword = async e => {
    e.preventDefault();
    setIsLoading(true);
    const valid = validate();

    if (valid) {
      try {
        await PasswordService.changePassword(currentPassword.trim(), newPassword.trim());

        alerts.success('Password changed successfully.', () => {
          cleanError();
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        });
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    cleanError();
    if (currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0) {
      setIsFilled(true);
    }
  }, [currentPassword, newPassword, confirmPassword]);

  return (
    <form onSubmit={changePassword} style={{ textAlign: 'center' }}>
      <h5 className={styles.TitleForm}>
        Change password{' '}
        <Hint
          content={
            <>
              Changing the password here <strong>WILL</strong> change it in Kibana too in case you are using{' '}
              <strong>Elasticsearch security features</strong> or <strong>OpenDistro</strong>.
            </>
          }
        />
      </h5>
      <div className="row">
        <div className={styles.InputGroup}>
          <label htmlFor="current-password" className={styles.InputLabel}>
            Current Password
          </label>
          <input
            id="current-password"
            type={showCurrentPassword ? 'text' : 'password'}
            className="form-control"
            placeholder="Current Password"
            value={currentPassword}
            onChange={handleChangeCurrentPassword}
          />
          <button type="button" onClick={toggleCurrentPassword} className={styles.TogglePassword} tabIndex="-1">
            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>
      <div className="row">
        <div className={styles.InputGroup}>
          <label htmlFor="current-password" className={styles.InputLabel}>
            New Password
          </label>
          <input
            type={showNewPassword ? 'text' : 'password'}
            className="form-control"
            value={newPassword}
            onChange={handleChangeNewPassword}
            placeholder="New Password"
          />
          <button type="button" onClick={toggleNewPassword} className={styles.TogglePassword} tabIndex="-1">
            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>
      <div className="row">
        <div className={styles.InputGroup}>
          <label htmlFor="current-password" className={styles.InputLabel}>
            Confirm New Password
          </label>
          <input
            type={showNewPassword ? 'text' : 'password'}
            className="form-control"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
          />
        </div>
      </div>
      {!errorMessage || errorMessage === '' ? null : <div className={styles.ErrorMessage}>{errorMessage}</div>}
      <Button
        className={styles.changePasswordButton}
        text="Change Password"
        disabled={!isFilled || isLoading}
        isLoading={isLoading}
        loadingMessage="Saving..."
        submit
      />
    </form>
  );
};

export default ChangePasswordForm;
