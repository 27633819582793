import Application from '../modules/Application/Application';
import CompareScenarios from '../modules/CompareScenarios/CompareScenarios';
import Home from '../modules/Home/Home';
import Project from '../modules/Project/Project';
import EndpointDetailed from '../modules/Tests/EndpointDetailed/EndpointDetailed';
import Tests from '../modules/Tests/Tests';
import Transaction from '../modules/Transaction/Transaction';

import ApplicationBreadcrumb from './breadcrumbs/ApplicationBreadcrumb';
import ProjectBreadcrumb from './breadcrumbs/ProjectBreadcrumb';
import TransactionBreadcrumb from './breadcrumbs/TransactionBreadcrumb';

const routes = [
  {
    path: '/explorer',
    exact: true,
    component: Home,
    breadcrumb: 'Home',
  },
  {
    path: '/explorer/project/:projectId',
    exact: true,
    component: Project,
    breadcrumb: ProjectBreadcrumb,
    title: 'Project',
  },
  {
    path: '/explorer/project/:projectId/application/:applicationId',
    exact: true,
    component: Application,
    breadcrumb: ApplicationBreadcrumb,
    title: 'Application',
  },
  {
    path: '/explorer/project/:projectId/application/:applicationId/transaction/:transactionId',
    exact: true,
    component: Transaction,
    breadcrumb: TransactionBreadcrumb,
    title: 'Transaction',
  },
  {
    path: '/explorer/project/:projectId/application/:applicationId/transaction/:transactionId/tests',
    exact: true,
    component: Tests,
    breadcrumb: 'Scenario',
    keepQueryOnBreadcrumbLink: true,
  },
  {
    path: '/explorer/project/:projectId/application/:applicationId/transaction/:transactionId/tests/endpoint',
    exact: true,
    component: EndpointDetailed,
    breadcrumb: 'Endpoint Detail',
  },
  {
    path: '/explorer/project/:projectId/application/:applicationId/transaction/:transactionId/compare',
    exact: true,
    component: CompareScenarios,
    breadcrumb: 'Scenario Comparison',
  },
];

export default routes;
