import axios from 'axios';

import history from '../../history';
import { SessionActions } from '../../modules/user';

const unauthorizedInterceptor = store => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        store.dispatch(SessionActions.logout());
        history.push('/');
      }

      if (error.response && error.response.status === 403) {
        history.push('/');
      }

      if (error.response && error.response.status === 503) {
        history.push('/503');
      }

      if (error.response) {
        const { response } = error;
        return Promise.reject(response.data || response);
      }

      return Promise.reject(error);
    }
  );
};

export default unauthorizedInterceptor;
