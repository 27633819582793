import React from 'react';
import { PropTypes } from 'prop-types';

import { TableButton } from '../../../../shared/components';

import styles from './ScenariosLinks.module.scss';

const ScenariosLinks = ({ label, scenarios, getLinkToTests }) => {
  const { values, fields, dateRange } = scenarios;

  return (
    <div className={styles.Field}>
      <div className={styles.LabelContainer}>
        <span className={styles.Label}>{label}</span>
      </div>
      {values.map((scenario, index) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`${styles.ValueContainer}`}
          >
            <TableButton isLink icon="eye" to={getLinkToTests(scenario, fields, dateRange)} tooltip="Open scenario" />
          </div>
        );
      })}
    </div>
  );
};

ScenariosLinks.defaultProps = {
  scenarios: {},
  label: '',
};

ScenariosLinks.propTypes = {
  scenarios: PropTypes.object,
  label: PropTypes.string,
  getLinkToTests: PropTypes.func.isRequired,
};

export default ScenariosLinks;
