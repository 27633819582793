import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import Roles from '../../../../../containers/auth/Roles';
// eslint-disable-next-line import/no-cycle
import routes from '../../../../../containers/pages/routes';

import styles from './NavItemList.module.scss';

const NavItemsList = () => (
  <ul className={styles.NavItemsList}>
    {routes.map(
      item =>
        item.navbar && (
          <Roles key={item.path} admit={item.roles}>
            <li>
              <Link
                to={item.path}
                title={item.label}
                className={styles.NavButton}
                activeClassName={styles.Active}
                exact
              >
                <span className={styles.NavItem}>{item.label}</span>
              </Link>
            </li>
          </Roles>
        )
    )}
  </ul>
);

export default NavItemsList;
