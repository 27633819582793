import { createBrowserHistory } from 'history';

let basePath = '/';

if (process.env.NODE_ENV === 'production') {
  basePath = process.env.PUBLIC_URL;
}

const history = createBrowserHistory({ basename: basePath });

export default history;
