import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import routes from '../../../Explorer/routes';

import styles from './Breadcrumbs.module.scss';

const getPath = (match, location, keepQuery) => {
  let path = match.url || '';
  if (keepQuery) {
    path += location.search;
  }
  return path;
};

const Breadcrumbs = ({ breadcrumbs }) => {
  const isCurrent = index => {
    return index === breadcrumbs.length - 1;
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={`${styles.Breadcrumbs}`}>
          {breadcrumbs.map(({ breadcrumb, match, location, keepQueryOnBreadcrumbLink, title }, index) => (
            <React.Fragment key={match.url}>
              <Link
                to={getPath(match, location, keepQueryOnBreadcrumbLink)}
                className={`${styles.BreadcrumbContainer} ${isCurrent(index) ? styles.CurrentBreadcrumbContainer : ''}`}
              >
                {title && <div className={styles.BreadcrumbTitle}>{title}</div>}
                <div className={styles.Breadcrumb} key={match.url}>
                  <span className={styles.Content}>{breadcrumb}</span>
                </div>
              </Link>
              {!isCurrent(index) && <span className={`fa fa-angle-right ${styles.Separator}`}></span>}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

Breadcrumbs.defaultProps = {
  breadcrumbs: PropTypes.array,
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumbs);
