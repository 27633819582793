import axios from 'axios';

class VerifyAccountService {
  static async verifyToken(token) {
    const response = await axios.get(`confirmation/${token}`);

    return response.data.username;
  }
}

export default VerifyAccountService;
