import React from 'react';
import { orderBy } from 'lodash';
import { PropTypes } from 'prop-types';

import { alphabet, metrics, tables } from '../../../../../enums';
import { Table } from '../../../../shared/components';
import { calculatePercentageChange, parseIfNumber } from '../../../../shared/utils/utils';

import ComparisonCell from './ComparisonCell/ComparisonCell';

const getRowValue = (row, scenario, accessor) => {
  if (row[scenario]) {
    return row[scenario][accessor];
  }

  return 'No data';
};

const compareValues = (row, scenarios, accessor) => {
  if (row[scenarios[0]] && row[scenarios[1]]) {
    const baseline = row[scenarios[0]][accessor];
    const value = row[scenarios[1]][accessor];
    return calculatePercentageChange(baseline, value);
  }

  return 'No data';
};

const CompareMetricsTable = ({ data }) => {
  const columns = [
    {
      Header: metrics.THREADS.label,
      accessor: metrics.THREADS.accessor,
      width: 110,
      resizable: false,
      Cell: cell => parseIfNumber(cell.value),
    },
    {
      Header: `Scenario ${alphabet[0]} (baseline)`,
      columns: [
        {
          Header: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label,
          id: `scn_${alphabet[0]}_${metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor}`,
          accessor: row =>
            getRowValue(row, `Scenario ${alphabet[0]}`, metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell =>
            cell.value !== 'No data' ? metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter(cell.value) : cell.value,
        },
        {
          Header: metrics.TOTAL_SUCCESSFUL_TPS.label,
          id: `scn_${alphabet[0]}_${metrics.TOTAL_SUCCESSFUL_TPS.accessor}`,
          accessor: row => getRowValue(row, `Scenario ${alphabet[0]}`, metrics.TOTAL_SUCCESSFUL_TPS.accessor),
          Cell: cell => (cell.value !== 'No data' ? metrics.TOTAL_SUCCESSFUL_TPS.formatter(cell.value) : cell.value),
        },
        {
          Header: metrics.FAILED_REQUESTS_PERCENTAGE.label,
          id: `scn_${alphabet[0]}_${metrics.FAILED_REQUESTS_PERCENTAGE.accessor}`,
          accessor: row => getRowValue(row, `Scenario ${alphabet[0]}`, metrics.FAILED_REQUESTS_PERCENTAGE.accessor),
          Cell: cell =>
            cell.value !== 'No data' ? metrics.FAILED_REQUESTS_PERCENTAGE.formatter(cell.value) : cell.value,
        },
      ],
    },
    {
      Header: `Scenario ${alphabet[1]}`,
      columns: [
        {
          Header: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label,
          id: `scn_${alphabet[1]}_${metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor}`,
          accessor: row =>
            getRowValue(row, `Scenario ${alphabet[1]}`, metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell =>
            cell.value !== 'No data' ? metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter(cell.value) : cell.value,
        },
        {
          Header: metrics.TOTAL_SUCCESSFUL_TPS.label,
          id: `scn_${alphabet[1]}_${metrics.TOTAL_SUCCESSFUL_TPS.accessor}`,
          accessor: row => getRowValue(row, `Scenario ${alphabet[1]}`, metrics.TOTAL_SUCCESSFUL_TPS.accessor),
          Cell: cell => (cell.value !== 'No data' ? metrics.TOTAL_SUCCESSFUL_TPS.formatter(cell.value) : cell.value),
        },
        {
          Header: metrics.FAILED_REQUESTS_PERCENTAGE.label,
          id: `scn_${alphabet[1]}_${metrics.FAILED_REQUESTS_PERCENTAGE.accessor}`,
          accessor: row => getRowValue(row, `Scenario ${alphabet[1]}`, metrics.FAILED_REQUESTS_PERCENTAGE.accessor),
          Cell: cell =>
            cell.value !== 'No data' ? metrics.FAILED_REQUESTS_PERCENTAGE.formatter(cell.value) : cell.value,
        },
      ],
    },
    {
      Header: 'Comparison',
      columns: [
        {
          Header: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label,
          id: `scn_comparison_${metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor}`,
          accessor: row =>
            compareValues(
              row,
              [`Scenario ${alphabet[0]}`, `Scenario ${alphabet[1]}`],
              metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor
            ),
          style: { textAlign: 'center' },
          Cell: comparison => <ComparisonCell value={comparison.value} increaseIsPositive={false} />,
        },
        {
          Header: metrics.TOTAL_SUCCESSFUL_TPS.label,
          id: `scn_comparison_${metrics.TOTAL_SUCCESSFUL_TPS.accessor}`,
          accessor: row =>
            compareValues(
              row,
              [`Scenario ${alphabet[0]}`, `Scenario ${alphabet[1]}`],
              metrics.TOTAL_SUCCESSFUL_TPS.accessor
            ),
          style: { textAlign: 'center' },
          Cell: comparison => <ComparisonCell value={comparison.value} increaseIsPositive />,
        },
        {
          Header: metrics.FAILED_REQUESTS_PERCENTAGE.label,
          id: `scn_comparison_${metrics.FAILED_REQUESTS_PERCENTAGE.accessor}`,
          accessor: row =>
            compareValues(
              row,
              [`Scenario ${alphabet[0]}`, `Scenario ${alphabet[1]}`],
              metrics.FAILED_REQUESTS_PERCENTAGE.accessor
            ),
          style: { textAlign: 'center' },
          Cell: comparison => <ComparisonCell value={comparison.value} increaseIsPositive={false} />,
        },
      ],
    },
  ];

  return (
    <>
      <Table
        id={tables.COMPARE_METRICS}
        className="CompareMetricsTableTable"
        data={orderBy(data, [metrics.THREADS.accessor])}
        columns={columns}
        defaultPageSize={10}
      />
    </>
  );
};

CompareMetricsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CompareMetricsTable;
