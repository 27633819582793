import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { ApplicationsReducer } from './modules/Explorer/modules/Application';
import { ProjectsReducer } from './modules/Explorer/modules/Project';
import { TestFieldsReducer, TestsReducer } from './modules/Explorer/modules/Tests';
import { TransactionsReducer } from './modules/Explorer/modules/Transaction';
import { SessionReducer, UserReducer } from './modules/user';

const reducers = combineReducers({
  projects: ProjectsReducer,
  applications: ApplicationsReducer,
  transactions: TransactionsReducer,
  tests: TestsReducer,
  testFields: TestFieldsReducer,
  user: UserReducer,
  session: SessionReducer,
});

const store = createStore(reducers, compose(applyMiddleware(thunk)));

export default store;
