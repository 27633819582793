import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import { Spinner } from '../../../../shared/components';
import useQuery from '../../../../shared/hooks/useQuery/useQuery';
import setExplorerState from '../../../store/explorerActions';
import ScalabilityModel from '../ScalabilityModel/ScalabilityModel';
import TestsService from '../services/testsService';

import EndpointInfo from './EndpointInfo/EndpointInfo';
import ResponseCodeDetailedTable from './ResponseCodeDetailedTable/ResponseCodeDetailedTable';

import styles from './EndpointDetailed.module.scss';

const EndpointDetailed = ({ initExplorerState, history }) => {
  const [queryScenario] = useQuery('scenario', '');
  const urlParams = useParams();
  const { projectId, applicationId, transactionId } = urlParams;

  const [responseCodeData, setResponseCodeData] = useState({});

  const [isScalabilityDataLoading, setIsScalabilityDataLoading] = useState(true);
  const [scalabilityData, setScalabilityData] = useState([]);

  const [isResponseCodeDetailedDataLoading, setIsResponseCodeDetailedDataLoading] = useState(true);
  const [responseCodeDetailedData, setResponseCodeDetailedData] = useState({});

  const [selectedThreadGroup, setSelectedThreadGroup] = useState('');
  const [threadsGroups, setThreadGroups] = useState([]);

  const [scenario, setScenario] = useState({
    fields: [],
    values: {},
    dateRange: {},
    endpointLabel: '',
  });

  const isScenarioEndpointDetailedObjectValid = scenarioObject =>
    scenarioObject.fields && scenarioObject.values && scenarioObject.dateRange && scenarioObject.endpointLabel;

  const isScenarioObjectValid = scenarioObject =>
    scenarioObject.fields && scenarioObject.values && scenarioObject.dateRange;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (queryScenario.length > 0) {
      const decodedScenario = rison.decode(decodeURIComponent(queryScenario));

      if (isScenarioEndpointDetailedObjectValid(decodedScenario)) {
        setScenario(decodedScenario);
        initExplorerState(urlParams);
        return;
      }
      if (isScenarioObjectValid(decodedScenario)) {
        history.push(
          `/explorer/project/${projectId}/application/${applicationId}/transaction/${transactionId}/tests?scenario=${queryScenario}`
        );
        return;
      }
    }

    history.push(`/explorer/project/${projectId}/application/${applicationId}/transaction/${transactionId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getResponseCodeData = async () => {
      if (scenario.fields.length > 0) {
        const data = await TestsService.getEndpointDetailedResponseCode(
          projectId,
          applicationId,
          transactionId,
          scenario
        );

        setResponseCodeData(data);
      }
    };
    getResponseCodeData();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    const getScalability = async () => {
      setIsScalabilityDataLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getEndpointDetailedScalabilityData(
          projectId,
          applicationId,
          transactionId,
          scenario
        );

        setScalabilityData(data);
        setIsScalabilityDataLoading(false);
      }
    };
    getScalability();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    const getResponseCodeDetailed = async () => {
      setIsResponseCodeDetailedDataLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getEndpointDetailedResponseCodeDetailed(
          projectId,
          applicationId,
          transactionId,
          scenario
        );

        setResponseCodeDetailedData(data);
        setIsResponseCodeDetailedDataLoading(false);
      }
    };
    getResponseCodeDetailed();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    if (!isEmpty(responseCodeData)) {
      const keys = Object.keys(responseCodeData);
      setThreadGroups(keys);
      setSelectedThreadGroup(keys[0]);
    }
  }, [responseCodeData]);

  const onHeaderThreadGroupChange = threadGroup => {
    setSelectedThreadGroup(threadGroup);
  };

  return (
    <>
      <section title={scenario.endpointLabel} className={styles.Header}>
        <h4 className="page-title">Endpoint: {scenario.endpointLabel}</h4>
      </section>

      <h4 className="page-subtitle">Scalability Model</h4>
      <section className={`${styles.ChartContainer} box-container`}>
        <div className={styles.ScalabilityModelWrapper}>
          {isScalabilityDataLoading ? <Spinner /> : <ScalabilityModel data={scalabilityData} />}
        </div>
      </section>

      <h4 className="page-subtitle">Detailed Data</h4>
      <section className={styles.DetailedInfo}>
        <div className={styles.SelectorContainer}>
          <h5 className="title-form">Virtual Users</h5>
          <DropdownList
            className={`${styles.virtualDropdown} mb-4 whiteDropdown`}
            data={threadsGroups}
            value={selectedThreadGroup}
            onChange={onHeaderThreadGroupChange}
            globalKeyEvents
          />
        </div>
        <div className={`${styles.EndpointInfoWrapper}`}>
          <div className={styles.EndpointInfo}>
            {isScalabilityDataLoading ? (
              <Spinner />
            ) : (
              <EndpointInfo
                scalabilityData={scalabilityData}
                responseCodeData={responseCodeData}
                endpointLabel={scenario.endpointLabel}
                selectedThreadGroup={selectedThreadGroup}
                threadGroups={threadsGroups}
                onThreadGroupChange={onHeaderThreadGroupChange}
              />
            )}
          </div>
        </div>

        <div className={`${styles.TableWrapper} box-container`}>
          {isResponseCodeDetailedDataLoading ? (
            <Spinner />
          ) : (
            <ResponseCodeDetailedTable data={responseCodeDetailedData} selectedThreadGroup={selectedThreadGroup} />
          )}
        </div>
      </section>
    </>
  );
};

EndpointDetailed.propTypes = {
  initExplorerState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  testFields: state.testFields.testFields,
  isFetchingTestFields: state.testFields.isFetchingTestFields,
});

const mapDispatchToProps = dispatch => ({
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndpointDetailed);
