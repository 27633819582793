import TransactionsService from '../services/transactionsService';

export const transactionsActions = {
  START_FETCH_TRANSACTIONS: '[TRANSACTIONS] Start fetching transactions',
  SUCCESS_FETCH_TRANSACTIONS: '[TRANSACTIONS] Success fetching transactions',
  ERROR_FETCH_TRANSACTIONS: '[TRANSACTIONS] Error fetching transactions',
  CLEAR_TRANSACTIONS: '[TRANSACTIONS] Clear transactions',
  SET_CURRENT_TRANSACTION: '[TRANSACTIONS] Set current transaction',
};

export function startFetchTransactions() {
  return {
    type: transactionsActions.START_FETCH_TRANSACTIONS,
  };
}

export function successFetchTransactions(transactions) {
  return {
    type: transactionsActions.SUCCESS_FETCH_TRANSACTIONS,
    payload: transactions,
  };
}

export function errorFetchTransactions(error) {
  return {
    type: transactionsActions.ERROR_FETCH_TRANSACTIONS,
    payload: error,
  };
}

export function clearTransactions() {
  return {
    type: transactionsActions.CLEAR_TRANSACTIONS,
  };
}

export function fetchTransactions(projectId, applicationId) {
  return dispatch => {
    dispatch(startFetchTransactions());
    return TransactionsService.getTransactionsByApplicationId(projectId, applicationId)
      .then(transactions => dispatch(successFetchTransactions(transactions)))
      .catch(error => dispatch(errorFetchTransactions(error)));
  };
}

export function setCurrentTransaction(projectId, applicationId, transactionId) {
  return dispatch => {
    return TransactionsService.getTransactionById(projectId, applicationId, transactionId)
      .then(transaction =>
        dispatch({
          type: transactionsActions.SET_CURRENT_TRANSACTION,
          payload: transaction,
        })
      )
      .catch(() => {});
  };
}
