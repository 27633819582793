import React from 'react';
import { endOfDay } from 'date-fns';
import { PropTypes } from 'prop-types';

import { tables } from '../../../../../enums';
import { FavoriteButton, Hint, ScenarioFields, Table, TableButton } from '../../../../shared/components';
import { formatDateToLocale } from '../../../../shared/utils/utils';

import styles from './FavoriteScenarios.module.scss';

const FavoriteScenarios = ({ favoriteScenarios, getLinkToTests, openModal, handlerDeleteFavoriteScenario }) => {
  const getScenarioLink = scenario => {
    const { values, fields } = scenario;
    return getLinkToTests(values, fields, {
      startDate: new Date(0),
      endDate: endOfDay(new Date()),
      key: 'selection',
    });
  };

  const columns = [
    {
      id: 'scenario',
      Header: 'Scenario name',
      headerClassName: 'justify-content-start',
      filterable: true,
      accessor: ({ scenario, name, description, createdAt }) => ({
        scenario,
        name,
        description,
        createdAt,
      }),
      Cell: cell => (
        <div className={`${styles.Wrapper} box-container`}>
          <div className={styles.Header}>
            <span className={styles.NameDescContainer}>
              <h3 className={styles.Name}>{cell.value.name}</h3>
              <Hint content={cell.value.description} />
            </span>
            <span title="Creation Date" className={styles.CreatedAt}>
              Created: {formatDateToLocale(new Date(cell.value.createdAt))}
            </span>
          </div>

          <div className={styles.Content}>
            <ScenarioFields scenario={cell.original} />
          </div>
        </div>
      ),
      filterMethod: (filterText, currentField) => {
        const currentFieldName = currentField.scenario.name.toLowerCase();
        return currentFieldName.includes(filterText.value.toLowerCase());
      },
      // eslint-disable-next-line react/prop-types
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          // eslint-disable-next-line react/prop-types
          value={filter ? filter.value : ''}
          className={styles.FilterInput}
        />
      ),
    },
    {
      id: 'actions',
      Header: 'Actions',
      headerClassName: 'disabled-filter',
      sortable: false,
      filterable: false,
      width: 200,
      resizable: false,
      className: 'table-action-cell',
      Cell: cell => (
        <>
          <TableButton
            isLink
            icon="eye"
            to={getScenarioLink(cell.original)}
            tooltip={`Open ${cell.original.name} scenario`}
          />
          <FavoriteButton
            scenario={cell.original}
            openModal={openModal}
            handlerDeleteFavoriteScenario={handlerDeleteFavoriteScenario}
          />
        </>
      ),
    },
  ];

  return <Table id={tables.FAVORITE_SCENARIOS} data={favoriteScenarios} columns={columns} filterable />;
};

FavoriteScenarios.propTypes = {
  favoriteScenarios: PropTypes.array.isRequired,
  getLinkToTests: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  handlerDeleteFavoriteScenario: PropTypes.func.isRequired,
};

export default FavoriteScenarios;
