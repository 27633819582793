import axios from 'axios';

class TransactionsService {
  static async getTransactionsByApplicationId(projectId, applicationId) {
    const response = await axios.get(`projects/${projectId}/applications/${applicationId}/transactions`);

    return response.data.transactions;
  }

  static async getTransactionById(projectId, applicationId, transactionId) {
    const response = await axios.get(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}`
    );
    return response.data.transaction;
  }

  static async createTransaction(name, projectId, applicationId) {
    const newTransaction = {
      name,
    };
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions`,
      newTransaction
    );
    if (!response.data.ok) {
      throw response.data;
    }
    return response.data.transaction;
  }

  static async deleteTransactions(projectId, applicationId, transactionId) {
    const response = await axios.delete(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}`
    );
    if (!response.data.ok) {
      throw response.data;
    } else {
      return true;
    }
  }
}

export default TransactionsService;
