import TestFieldsService from '../services/testFieldsService';

export const testFieldsActions = {
  START_FETCH_TEST_FIELDS: '[TEST_FIELDS] Start fetching test fields',
  SUCCESS_FETCH_TEST_FIELDS: '[TEST_FIELDS] Success fetching test fields',
  ERROR_FETCH_TEST_FIELDS: '[TEST_FIELDS] Error fetching test fields',
};

export function startFetchTestFields() {
  return {
    type: testFieldsActions.START_FETCH_TEST_FIELDS,
  };
}

export function successFetchTestFields(testFields) {
  return {
    type: testFieldsActions.SUCCESS_FETCH_TEST_FIELDS,
    payload: testFields,
  };
}

export function errorFetchTestFields(error) {
  return {
    type: testFieldsActions.ERROR_FETCH_TEST_FIELDS,
    payload: error,
  };
}

export function fetchTestFields(projectId) {
  return dispatch => {
    dispatch(startFetchTestFields());
    return TestFieldsService.getTestFields(projectId)
      .then(testFields => dispatch(successFetchTestFields(testFields)))
      .catch(error => dispatch(errorFetchTestFields(error)));
  };
}
