import { ApplicationsActions } from '../modules/Application';
import { ProjectsActions } from '../modules/Project';
import { TestFieldsActions, TestsActions } from '../modules/Tests';
import { TransactionsActions } from '../modules/Transaction';

const setExplorerState = urlParams => {
  return (dispatch, getState) => {
    const { projectId, applicationId, transactionId } = urlParams;
    const { currentProject: project } = getState().projects;
    const { currentApplication: application } = getState().applications;
    const { currentTransaction: transaction } = getState().transactions;

    if (projectId && (!project || project._id !== projectId)) {
      dispatch(ProjectsActions.setCurrentProject(projectId));
    }

    if (applicationId && (!application || application._id !== applicationId)) {
      dispatch(ApplicationsActions.setCurrentApplication(projectId, applicationId));
    }

    if (transactionId && (!transaction || transaction._id !== transactionId)) {
      dispatch(TransactionsActions.setCurrentTransaction(projectId, applicationId, transactionId));
      dispatch(TestFieldsActions.fetchTestFields(projectId));
      dispatch(TestsActions.fetchFavoriteScenarios(projectId, applicationId, transactionId));
    }
  };
};

export default setExplorerState;
