import React from 'react';

import styles from './BreadcrumbSpinner.module.scss';

const BreadcrumbSpinner = () => (
  <div className={styles.container}>
    <div className={styles.loader}>Loading...</div>
  </div>
);

export default BreadcrumbSpinner;
