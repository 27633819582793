import TestsService from '../services/testsService';

export const testsActions = {
  START_FETCH_TESTS: '[TESTS] Start fetching tests',
  SUCCESS_FETCH_TESTS: '[TESTS] Success fetching tests',
  ERROR_FETCH_TESTS: '[TESTS] Error fetching tests',
  SUCCESS_FETCH_SHARED_TESTS: '[SHARED_TESTS] Success fetching shared tests',
  ERROR_FETCH_SHARED_TESTS: '[SHARED_TESTS] Error fetching shared tests',
  CLEAR_TESTS: '[TESTS] Clear tests',
  START_FETCH_FAVORITE_SCENARIOS: '[FAVORITESCENARIOS] Start fetching favorite scenarios',
  SUCCESS_FETCH_FAVORITE_SCENARIOS: '[FAVORITESCENARIOS] Success fetching favorite scenarios',
  ERROR_FETCH_FAVORITE_SCENARIOS: '[FAVORITESCENARIOS] Error fetching favorite scenarios',
};

export function startFetchTests() {
  return {
    type: testsActions.START_FETCH_TESTS,
  };
}

export function successFetchTests(tests) {
  return {
    type: testsActions.SUCCESS_FETCH_TESTS,
    payload: tests,
  };
}

export function errorFetchTests(error) {
  return {
    type: testsActions.ERROR_FETCH_TESTS,
    payload: error,
  };
}

export function successFetchSharedTests(testsShared) {
  return {
    type: testsActions.SUCCESS_FETCH_SHARED_TESTS,
    payload: testsShared,
  };
}

export function errorFetchSharedTests(error) {
  return {
    type: testsActions.ERROR_FETCH_SHARED_TESTS,
    payload: error,
  };
}

export function clearTests() {
  return {
    type: testsActions.CLEAR_TESTS,
  };
}

export function startFetchFavoriteScenarios() {
  return {
    type: testsActions.START_FETCH_FAVORITE_SCENARIOS,
  };
}
export function successFetchFavoriteScenarios(favoriteScenarios) {
  return {
    type: testsActions.SUCCESS_FETCH_FAVORITE_SCENARIOS,
    payload: favoriteScenarios,
  };
}

export function errorFetchFavoriteScenarios(error) {
  return {
    type: testsActions.ERROR_FETCH_FAVORITE_SCENARIOS,
    payload: error,
  };
}

export function fetchTests(projectId, applicationId, transactionId, currentScenario, dateRange) {
  return dispatch => {
    dispatch(startFetchTests());
    return TestsService.groupTests(projectId, applicationId, transactionId, currentScenario.fields, dateRange)
      .then(tests => dispatch(successFetchTests(tests)))
      .catch(error => dispatch(errorFetchTests(error)));
  };
}

export function fetchFavoriteScenarios(projectId, applicationId, transactionId) {
  return dispatch => {
    dispatch(startFetchFavoriteScenarios());
    return TestsService.getAllScenarios(projectId, applicationId, transactionId)
      .then(favoriteScenarios => dispatch(successFetchFavoriteScenarios(favoriteScenarios)))
      .catch(error => dispatch(errorFetchFavoriteScenarios(error)));
  };
}
