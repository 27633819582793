import axios from 'axios';

class ProjectsService {
  static async getProjects() {
    const response = await axios.get('projects');
    const { projects } = response.data;
    return projects;
  }

  static async getProjectById(projectId) {
    const response = await axios.get(`projects/${projectId}`);
    const { project } = response.data;
    return project;
  }

  static async createProject(name, createKibanaDashboards) {
    const newProject = {
      name,
      createKibanaDashboards,
    };
    const response = await axios.post('projects', newProject);
    if (!response.data.ok) {
      throw response.data;
    }
    return response.data.project;
  }

  static async deleteProject(projectId) {
    const response = await axios.delete(`projects/${projectId}`);
    if (!response.data.ok) {
      throw response.data;
    } else {
      return true;
    }
  }

  static async shareProject(projectId, username) {
    const response = await axios.post(`projects/${projectId}/shared`, { username });
    if (!response.data.ok) {
      throw response.data;
    }

    return response.data;
  }
}

export default ProjectsService;
