/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Breadcrumbs } from '../shared/components';

import routes from './routes';

import styles from './Explorer.module.scss';

const Explorer = () => {
  return (
    <div className={styles.MainContent}>
      <Breadcrumbs />
      <Switch>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

Explorer.defaultProps = {};

Explorer.propTypes = {};

export default Explorer;
