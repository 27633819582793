import axios from 'axios';

class SignUpService {
  static async signUp(username, email, password) {
    const newUser = {
      username,
      password,
      email,
    };
    const response = await axios.post('user', { newUser });

    if (response.data.error) {
      throw response.data.error;
    }

    return response.data;
  }
}

export default SignUpService;
