import React from 'react';
import { Tab as PackageTab, TabList, Tabs as PackageTabs } from 'react-tabs';
import { PropTypes } from 'prop-types';

import './Tabs.scss';

const Tabs = ({ children: tabs, titleSize }) => {
  return (
    <PackageTabs>
      <TabList>
        {tabs.map(tab => (
          <PackageTab key={tab.props.tabTitle} disabled={tab.props.disabled} style={{ fontSize: `${titleSize}px` }}>
            {tab.props.tabTitle}
          </PackageTab>
        ))}
      </TabList>
      {tabs}
    </PackageTabs>
  );
};

Tabs.defaultProps = {
  titleSize: 24,
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  titleSize: PropTypes.number,
};

export default Tabs;
