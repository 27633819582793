import axios from 'axios';

class UserService {
  static async getClients() {
    const response = await axios.get('clients');

    if (response.data.error) {
      throw response.data.error;
    }

    const { clients } = response.data;

    return clients;
  }

  static async createClient(username, email, password) {
    const newUser = {
      username,
      password,
      email,
    };

    const response = await axios.post('clients', { newUser });

    if (response.data.error) {
      throw response.data.error;
    }

    const { client } = response.data;
    return client;
  }

  static async update(updateObject) {
    const response = await axios.put('user', updateObject);

    if (response.data.error) {
      throw response.data.error;
    }
  }

  static async getCurrentUser() {
    const response = await axios.get('myself');
    const { user } = response.data;
    return user;
  }
}

export default UserService;
