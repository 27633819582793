import React from 'react';
import { connect } from 'react-redux';

import { BreadcrumbSpinner } from '../../../shared/components';

const ApplicationBreadcrumb = ({ application }) => {
  if (!application.name) {
    return <BreadcrumbSpinner />;
  }

  return application.name;
};

const mapStateToProps = state => ({
  application: state.applications.currentApplication,
});

export default connect(mapStateToProps)(ApplicationBreadcrumb);
