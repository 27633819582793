/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';

import ClientsManager from './ClientsManager/ClientsManager';

import styles from './Configuration.module.scss';

const Configuration = () => {
  return (
    <div className={styles.MainContent}>
      <div className={`${styles.MainCardsWrapper} col-md-12`}>
        <ClientsManager />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(Configuration);
