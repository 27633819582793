import React from 'react';
import Icon from 'react-fa';
import { isString } from 'lodash';
import { PropTypes } from 'prop-types';

import { parseIfNumber } from '../../../../../shared/utils/utils';

import styles from './ComparisonCell.module.scss';

const ComparisonCell = ({ value, increaseIsPositive }) => {
  const increase = value > 0;

  const getColor = () => {
    if (value === 0) return '#9E9E9E';
    if ((increaseIsPositive && increase) || (!increaseIsPositive && !increase)) return '#14cc45';
    if ((increaseIsPositive && !increase) || (!increaseIsPositive && increase)) return '#eb2617';

    return '#000';
  };

  const getIcon = () => {
    if (value === 0) return 'arrow-right';
    if (increase) return 'arrow-up';
    if (!increase) return 'arrow-down';

    return 'arrow-up';
  };

  return (
    <div className={styles.Cell}>
      {isString(value) ? (
        <span className={styles.NoData}>No data</span>
      ) : (
        <div className={styles.ValueContainer}>
          <div className={styles.Arrow} style={{ color: getColor() }}>
            <Icon name={getIcon()} />
          </div>
          <span className={styles.Value} style={{ color: getColor() }}>
            {parseIfNumber(Math.abs(value))}%
          </span>
        </div>
      )}
    </div>
  );
};

ComparisonCell.defaultProps = {
  increaseIsPositive: true,
  value: undefined,
};

ComparisonCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  increaseIsPositive: PropTypes.bool,
};

export default ComparisonCell;
