import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'react-fa';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { version } from '../../../../../../package.json';

import styles from './NavUserOptions.module.scss';

function useOutsideListener(ref, setter) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setter(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const NavUserOptions = props => {
  const [showOptions, setShowOptions] = useState(false);
  const { username, signOut } = props;
  const wrapperRef = useRef(null);
  useOutsideListener(wrapperRef, setShowOptions);

  return (
    <div className={styles.headerLeft}>
      <div ref={wrapperRef} className={styles.MenuContainer} role="presentation">
        <button type="button" className={styles.Button} onClick={() => setShowOptions(!showOptions)}>
          <div className={`${styles.UserNameContainer}`}>
            <span className={styles.photo}>
              <Icon className={`${styles.UserIcon}`} name="user" />
            </span>
            <div className={styles.user}>
              <span>Welcome</span>
              <span className={styles.NavItem}>{username}</span>
            </div>
          </div>
          <Icon className={`${styles.arrowIcon} icon`} name={showOptions ? 'angle-up' : 'angle-down'} />
        </button>

        <ul className={`${styles.List} ${showOptions && styles.ListOpen}`}>
          <li>
            <Link className={`${styles.RouterLink} ${styles.UserMenu}`} to="/profile">
              Profile
            </Link>
          </li>
          <li>
            <Link className={`${styles.RouterLink} ${styles.UserMenu}`} to="/" onClick={() => signOut()}>
              Sign out
            </Link>
          </li>
          <li className={styles.Version}>
            <span>v{version}</span>
          </li>
        </ul>
      </div>
      <div className={styles.signOut}>
        <Link to="/" className="fa fa-sign-out" onClick={() => signOut()}></Link>
      </div>
    </div>
  );
};

NavUserOptions.propTypes = {
  signOut: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default NavUserOptions;
