import React from 'react';
import Icon from 'react-fa';
import ReactTooltip from 'react-tooltip';
import { uniqueId } from 'lodash';
import { PropTypes } from 'prop-types';

import { appColors } from '../../../../enums';

import styles from './Hint.module.scss';

const Hint = ({ content }) => {
  const id = uniqueId('hint');
  return (
    <>
      <Icon data-tip data-for={id} className={styles.Icon} name="question-circle-o" />
      <ReactTooltip id={id} className={styles.Tooltip} place="right" effect="solid" backgroundColor={appColors.primary}>
        <p className={styles.TooltipParagraph}>{content}</p>
      </ReactTooltip>
    </>
  );
};

Hint.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Hint;
