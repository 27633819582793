import axios from 'axios';

class TestFields {
  static async getTestFields(projectId) {
    const response = await axios.get(`projects/${projectId}/test-fields`);

    return response.data.fields;
  }

  static async createTestField(testFieldData, projectId) {
    const response = await axios.post(`projects/${projectId}/test-fields`, testFieldData);

    return response.data.testField;
  }

  static async delete(testFieldId, projectId) {
    const response = await axios.delete(`projects/${projectId}/test-fields/${testFieldId}`);

    return response.data;
  }
}

export default TestFields;
