import { roles } from '../../enums';
import Configuration from '../../modules/Configuration/Configuration';
import Explorer from '../../modules/Explorer/Explorer';
// eslint-disable-next-line import/no-cycle
import withNavbar from '../../modules/shared/hoc/withNavbar';
import UserProfile from '../../modules/user/UserProfile/UserProfile';

const routes = [
  {
    path: '/explorer',
    component: withNavbar(Explorer),
    roles: roles.all(),
    navbar: true,
    label: 'Home',
    icon: 'book',
  },
  {
    path: '/configuration',
    component: withNavbar(Configuration),
    roles: [roles.DEVELOPER],
    navbar: true,
    label: 'Clients manager',
    icon: 'cog',
  },
  {
    path: '/profile',
    component: withNavbar(UserProfile),
    roles: roles.all(),
  },
];

export default routes;
