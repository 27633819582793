/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { intersection } from 'lodash';
import { PropTypes } from 'prop-types';

const PrivateRoute = ({ component: RenderComponent, user, roles, ...rest }) => {
  if (!user || (roles.length > 0 && !intersection(user.roles, roles).length)) {
    return <Redirect to="/" />;
  }

  return (
    <Route {...rest}>
      <RenderComponent {...rest} />
    </Route>
  );
};

PrivateRoute.defaultProps = {
  user: null,
  component: null,
  roles: [],
};

PrivateRoute.propTypes = {
  user: PropTypes.object,
  component: PropTypes.elementType,
  roles: PropTypes.array,
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(PrivateRoute);
