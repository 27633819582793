import React from 'react';
import PureSwal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { capitalize } from './utils';

import '../styles/alerts.scss';

const Swal = withReactContent(PureSwal);

export const success = (message, onClose = () => {}) =>
  Swal.fire({
    icon: 'success',
    title: `Success`,
    html: message,
    timer: 2000,
    showCloseButton: true,
    timerProgressBar: true,
    onOpen: alert => {
      alert.addEventListener('mouseenter', Swal.stopTimer);
      alert.addEventListener('mouseleave', Swal.resumeTimer);
    },
  }).then(() => {
    onClose();
  });

export const error = message =>
  Swal.fire({
    icon: 'error',
    title: 'Something went wrong!',
    html: message,
    showCloseButton: true,
  });

export const info = (message, title = 'Info', onClose = () => {}) =>
  Swal.fire({
    icon: 'info',
    title,
    html: message,
    showCloseButton: true,
  }).then(() => {
    onClose();
  });

export const warning = (message, onClose = () => {}) =>
  Swal.fire({
    icon: 'warning',
    title: 'Warning',
    html: message,
    showCloseButton: true,
  }).then(() => {
    onClose();
  });

export const areYouSure = ({ onConfirm, onCancel = () => {}, message }) =>
  Swal.fire({
    title: 'Are you sure?',
    html: message,
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Yes!',
    reverseButtons: true,
    showCloseButton: true,
  }).then(result => {
    if (result.value) {
      onConfirm();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      onCancel();
    }
  });

export const deleteWarning = ({ entity, message, subjectToDelete, onConfirm, onCancel = () => {} }) =>
  Swal.fire({
    title: (
      <span>
        Sure you want to delete <span className="swal2-title-entity">{subjectToDelete || ''}</span> {entity || ''}?
      </span>
    ),
    html: (
      <>
        <div className="swal2-content-default">You won&apos;t be able to revert this!</div>
        <div className="swal2-content-info">{message || ''}</div>
      </>
    ),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    reverseButtons: true,
    showCloseButton: true,
    customClass: {
      popup: 'delete-warning',
    },
  }).then(result => {
    if (result.value) {
      onConfirm();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      onCancel();
    }
  });

export const deleteWarningDangerZone = ({ subjectToDelete, entity, message, onConfirm, onCancel = () => {} }) =>
  Swal.fire({
    title: (
      <span>
        Sure you want to delete <span className="swal2-title-entity">{subjectToDelete || ''}</span> {entity || ''}?
      </span>
    ),
    html: (
      <>
        <div className="swal2-content-default">You won&apos;t be able to revert this!</div>
        <div className="swal2-content-info">{message || ''}</div>
      </>
    ),
    icon: 'warning',
    footer: 'Danger Zone',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    showCloseButton: true,
    customClass: {
      popup: 'danger',
      icon: 'danger-icon',
      footer: 'danger-footer',
    },
  }).then(result => {
    if (result.value) {
      areYouSure({ onConfirm });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      onCancel();
    }
  });

export const entityCreatorForm = async (entityName, message) => {
  const { value: inputName } = await Swal.fire({
    title: `Create ${capitalize(entityName)}`,
    html: (
      <>
        <div>Provide a name for your {entityName}</div>
        {message && <div className="swal2-content-info">{message || ''}</div>}
      </>
    ),
    input: 'text',
    inputPlaceholder: `${capitalize(entityName)}'s name`,
    showCancelButton: true,
    confirmButtonText: 'Create',
    reverseButtons: true,
    // eslint-disable-next-line consistent-return
    inputValidator: currentValue => {
      if (!currentValue) {
        return 'You need to write something!';
      }
    },
  });

  return inputName;
};

export const askYesOrNo = async (title, message) => {
  return Swal.fire({
    title,
    html: <>{message && <div className="swal2-content-info">{message || ''}</div>}</>,
    confirmButtonText: 'Yes',
    showDenyButton: true,
    denyButtonText: 'No',
    showCancelButton: true,
    reverseButtons: true,
  });
};

export const empathizeVars = (strings, ...values) => {
  let str = '';
  strings.forEach((string, i) => {
    str += `${string}<span class='swal2-title-entity'>${values[i] || ''}</span>`;
  });
  return str;
};
