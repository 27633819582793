import React from 'react';
import Icon from 'react-fa';

import { Button } from '../../shared/components';

import styles from './ServiceUnavailable.module.scss';

const ServiceUnavailable = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <div className={styles.big}>
          <Icon name="database" />
        </div>
        <div className={styles.times}>
          <Icon name="times" />
        </div>
      </div>
      <div className="">
        <h1 className={styles.code}>503</h1>
      </div>
      <div className={styles.message}>Service Unavailable</div>
      <Button
        text="Refresh"
        onClick={() => {
          window.location = '/';
        }}
      />
    </div>
  );
};

export default ServiceUnavailable;
