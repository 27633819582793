import { connect } from 'react-redux';
import { intersection } from 'lodash';
import { PropTypes } from 'prop-types';

const Roles = ({ children, user, admit }) => {
  if (intersection(user.roles, admit).length) {
    return children;
  }
  return null;
};

Roles.defaultProps = {
  user: null,
  admit: [],
};

Roles.propTypes = {
  user: PropTypes.object,
  admit: PropTypes.array,
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(Roles);
