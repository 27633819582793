import React from 'react';
import Icon from 'react-fa';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import styles from './Button.module.scss';

const types = {
  primary: styles.PrimaryButton,
  secondary: styles.SecondaryButton,
};

const Button = React.forwardRef(
  (
    {
      text,
      type,
      onClick,
      icon: iconName,
      spin,
      submit,
      disabled,
      className,
      isLoading,
      loadingMessage,
      isLink,
      to,
      style,
      title,
    },
    ref
  ) => {
    const icon = (iconName || isLoading) && (
      <Icon spin={isLoading ? true : spin} className={styles.Icon} name={isLoading ? 'refresh' : iconName} />
    );

    const content = isLoading ? loadingMessage : text;

    if (isLink) {
      return (
        <Link
          type="button"
          className={`${styles.Button} ${types[type]} ${className} ${disabled ? styles.buttonDisabled : ''}`}
          to={to}
          style={style}
          title={title}
          ref={ref}
        >
          {icon}
          {content}
        </Link>
      );
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        type={submit ? 'submit' : 'button'}
        className={`${styles.Button} ${types[type]} ${className}`}
        onClick={onClick}
        disabled={disabled}
        style={style}
        title={title}
        ref={ref}
      >
        {icon}
        {content}
      </button>
    );
  }
);

Button.defaultProps = {
  icon: '',
  onClick: () => {},
  submit: false,
  disabled: false,
  className: '',
  type: 'primary',
  spin: false,
  isLoading: false,
  loadingMessage: 'Loading...',
  isLink: false,
  to: '',
  style: {},
  title: undefined,
  text: undefined,
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  spin: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  isLink: PropTypes.bool,
  to: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
};

export default Button;
