import React from 'react';
import Icon from 'react-fa';
import { sumBy } from 'lodash';
import { PropTypes } from 'prop-types';

import { metrics, tables } from '../../../../../../enums';
import { Table } from '../../../../../shared/components';
import { calculatePercentage, parseIfNumber } from '../../../../../shared/utils/utils';

const ResponseCodeDetailedTable = ({ data, selectedThreadGroup }) => {
  const getIcon = value => {
    const isOk = value === 'OK';

    return <Icon name={isOk ? 'ok' : 'ko'} />;
  };

  const processData = currentData => {
    const totalSamples = sumBy(currentData, 'samples');
    return currentData.map(element => ({
      ...element,
      samplesPercentage: calculatePercentage(element.samples, totalSamples),
    }));
  };

  const columns = [
    {
      Header: metrics.STATUS.label,
      accessor: metrics.STATUS.accessor,
      style: { textAlign: 'center' },
      width: 80,
      resizable: false,
      Cell: cell => getIcon(cell.value),
    },
    {
      Header: metrics.RESPONSE_CODE.label,
      headerClassName: 'justify-content-start',
      accessor: metrics.RESPONSE_CODE.accessor,
    },
    {
      Header: metrics.RESPONSE_MESSAGE.label,
      headerClassName: 'justify-content-start',
      accessor: metrics.RESPONSE_MESSAGE.accessor,
    },
    {
      Header: metrics.FAILURE_MESSAGE.label,
      headerClassName: 'justify-content-start',
      accessor: metrics.FAILURE_MESSAGE.accessor,
    },
    {
      Header: metrics.SAMPLES.label,
      headerClassName: 'justify-content-start',
      accessor: metrics.SAMPLES.accessor,
      Cell: cell => parseIfNumber(cell.value),
    },
    {
      Header: 'Percentage',
      headerClassName: 'justify-content-start',
      accessor: 'samplesPercentage',
      Cell: cell => `${parseIfNumber(cell.value)}%`,
    },
  ];

  return (
    <>
      <Table
        id={tables.RESPONSE_CODE_DETAILED}
        className="ResponseCodeDetailedTableTable"
        data={data[selectedThreadGroup] && processData(data[selectedThreadGroup])}
        columns={columns}
        defaultPageSize={10}
      />
    </>
  );
};

ResponseCodeDetailedTable.propTypes = {
  data: PropTypes.object.isRequired,
  selectedThreadGroup: PropTypes.string.isRequired,
};

export default ResponseCodeDetailedTable;
