import React from 'react';

import styles from './FullPageSpinner.module.scss';

const FullPageSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>Loading...</div>
    </div>
  );
};

export default FullPageSpinner;
