import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './Header.module.scss';

const Header = React.forwardRef(({ children, className, handleClick, id }, ref) => (
  <header id={id} onClick={handleClick} role="presentation" ref={ref} className={`${styles.Header} ${className}`}>
    {children}
  </header>
));

Header.defaultProps = {
  children: null,
  className: '',
  handleClick: () => {},
  id: '',
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  id: PropTypes.string,
};

export default Header;
