import React from 'react';
import Modal from 'react-modal';
import { PropTypes } from 'prop-types';

import CreateTestStepper from './CreateTestStepper/CreateTestStepper';

import styles from './CreateTestModal.module.scss';

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: '17px',
    textAlign: 'center',
    position: 'fixed',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'hidden',
    borderRadius: '5px',
    width: '600px',
    height: '610px',
  },
};

const CreateTestModal = ({ isOpen, onCloseModal, onTestCreation }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      contentLabel="TestModal"
      style={customStyles}
    >
      <h2 className={styles.ModalTitle}>Create Test</h2>
      <CreateTestStepper onCancel={onCloseModal} onTestCreation={onTestCreation} />
    </Modal>
  );
};

CreateTestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onTestCreation: PropTypes.func.isRequired,
};

export default CreateTestModal;
