import React from 'react';

import Auth from './containers/auth/Auth';
import Pages from './containers/pages/Pages';

const App = () => {
  return (
    <Auth>
      <Pages />
    </Auth>
  );
};

export default App;
