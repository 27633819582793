import { createStaticRanges, defaultStaticRanges } from 'react-date-range';
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInMonths,
  endOfDay,
  isSameDay,
  startOfDay,
} from 'date-fns';

const dates = {
  beginningOfTime: new Date(0),
  endOfTomorrow: endOfDay(addDays(new Date(), 1)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
};

export const staticRanges = [
  ...defaultStaticRanges,
  ...createStaticRanges([
    {
      label: 'Beginning of Time',
      range: () => ({
        startDate: dates.beginningOfTime,
        endDate: dates.endOfTomorrow,
      }),
    },
  ]),
];

export const inputRanges = [
  {
    label: 'days up to today',
    range(value) {
      return {
        startDate: addDays(dates.startOfToday, Number(value) * -1),
        endDate: dates.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, dates.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(dates.endOfToday, range.startDate);
    },
  },
  {
    label: 'months up to today',
    range(value) {
      return {
        startDate: addMonths(dates.startOfToday, Number(value) * -1),
        endDate: dates.endOfToday,
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, dates.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInMonths(dates.endOfToday, range.startDate);
    },
  },
];
