import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import { DatePicker } from '../../../shared/components';
import useQuery from '../../../shared/hooks/useQuery/useQuery';
import { sanitizeDateRangeObject } from '../../../shared/utils/utils';
import setExplorerState from '../../store/explorerActions';
import { TestsService } from '../Tests';

import CompareMetrics from './CompareMetrics/CompareMetrics';
import CompareMetricsTable from './CompareMetricsTable/CompareMetricsTable';
import CompareScalabilityModel from './CompareScalabilityModel/CompareScalabilityModel';
import MultipleScenariosFields from './MultipleScenariosFields/MultipleScenariosFields';
import ScenariosLinks from './ScenariosLinks/ScenariosLinks';
import ScenariosTitle from './ScenariosTitle/ScenariosTitle';

import styles from './CompareScenarios.module.scss';

const CompareScenarios = ({ initExplorerState, history, testFields, isFetchingTestFields }) => {
  const [queryScenarios, setQueryScenarios] = useQuery('scenarios', '');
  const urlParams = useParams();
  const { projectId, applicationId, transactionId } = urlParams;

  const [scenariosInfo, setScenariosInfo] = useState([0, 0]);

  const [isScalabilityModelLoading, setIsScalabilityModelLoading] = useState(true);
  const [scalabilityModelData, setScalabilityModelData] = useState({});

  const [isStatisticsDataLoading, setIsStatisticsDataLoading] = useState(true);
  const [statisticsData, setStatisticsData] = useState([]);

  const [scenarios, setScenarios] = useState({
    fields: [],
    values: [],
    dateRange: {},
  });
  const [dateSelection, setDateSelection] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });

  const isScenariosObjectValid = scenarioObject =>
    scenarioObject.fields && scenarioObject.values && scenarioObject.dateRange;

  const getTransactionLink = () => {
    return `/explorer/project/${projectId}/application/${applicationId}/transaction/${transactionId}`;
  };

  useEffect(() => {
    if (queryScenarios.length > 0) {
      const decodedScenario = rison.decode(decodeURIComponent(queryScenarios));

      if (isScenariosObjectValid(decodedScenario)) {
        setScenarios(decodedScenario);
        setDateSelection(sanitizeDateRangeObject(decodedScenario.dateRange));
        initExplorerState(urlParams);
        return;
      }
    }

    history.push(getTransactionLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryScenarios]);

  useEffect(() => {
    if (scenarios.fields.length > 0) setQueryScenarios(rison.encode(scenarios));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarios]);

  const onDateSelectionChange = range => {
    setScenarios(currentScenario => {
      return { ...currentScenario, dateRange: range };
    });
    setDateSelection(range);
  };

  useEffect(() => {
    const getScenariosInfo = async () => {
      if (scenarios.fields.length > 0) {
        const data = await TestsService.getScenariosInfo(projectId, applicationId, transactionId, scenarios);
        setScenariosInfo(data);
      }
    };
    getScenariosInfo();
  }, [applicationId, projectId, transactionId, scenarios]);

  useEffect(() => {
    const getScalabilityData = async () => {
      setIsScalabilityModelLoading(true);

      if (scenarios.fields.length > 0) {
        const resData = await TestsService.getScenariosScalabilityData(
          projectId,
          applicationId,
          transactionId,
          scenarios
        );

        setScalabilityModelData(resData);
        setIsScalabilityModelLoading(false);
      }
    };
    getScalabilityData();
  }, [applicationId, projectId, transactionId, scenarios]);

  useEffect(() => {
    const getStatistics = async () => {
      setIsStatisticsDataLoading(true);

      if (scenarios.fields.length > 0) {
        const resData = await TestsService.getScenariosStatistics(projectId, applicationId, transactionId, scenarios);

        setStatisticsData(resData);
        setIsStatisticsDataLoading(false);
      }
    };
    getStatistics();
  }, [applicationId, projectId, transactionId, scenarios]);

  const getLinkToTests = (testsScenario, currentFields, dateRange) => {
    const scenario = {
      fields: currentFields,
      values: testsScenario,
      dateRange,
    };
    const encodedScenario = rison.encode(scenario);
    return `${getTransactionLink()}/tests?scenario=${encodeURIComponent(encodedScenario)}`;
  };

  return (
    <>
      <h4 className="page-title">Scenario comparison</h4>
      <div className={styles.Header}>
        <h4 className="page-subtitle mb-0">Scenarios</h4>
        <DatePicker dateSelection={dateSelection} onSelectionChange={onDateSelectionChange} />
      </div>

      <div className={styles.ScenarioMainContainer}>
        <div className={`${styles.ScenarioContainer} box-container`}>
          <div className={styles.Content}>
            <ScenariosTitle count={scenarios.values.length} />
            {!isFetchingTestFields &&
              testFields.length > 0 &&
              scenarios.fields.map(fieldName => {
                const field = testFields.find(f => f.field_name === fieldName);
                const values = scenarios.values.map(element => get(element, fieldName, 'undefined'));
                if (field) {
                  return (
                    <MultipleScenariosFields
                      key={field._id}
                      label={field.label}
                      fieldName={field.field_name}
                      values={values}
                    />
                  );
                }
                return null;
              })}
            <MultipleScenariosFields label="Executions" values={scenariosInfo.map(e => e.count)} />
            <ScenariosLinks label="Explore" scenarios={scenarios} getLinkToTests={getLinkToTests} />
          </div>
        </div>
      </div>

      <div className={`${styles.ChartContainer}`}>
        <CompareMetrics data={scalabilityModelData} isDataLoading={isScalabilityModelLoading} />
      </div>

      <div className={`${styles.ChartContainer}`}>
        <CompareScalabilityModel
          scenarios={scenarios}
          data={scalabilityModelData}
          isDataLoading={isScalabilityModelLoading}
        />
      </div>

      <div className={`${styles.ChartContainer} box-container`}>
        <CompareMetricsTable data={statisticsData} isDataLoading={isStatisticsDataLoading} />
      </div>
    </>
  );
};

CompareScenarios.propTypes = {
  initExplorerState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  testFields: PropTypes.array.isRequired,
  isFetchingTestFields: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  testFields: state.testFields.testFields,
  isFetchingTestFields: state.testFields.isFetchingTestFields,
});

const mapDispatchToProps = dispatch => ({
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareScenarios);
