/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import logo from '../../../assets/images/logo.svg';
import { Button } from '../../shared/components';

import styles from './LoginForm.module.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: '',
    };
  }

  isValidForm = (username, password) => {
    const { cleanLoginError } = this.props;
    cleanLoginError();
    const isValid = username !== '' && password !== '';
    if (isValid) {
      this.setState({
        error: '',
      });
    } else {
      this.setState({
        error: '',
      });
    }
  };

  showError = error => (error ? <div className={styles.alertDanger}>{error}</div> : null);

  handleChangeUsername = e => {
    const { password } = this.state;
    const value = e.target.value.trim();
    this.isValidForm(value, password);
    this.setState({ username: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { onLogin } = this.props;
    return onLogin(username, password);
  };

  handleChangePassword = e => {
    const { username } = this.state;
    const value = e.target.value.trim();
    this.isValidForm(username, value);
    this.setState({ password: value });
  };

  render() {
    const { error } = this.state;
    const { externalError, onFlip } = this.props;
    return (
      <div className="front">
        <form onSubmit={this.onSubmit}>
          <img src={logo} alt="logo" className="main-logo" />
          <div>
            <input
              type="text"
              placeholder="Username"
              className="col-md-12 form-control"
              onChange={this.handleChangeUsername}
              required
            />
            <input
              type="password"
              placeholder="Password"
              onChange={this.handleChangePassword}
              className="col-md-12 mb-1 form-control"
              required
            />
            {this.showError(error)}
            {this.showError(externalError)}
            <Button text="Login" submit />
            <button type="button" className={styles.BtnCreateAccount} onClick={onFlip}>
              Or create a new account
            </button>
          </div>
        </form>
      </div>
    );
  }
}

LoginForm.defaultProps = {
  externalError: null,
};

LoginForm.propTypes = {
  externalError: PropTypes.string,
  onFlip: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
