/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Hint, Spinner } from '../../shared/components';
import { alerts } from '../../shared/utils';
import { UserService } from '../../user';

import ClientsTable from './ClientsTable';
import CreateClient from './CreateClient';

import styles from './ClientsManager.module.scss';

const ClientsManager = () => {
  const [clients, setClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);

  const getClients = async () => {
    setIsClientsLoading(true);
    const clientsDB = await UserService.getClients();
    setClients(clientsDB);
    setIsClientsLoading(false);
  };

  useEffect(() => {
    getClients();
  }, []);

  const onClientCreation = client => {
    getClients();
    alerts.success(alerts.empathizeVars`Client ${client.username} created successfully.`);
  };

  return (
    <>
      <div className="page-header mt-4">
        <h4 className="page-title">
          Create client
          <Hint
            content={
              <>
                Creating a user here will also create it in Kibana with the same username and password in case you are
                using <strong>Elasticsearch security features</strong> or <strong>OpenDistro</strong>.
              </>
            }
          />
        </h4>
      </div>
      <div className="box-container">
        <CreateClient onCreation={onClientCreation} />
      </div>

      <div className="page-header mt-4">
        <h4 className="page-title">Clients</h4>
      </div>
      <div className="box-container">
        <div className={styles.Container}>
          <div className={styles.TableContainer}>
            {isClientsLoading ? <Spinner /> : <ClientsTable clients={clients} />}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(ClientsManager);
