import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { parseIfNumber } from '../../utils/utils';

import styles from './Selector.module.scss';

const Selector = ({ options, selected, onChange, globalKeyEvents }) => {
  const nextOption = () => {
    const currentIndex = options.indexOf(selected);
    if (currentIndex === options.length - 1) {
      onChange(options[0]);
    } else {
      onChange(options[currentIndex + 1]);
    }
  };

  const prevOption = () => {
    const currentIndex = options.indexOf(selected);
    if (currentIndex === 0) {
      onChange(options[options.length - 1]);
    } else {
      onChange(options[currentIndex - 1]);
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 39) {
      nextOption();
    } else if (e.keyCode === 37) {
      prevOption();
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (globalKeyEvents) {
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  });

  return (
    <div className={styles.Container}>
      <div className={styles.Selector}>
        {options.map(option => (
          <div
            key={option}
            className={`${styles.Option} ${option === selected ? styles.Option_selected : ''}`}
            onClick={() => onChange(option)}
            onKeyDown={() => onChange(option)}
          >
            {parseIfNumber(option)}
          </div>
        ))}
      </div>
    </div>
  );
};

Selector.defaultProps = {
  globalKeyEvents: false,
};

Selector.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  globalKeyEvents: PropTypes.bool,
};

export default Selector;
