import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getQueryStringValue, setQueryStringValue } from './queryStringUtils';

const useQuery = (key, initialValue) => {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const location = useLocation();

  const onSetValue = useCallback(
    newValue => {
      if (newValue !== value) {
        setValue(newValue);
        setQueryStringValue(key, newValue);
      }
    },
    [key, value]
  );

  useEffect(() => {
    setValue(getQueryStringValue(key));
  }, [key, location]);

  return [value, onSetValue];
};

export default useQuery;
