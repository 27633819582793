/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';

import { tables } from '../../../enums';
import { Table } from '../../shared/components';
import { formatDateToLocale } from '../../shared/utils/utils';

const ClientsTable = ({ clients }) => {
  const columns = [
    {
      Header: 'Username',
      headerClassName: 'justify-content-start',
      accessor: 'username',
      style: { textAlign: 'left' },
      filterable: true,
      filterMethod: (filter, client) => {
        const username = client.username && client.username.toLowerCase();
        return username.includes(filter.value.toLowerCase());
      },
    },
    {
      Header: 'Email',
      headerClassName: 'justify-content-start',
      accessor: 'email',
      filterable: true,
      filterMethod: (filter, client) => {
        const email = client.email && client.email.toLowerCase();
        return email.includes(filter.value.toLowerCase());
      },
    },
    {
      Header: 'Created at',
      id: 'createdAt',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      accessor: ({ createdAt }) => {
        const created = new Date(createdAt);
        return formatDateToLocale(created);
      },
      style: { textAlign: 'center' },
    },
    {
      Header: 'Projects shared',
      accessor: 'projects',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      Cell: cell => {
        const projects = cell.value.map(project => project.name);
        return projects.join(', ');
      },
    },
  ];
  return <Table id={tables.CLIENTS} data={clients} columns={columns} filterable />;
};

ClientsTable.defaultProps = {
  clients: [],
};

ClientsTable.propTypes = {
  clients: PropTypes.array,
};

export default ClientsTable;
