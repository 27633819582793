import axios from 'axios';

class LoginService {
  static async login(username, password) {
    const response = await axios.post('login', {
      username,
      password,
    });

    if (response.data && response.data.user && response.data.user.token) {
      return response.data.user;
    }

    throw response.data;
  }
}

export default LoginService;
