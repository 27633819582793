import * as React from 'react';
import Stepper, { Step } from 'react-material-stepper';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { alerts } from '../../../../../shared/utils';
import { TestsService } from '../../../Tests';

import { STEP1, STEP2, TestInfoStep, UploadFilesStep } from './steps';

import 'react-material-stepper/dist/react-stepper.css';
import './CreateTestStepper.scss';

const CreateTestStepper = ({ onCancel, project, application, transaction, onTestCreation }) => {
  const ERROR_MESSAGE = 'Error uploading the test. Please try again.';
  const SUCCESS_MESSAGE = 'Test created successfully.';

  const [isLoading, setIsLoading] = React.useState();
  const stepperRef = React.useRef();

  const onComplete = async () => {
    setIsLoading(true);
    const controller = stepperRef.current;
    const fileUploadData = controller.getData(STEP1);
    const testInfoData = controller.getData(STEP2);

    delete testInfoData.environmentInfo;
    delete testInfoData.metadata;

    const testInfo = {
      scn_project_name: project.name,
      scn_application_name: application.name,
      scn_transaction_name: transaction.name,
      ...testInfoData,
    };

    try {
      const file = new File([fileUploadData.bundle], 'file.zip', {
        type: 'application/x-zip-compressed',
      });

      await TestsService.createTest(file, testInfo);
      setIsLoading(false);
      onTestCreation();
      alerts.success(SUCCESS_MESSAGE);
    } catch (error) {
      setIsLoading(false);
      alerts.error(ERROR_MESSAGE);
    }
  };

  const onResolve = () => {
    const controller = stepperRef.current;
    const steps = controller.getSteps();

    if (steps.length && steps.every(step => step.completed)) {
      onComplete();
    }
  };

  return (
    <>
      <Stepper className="createTestStepper" contextRef={stepperRef} onResolve={onResolve}>
        <Step stepId={STEP1} title="Files Upload">
          <UploadFilesStep onCancel={onCancel} />
        </Step>
        <Step stepId={STEP2} title="Test Info" loading={isLoading}>
          <TestInfoStep onCancel={onCancel} />
        </Step>
      </Stepper>
    </>
  );
};

CreateTestStepper.propTypes = {
  onCancel: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  onTestCreation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  project: state.projects.currentProject,
  application: state.applications.currentApplication,
  transaction: state.transactions.currentTransaction,
});

export default connect(mapStateToProps)(CreateTestStepper);
