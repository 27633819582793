import { intersection } from 'lodash';

import store from '../../../store';

const hasRoles = roles => {
  const { user } = store.getState().session;

  if (user) return !!intersection(user.roles, roles).length;

  return false;
};

export default hasRoles;
