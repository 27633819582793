import React from 'react';
import Icon from 'react-fa';
import { isUndefined } from 'lodash';
import { PropTypes } from 'prop-types';

import { metrics } from '../../../../../../enums';
import { calculatePercentageChange } from '../../../../../shared/utils/utils';

import styles from './CompareScalabilityModelTooltip.module.scss';

const CompareScalabilityModelTooltip = ({ label: virtualUsers, payload, formatter, increaseIsPositive }) => {
  const getDiff = () => {
    return payload.length > 1 ? calculatePercentageChange(payload[0].value, payload[1].value) : undefined;
  };

  const diff = getDiff();
  const increase = diff > 0;

  const getColor = () => {
    if (isUndefined(diff)) return '#adadad';
    if (diff === 0) return '#9E9E9E';
    if ((increaseIsPositive && increase) || (!increaseIsPositive && !increase)) return '#14cc45';
    if ((increaseIsPositive && !increase) || (!increaseIsPositive && increase)) return '#eb2617';

    return '#000';
  };

  const getIcon = () => {
    if (isUndefined(diff)) return 'times';
    if (diff === 0) return 'arrow-right';
    if (increase) return 'arrow-up';
    if (!increase) return 'arrow-down';

    return 'arrow-up';
  };

  return (
    <div className={styles.Container}>
      <div className={styles.HeaderContainer}>
        <div className={styles.Title}>{metrics.THREADS.label}</div>
        <div className={styles.Value}>{virtualUsers}</div>
      </div>
      <div className={styles.ScenariosContainer}>
        {payload.length > 0 &&
          payload.map(({ name, color, value }) => (
            <div key={name} className={styles.ContentContainer}>
              <div className={styles.Title} style={{ color }}>
                {name}
              </div>
              <div className={styles.Value}>{formatter(value)}</div>
            </div>
          ))}
      </div>
      <hr />
      {payload.length > 0 && (
        <div className={styles.DiffContainer}>
          <div className={styles.Arrow} style={{ color: getColor() }}>
            <Icon name={getIcon()} />
          </div>
          <div className={styles.Value}>{diff && `${Math.abs(diff)}%`}</div>
        </div>
      )}
    </div>
  );
};

CompareScalabilityModelTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
  formatter: PropTypes.func.isRequired,
  increaseIsPositive: PropTypes.bool.isRequired,
};

export default CompareScalabilityModelTooltip;
