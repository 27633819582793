/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { PropTypes } from 'prop-types';

import UploadedFile from './UploadFile/UploadedFile';

import styles from './Dropzone.module.scss';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '10px',
  borderColor: 'rgb(170, 170, 170)',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  position: 'relative',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const focusStyle = {
  borderColor: '#4287f5',
};

const Dropzone = ({ files, updateFiles, message }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      updateFiles(currentFiles => {
        const newFiles = [];
        acceptedFiles.forEach(file => {
          if (currentFiles.filter(f => f.path === file.path).length === 0) {
            newFiles.push(file);
          }
        });

        return [...currentFiles, ...newFiles];
      });
    },
    [updateFiles]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused } = useDropzone({ onDrop });

  const onFileRemoved = file => {
    const newFiles = files.filter(f => f.path !== file.path);
    updateFiles(newFiles);
  };

  const filesView = files.map(file => <UploadedFile key={file.path} file={file} onFileRemoved={onFileRemoved} />);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isFocused ? focusStyle : {}),
    }),
    [isDragAccept, isDragActive, isDragReject, isFocused]
  );

  return (
    <div {...getRootProps({ style, className: 'dropzone' })}>
      <input {...getInputProps()} />
      <p className={styles.DropzoneText}>{message}</p>
      <div className={styles.UploadedFilesContainer}>
        <div className={styles.UploadedFilesWrapper}>{filesView}</div>
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  message: "Drag 'n' drop some files here, or click to select files",
};

Dropzone.propTypes = {
  files: PropTypes.array.isRequired,
  message: PropTypes.string,
  updateFiles: PropTypes.func.isRequired,
};

export default Dropzone;
