/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { Button } from '../../shared/components';
import { UserService } from '../../user';

import styles from './ClientsManager.module.scss';

const CreateClient = ({ onCreation }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const togglePassword = () => {
    setShowPassword(current => !current);
  };

  const createClient = async event => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const username = usernameRef.current && usernameRef.current.value;
      const email = emailRef.current && emailRef.current.value;
      const password = passwordRef.current && passwordRef.current.value;

      const client = await UserService.createClient(username.trim(), email, password.trim());
      onCreation(client);
      setErrorMessage('');
      usernameRef.current.value = '';
      emailRef.current.value = '';
      passwordRef.current.value = '';
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  const onFormChange = () => {
    setErrorMessage('');
  };

  return (
    <>
      <form onSubmit={createClient} onChange={onFormChange}>
        <div className={styles.FormContainer}>
          <input ref={usernameRef} type="text" placeholder="Username" className="form-control" required />
          <input ref={emailRef} className="form-control" type="email" placeholder="Email" required />
          <div className={styles.InputGroup}>
            <input
              ref={passwordRef}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              className="form-control"
              required
            />
            <button type="button" onClick={togglePassword} className={styles.TogglePassword} tabIndex="-1">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {!errorMessage || errorMessage === '' ? null : <div className={styles.ErrorMessage}>{errorMessage}</div>}
          <Button
            text="Create"
            className={styles.btnClient}
            onClick={() => {}}
            disabled={false}
            isLoading={isLoading}
            loadingMessage="Creating..."
            submit
          />
        </div>
      </form>
    </>
  );
};

CreateClient.propTypes = {
  onCreation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(CreateClient);
