import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import Roles from '../../../../containers/auth/Roles';
import { roles, tables } from '../../../../enums';
import { Button, Spinner, Table, TableButton } from '../../../shared/components';
import { alerts } from '../../../shared/utils';
import * as sessionActions from '../../../user/store/sessionActions';
import { ProjectsActions, ProjectsService } from '../Project';

import ShareProjectModal from './ShareProjectModal/ShareProjectModal';

const Home = ({ user, projects, isFetchingProjects, getProjects, clearTableFilters }) => {
  const [shareProjectModal, setShareProjectModal] = useState(false);
  const [shareProject, setShareProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getAllProjects = useCallback(() => {
    getProjects();
    setIsLoading(false);
  }, [getProjects]);

  useEffect(() => {
    getAllProjects();
  }, [user, getAllProjects]);

  const getProjectUrl = projectId => {
    return `/explorer/project/${projectId}`;
  };

  const deleteProject = async project => {
    try {
      await ProjectsService.deleteProject(project._id);
      alerts.success(alerts.empathizeVars`Your Project ${project.name} was deleted.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getProjects();
  };

  const handleProjectDeletion = selectedProject => {
    alerts.deleteWarningDangerZone({
      subjectToDelete: selectedProject.name,
      entity: 'project',
      message:
        'This action deletes all the data of applications, transactions' +
        ' and tests of this project in MongoDB and Elasticsearch.',
      onConfirm: () => {
        deleteProject(selectedProject);
      },
    });
  };

  const createNewProject = async (name, createKibanaDashboards) => {
    try {
      await ProjectsService.createProject(name, createKibanaDashboards);
      alerts.success(alerts.empathizeVars`Your project ${name} was created.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getProjects();
  };

  const onNewProject = async () => {
    const projectName = await alerts.entityCreatorForm('project');
    const message = (
      <>
        <p>
          If your <b>Kibana/Elasticsearch</b> version is <b>7.x</b> the project creation can generate a series of
          dashboards ready for use with the tests data.
        </p>
        <p>
          This should not cause conflicts unless you already have the <b>Performance Explorer</b> index patterns with
          the prefix{' '}
          <b>
            {projectName &&
              projectName
                .toLowerCase()
                .split(' ')
                .join('-')}
            -
          </b>
          .
        </p>
      </>
    );

    if (projectName) {
      const createKibanaDashboards = await alerts.askYesOrNo('Create Kibana dashboards?', message);
      if (!createKibanaDashboards.isDismissed) {
        createNewProject(projectName, createKibanaDashboards.isConfirmed);
      }
    }
  };

  const openShareProjectModal = project => {
    setShareProject(project);
    setShareProjectModal(true);
  };

  const onShareModalClose = () => {
    setShareProject({});
    setShareProjectModal(false);
  };

  const onSuccessSharingProject = (project, client) => {
    alerts.success(alerts.empathizeVars`Project ${project.name} shared with ${client.username}.`);
    setShareProject({});
    setShareProjectModal(false);
  };

  const onErrorSharingProject = errorMessage => {
    alerts.error(errorMessage);
    setShareProject({});
    setShareProjectModal(false);
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      headerClassName: 'justify-content-start',
      id: 'name',
      filterable: true,
      sortable: true,
      Cell: project => (
        <span className="table-featured-cell" title={project.value}>
          {project.value}
        </span>
      ),
      filterMethod: (filter, project) => {
        const projectName = project.name && project.name.toLowerCase();
        return projectName.includes(filter.value.toLowerCase());
      },
    },
    {
      Header: 'Applications',
      id: 'applicationsNumber',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      accessor: project => {
        if (project.applications_number) {
          return project.applications_number;
        }
        return 0;
      },
    },
    {
      Header: 'Transactions',
      id: 'transactionsNumber',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      accessor: project => {
        if (project.transactions_number) {
          return project.transactions_number;
        }
        return 0;
      },
    },
    {
      Header: 'Actions',
      width: 250,
      resizable: false,
      headerClassName: 'disabled-filter',
      className: 'table-action-cell',
      sortable: false,
      Cell: project => (
        <>
          <TableButton isLink icon="eye" to={getProjectUrl(project.original._id)} tooltip="Open project" />
          <Roles admit={[roles.DEVELOPER]}>
            <TableButton
              icon="share-alt"
              onClick={() => openShareProjectModal(project.original)}
              tooltip="Share project"
            />
            <TableButton
              icon="trash"
              onClick={() => handleProjectDeletion(project.original)}
              tooltip="Delete project"
            />
          </Roles>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="page-header">
        <h4 className="page-title">Projects</h4>
        <div className="header-actions">
          <Button text="Clear filters" type="secondary" onClick={() => clearTableFilters(tables.PROJECTS)} />
          <Roles admit={[roles.DEVELOPER]}>
            <Button text="New project" icon="plus" onClick={onNewProject} />
          </Roles>
        </div>
      </div>

      <div className="box-container">
        {isFetchingProjects || isLoading ? (
          <Spinner />
        ) : (
          <Table id={tables.PROJECTS} data={projects} columns={columns} />
        )}
      </div>

      <ShareProjectModal
        isOpen={shareProjectModal}
        project={shareProject}
        onCloseModal={onShareModalClose}
        onShareProject={[]}
        onError={onErrorSharingProject}
        onSuccess={onSuccessSharingProject}
      />
    </>
  );
};

Home.defaultProps = {
  user: {},
  projects: [],
  isFetchingProjects: false,
};

Home.propTypes = {
  user: PropTypes.object,
  projects: PropTypes.array,
  isFetchingProjects: PropTypes.bool,
  getProjects: PropTypes.func.isRequired,
  clearTableFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.session.user,
  projects: state.projects.projects,
  isFetchingProjects: state.projects.isFetchingProjects,
});

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(ProjectsActions.fetchProjects()),
  clearTableFilters: tableId => dispatch(sessionActions.clearTableFilters(tableId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
