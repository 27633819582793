import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import { FullPageSpinner } from '../../modules/shared/components';
import { SessionActions, TokenService } from '../../modules/user';

const Auth = ({ children, user, setCurrentUser, logoutUser }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getUserByToken = async token => {
      const userDb = await TokenService.getUserByToken(token);
      setCurrentUser({ ...userDb });
      setLoading(false);
    };

    const signOut = () => {
      const currentPath = `${history.location.pathname}${history.location.search}`;
      logoutUser(currentPath === '/' ? '/explorer' : currentPath);
      setLoading(false);
    };

    const verifyToken = async () => {
      const token = window.localStorage.accessToken;

      if (!token) {
        signOut();
        return;
      }

      try {
        const expired = await TokenService.hasExpiredToken(token);

        if (expired) {
          signOut();
        } else if (!user) {
          getUserByToken(token);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    if (!user) {
      verifyToken();
    }
  }, [user, logoutUser, setCurrentUser]);

  if (loading) {
    return <FullPageSpinner />;
  }

  return children;
};

const mapStateToProps = state => ({
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
  logoutUser: redirectPath => dispatch(SessionActions.logout(redirectPath)),
  setCurrentUser: user => dispatch(SessionActions.setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
