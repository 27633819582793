import { projectsActions } from './projectsActions';

const initialState = {
  projects: [],
  currentProject: {},
  isFetchingProjects: false,
  fetchProjectsError: null,
  sharedProjects: [],
  isFetchingSharedProjects: false,
  fetchSharedProjectsError: null,
  isCreatingProject: false,
  createtProjectError: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectsActions.START_FETCH_PROJECTS:
      return { ...state, isFetchingProjects: true };
    case projectsActions.SUCCESS_FETCH_PROJECTS:
      return { ...state, projects: action.payload, isFetchingProjects: false };
    case projectsActions.ERROR_FETCH_PROJECTS:
      return { ...state, fetchProjectsError: action.payload, isFetchingProjects: false };
    case projectsActions.START_FETCH_SHARED_PROJECTS:
      return { ...state, isFetchingSharedProjects: true };
    case projectsActions.SUCCESS_FETCH_SHARED_PROJECTS:
      return { ...state, sharedProjects: action.payload, isFetchingSharedProjects: false };
    case projectsActions.CLEAR_PROJECTS:
      return { ...state, sharedProjects: [], projects: [] };
    case projectsActions.ERROR_FETCH_SHARED_PROJECTS:
      return {
        ...state,
        fetchSharedProjectsError: action.payload,
        isFetchingSharedProjects: false,
      };
    case projectsActions.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
      };
    default:
      return state;
  }
};
export default projectsReducer;
