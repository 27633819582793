/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { PropTypes } from 'prop-types';

import { Button, Modal } from '../../../../shared/components';
import { UserService } from '../../../../user';
import { ProjectsService } from '../../Project';

import styles from './ShareProjectModal.module.scss';

const ShareProjectModal = ({ isOpen, onCloseModal, project, onSuccess, onError }) => {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isSharingProject, setIsSharingProject] = useState(false);

  useEffect(() => {
    const getClients = async () => {
      setIsClientsLoading(true);
      const clientsDB = await UserService.getClients();
      setClients(clientsDB);
      setSelectedClient(clientsDB[0]);
      setIsClientsLoading(false);
    };
    if (isOpen) {
      getClients();
    }
  }, [isOpen]);

  const shareProject = async () => {
    try {
      setIsSharingProject(true);
      await ProjectsService.shareProject(project._id, selectedClient.username);
      onSuccess(project, selectedClient);
    } catch (error) {
      onError('An error occurred during the sharing of the project.');
    }
    setIsSharingProject(false);
  };

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} contentLabel="TestModal">
      <h3 className={styles.ModalTitle}>
        Share <span>{project.name}</span>
      </h3>
      <div className="">Select a client</div>
      <div className={styles.Content}>
        <div className={styles.ClientSelection}>
          <DropdownList
            className={styles.Dropdown}
            data={clients}
            value={selectedClient}
            textField="username"
            filter
            busy={isClientsLoading}
            onChange={value => setSelectedClient(value)}
          />
        </div>
        <Button
          text="Share"
          icon="share-alt"
          className={styles.ShareButton}
          isLoading={isSharingProject}
          onClick={shareProject}
        />
      </div>
      <Button
        className={styles.CreateClientButton}
        text="or create a new client"
        icon="plus"
        onClick={() => history.push('/configuration')}
      />
    </Modal>
  );
};

ShareProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(ShareProjectModal);
