import axios from 'axios';

import backendPath from '../../../../../backendPath';

const instance = axios.create({
  baseURL: backendPath.CLI_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
});

class CliService {
  static async parseJtl(jtlFile) {
    const formData = new FormData();
    formData.append('jtl', jtlFile);

    const response = await instance.post('parsers/jtl', formData, {
      responseType: 'arraybuffer',
    });

    return response.data;
  }

  static async parseJtlSar(jtlFile, sarFiles) {
    const formData = new FormData();
    formData.append('jtl', jtlFile);
    sarFiles.forEach(file => formData.append('sar', file));

    const response = await instance.post('parsers/jtl/merge/sar', formData, {
      responseType: 'arraybuffer',
    });

    return response.data;
  }

  static async parseJtlPerfmon(jtlFile, perfmonFiles) {
    const formData = new FormData();
    formData.append('jtl', jtlFile);
    formData.append('perfmon', perfmonFiles);

    const response = await instance.post('parsers/jtl/merge/sar', formData, {
      responseType: 'arraybuffer',
    });

    return response.data;
  }
}

export default CliService;
