import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';

import Spinner from '../spinners/Spinner/Spinner';

import ScenarioField from './ScenarioField/ScenarioField';

const ScenarioFields = ({ scenario, testFields, isFetchingTestFields }) => {
  if (scenario && !isFetchingTestFields && testFields.length > 0) {
    return scenario.fields.map(fieldName => {
      const field = testFields.find(f => f.field_name === fieldName);
      if (field)
        return (
          <ScenarioField
            key={field._id}
            label={field.label}
            fieldName={field.field_name}
            value={get(scenario.values, fieldName, 'undefined')}
            isUndefined={!get(scenario.values, fieldName)}
          />
        );
      return null;
    });
  }

  return <Spinner />;
};

ScenarioFields.propTypes = {
  testFields: PropTypes.array.isRequired,
  isFetchingTestFields: PropTypes.bool.isRequired,
  scenario: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  testFields: state.testFields.testFields,
  isFetchingTestFields: state.testFields.isFetchingTestFields,
});

export default connect(mapStateToProps)(ScenarioFields);
