/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { StepperContent, StepperContext } from 'react-material-stepper';
import { DropdownList } from 'react-widgets';
import { PropTypes } from 'prop-types';

import { Button } from '../../../../../../../shared/components';
import CliService from '../../../../services/cliService';

import Dropzone from './Dropzone/Dropzone';
import Loader from './Loader/Loader';
import DropzoneMessages from './dropzoneMessages';

import './Dropdown.scss';
import styles from './UploadFilesStep.module.scss';

const FileDownload = require('js-file-download');

export const STEP1 = 'step-one';

const UploadFilesStep = ({ onCancel }) => {
  const { resolve, getData } = React.useContext(StepperContext);
  const data = getData(STEP1) || {};

  const loadTools = ['JMeter'];
  const monitoringTools = ['SAR', 'Perfmon'];
  const [loadFiles, setLoadFiles] = useState(data.loadFiles || []);
  const [monitoringFiles, setMonitoringFiles] = useState(data.monitoringFiles || []);
  const [selectedLoadTool, setSelectedLoadTool] = useState(data.selectedLoadTool || loadTools[0]);
  const [selectedMonitoringTool, setSelectedMonitoringTool] = useState(
    data.selectedMonitoringTool || monitoringTools[0]
  );
  const [isParsing, setIsParsing] = useState(false);
  const [areFilesParsed, setAreFilesParsed] = useState(data.areFilesParsed || false);
  const [actionButtonText, setActionButtonText] = useState(data.actionButtonText || 'Parse Files');
  const [bundle, setBundle] = useState(data.bundle);
  const [hasParsingError, setHasParsingError] = useState(false);

  const onBundleDownload = () => {
    FileDownload(bundle, 'parsed_files.zip');
  };

  const parseJMeter = async () => {
    let response = {};

    if (selectedMonitoringTool === 'SAR' && monitoringFiles.length > 0) {
      response = await CliService.parseJtlSar(loadFiles[0], monitoringFiles);
    } else if (selectedMonitoringTool === 'Perfmon' && monitoringFiles.length > 0) {
      response = await CliService.parseJtlPerfmon(loadFiles[0], monitoringFiles);
    } else {
      response = await CliService.parseJtl(loadFiles[0]);
    }

    return response;
  };

  const parseFiles = async () => {
    try {
      setHasParsingError(false);
      setAreFilesParsed(false);
      setIsParsing(true);
      setActionButtonText('Parsing...');

      let response = {};
      if (selectedLoadTool === 'JMeter') {
        response = await parseJMeter();
      }

      setIsParsing(false);
      setAreFilesParsed(true);
      setBundle(response);
      setActionButtonText('Continue');
    } catch (error) {
      setIsParsing(false);
      setActionButtonText('Parse Files');
      setHasParsingError(true);
    }
  };

  const onSubmit = event => {
    if (!areFilesParsed) {
      event.preventDefault();
      if (loadFiles.length > 0 || monitoringFiles > 0) {
        parseFiles();
      }
      return;
    }
    resolve({
      loadFiles,
      monitoringFiles,
      selectedLoadTool,
      selectedMonitoringTool,
      areFilesParsed,
      actionButtonText,
      bundle,
    });
  };

  const onChangeLoadTool = value => setSelectedLoadTool(value);
  const onChangeMonitoringTool = value => setSelectedMonitoringTool(value);

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <>
          <Button type="secondary" text="Back" disabled />
          <Button type="secondary" className="StepperCancelButton" text="Cancel" onClick={onCancel} />
          <Button
            text={actionButtonText}
            onClick={onSubmit}
            disabled={isParsing || loadFiles.length === 0 || monitoringFiles === 0}
            submit
          />
        </>
      }
    >
      <div className={styles.ToolContainer}>
        <div className={`${styles.ToolHeaderContainer} ToolHeaderContainer`}>
          <h5 className={styles.ToolTitle}>Load</h5>
          <div className={styles.ToolSelection}>
            <DropdownList
              className={styles.ToolDropdown}
              data={loadTools}
              value={selectedLoadTool}
              onChange={value => onChangeLoadTool(value)}
            />
          </div>
        </div>
        <Dropzone files={loadFiles} updateFiles={setLoadFiles} message={DropzoneMessages[selectedLoadTool]} />
      </div>
      <div className={styles.ToolContainer}>
        <div className={`${styles.ToolHeaderContainer} ToolHeaderContainer`}>
          <h5 className={styles.ToolTitle}>Monitoring</h5>
          <div className={styles.ToolSelection}>
            <DropdownList
              className={styles.ToolDropdown}
              data={monitoringTools}
              value={selectedMonitoringTool}
              onChange={value => onChangeMonitoringTool(value)}
            />
          </div>
        </div>
        <Dropzone
          files={monitoringFiles}
          updateFiles={setMonitoringFiles}
          message={DropzoneMessages[selectedMonitoringTool]}
        />
      </div>
      <div className={styles.ParseFilesContainer}>
        <div className={styles.ParseFilesBundle}>
          {isParsing && (
            <>
              <Loader />
              <span className={styles.ParsingMessage}>Parsing...</span>
            </>
          )}

          {hasParsingError && <span className={styles.ErrorMessage}>Error parsing the files</span>}

          {!isParsing && areFilesParsed && (
            <>
              <i className={`fa fa-file-archive-o ${styles.BundleIcon}`} aria-hidden="true"></i>
              <div className={styles.Bundle}>
                <span className={styles.BundleMessage}>Bundle ready!</span>
                <i
                  title="Download bundle"
                  className={`fa fa-download ${styles.DownloadIcon}`}
                  onClick={onBundleDownload}
                  aria-hidden="true"
                ></i>
              </div>
            </>
          )}
        </div>
      </div>
    </StepperContent>
  );
};

UploadFilesStep.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default UploadFilesStep;
