import ProjectsService from '../services/projectsService';

export const projectsActions = {
  START_FETCH_PROJECTS: '[PROJECTS] Start fetching projects',
  SUCCESS_FETCH_PROJECTS: '[PROJECTS] Success fetching projects',
  ERROR_FETCH_PROJECTS: '[PROJECTS] Error fetching projects',
  START_FETCH_SHARED_PROJECTS: '[PROJECTS] Start fetching shared projects',
  SUCCESS_FETCH_SHARED_PROJECTS: '[PROJECTS] Success fetching shared projects',
  ERROR_FETCH_SHARED_PROJECTS: '[PROJECTS] Error fetching shared projects',
  CLEAR_PROJECTS: '[PROJECTS] Clear projects',
  SET_CURRENT_PROJECT: '[PROJECTS] Set current project',
};

export function startFetchProjects() {
  return {
    type: projectsActions.START_FETCH_PROJECTS,
  };
}

export function successFetchProjects(projects) {
  return {
    type: projectsActions.SUCCESS_FETCH_PROJECTS,
    payload: projects,
  };
}

export function errorFetchProjects(error) {
  return {
    type: projectsActions.ERROR_FETCH_PROJECTS,
    payload: error,
  };
}

export function startFetchSharedProjects() {
  return {
    type: projectsActions.START_FETCH_SHARED_PROJECTS,
  };
}

export function successFetchSharedProjects(projects) {
  return {
    type: projectsActions.SUCCESS_FETCH_SHARED_PROJECTS,
    payload: projects,
  };
}

export function errorFetchSharedProjects(error) {
  return {
    type: projectsActions.ERROR_FETCH_SHARED_PROJECTS,
    payload: error,
  };
}

export function clearProjects() {
  return {
    type: projectsActions.CLEAR_PROJECTS,
  };
}

export function setCurrentProject(projectId) {
  return dispatch => {
    return ProjectsService.getProjectById(projectId)
      .then(project =>
        dispatch({
          type: projectsActions.SET_CURRENT_PROJECT,
          payload: project,
        })
      )
      .catch(() => {});
  };
}

export function fetchProjects() {
  return dispatch => {
    dispatch(startFetchProjects());
    return ProjectsService.getProjects()
      .then(projects => dispatch(successFetchProjects(projects)))
      .catch(error => dispatch(errorFetchProjects(error)));
  };
}
