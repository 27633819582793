/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { metrics } from '../../../../../enums';
import { NoData } from '../../../../shared/components';
import { parseIfNumber } from '../../../../shared/utils/utils';

import ScalabilityModelTooltip from './ScalabilityModelTooltip/ScalabilityModelTooltip';
import fields from './fields';

import './ScalabilityModel.scss';

const ScalabilityModel = ({ data }) => {
  return data.length === 0 ? (
    <NoData />
  ) : (
    <ResponsiveContainer>
      <ComposedChart data={data} margin={{ top: 16, right: 0, left: 0, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={metrics.THREADS.accessor}
          label={metrics.THREADS.label}
          tickFormatter={value => parseIfNumber(value)}
        />
        {fields.map(({ name, dataKey, unit, orientation, tickFormatter, color, type, strokeWidth, ...rest }) => [
          <YAxis
            yAxisId={`${name}YAxis`}
            orientation={orientation}
            stroke={color}
            unit={unit}
            tickFormatter={tickFormatter}
            {...rest}
          />,
          <Line
            yAxisId={`${name}YAxis`}
            type={type}
            strokeWidth={strokeWidth}
            dataKey={dataKey}
            name={name}
            stroke={color}
            {...rest}
          />,
        ])}
        <Tooltip content={ScalabilityModelTooltip} />
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ScalabilityModel.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ScalabilityModel;
