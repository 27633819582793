import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { Button } from '../../../../../shared/components';
import { alerts } from '../../../../../shared/utils';
import { TestFieldsService } from '../../../Tests';
import { fetchTestFields } from '../../../Tests/store/testFieldsActions';

import styles from './AddField.module.scss';

const AddField = ({ getTestFields, project }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const formRef = useRef();
  const openFormButtonRef = useRef();
  const labelInputRef = useRef();
  const fieldNameInputRef = useRef();

  const cleanInputs = () => {
    fieldNameInputRef.current.value = '';
    labelInputRef.current.value = '';
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isFormOpen &&
        !isLoading &&
        !formRef.current.contains(event.target) &&
        !openFormButtonRef.current.contains(event.target)
      ) {
        cleanInputs();
        setIsFormOpen(false);
      }
    };

    if (isFormOpen) document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFormOpen, isLoading]);

  const toggleForm = () => {
    setIsFormOpen(current => !current);
  };

  const onCreateFinish = () => {
    cleanInputs();
    setIsLoading(false);
    setIsFormOpen(false);
  };

  const createTestField = async event => {
    event.preventDefault();
    setIsLoading(true);
    const label = labelInputRef.current && labelInputRef.current.value;
    const fieldName = fieldNameInputRef.current && fieldNameInputRef.current.value;

    try {
      await TestFieldsService.createTestField({ label, fieldName }, project._id);
      onCreateFinish();
      getTestFields(project._id);
    } catch (error) {
      if (error.code === 409) {
        await alerts.warning(error.message);
        setIsLoading(false);
      } else {
        alerts.error('An error ocurred during the creation of test field.');
        onCreateFinish();
      }
    }
  };

  return (
    <div className={styles.AddField}>
      <Button text="Add custom field" type="secondary" onClick={toggleForm} ref={openFormButtonRef} />
      <div ref={formRef} className={`${styles.NewFieldForm} ${isFormOpen ? styles.NewFieldFormOpened : ''}`}>
        <form onSubmit={createTestField}>
          <input
            type="text"
            className="form-control-light"
            ref={labelInputRef}
            placeholder="Label (Test Name)"
            name="field_label"
            required
          />
          <input
            type="text"
            className="form-control-light"
            ref={fieldNameInputRef}
            placeholder="Field name (scn_test_name)"
            name="field_name"
            required
          />
          <Button text="Save" disabled={isLoading} isLoading={isLoading} className={styles.NewFieldSaveButton} submit />
        </form>
      </div>
    </div>
  );
};

AddField.propTypes = {
  getTestFields: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  project: state.projects.currentProject,
});

const mapDispatchToProps = dispatch => ({
  getTestFields: projectId => dispatch(fetchTestFields(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddField);
