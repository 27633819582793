import React, { useState } from 'react';
import { StepperContent, StepperContext } from 'react-material-stepper';
import { PropTypes } from 'prop-types';

import { Button } from '../../../../../../../shared/components';
import { STEP1 } from '../UploadFilesStep/UploadFilesStep';

import DynamicField from './DynamicField/DynamicField';

import styles from './TestInfoStep.module.scss';

export const STEP2 = 'step-two';

const TestInfoStep = ({ onCancel }) => {
  const { resolve, getData, goAt } = React.useContext(StepperContext);
  const data = getData(STEP2) || {};

  const [environmentInfo, setEnvironmentInfo] = useState(data.environmentInfo || [{ id: 0, key: '', value: '' }]);
  const [metadata, setMetadata] = useState(data.metadata || [{ id: 0, key: '', value: '' }]);

  const testNameRef = React.useRef();
  const threadNumberRef = React.useRef();
  const tagRef = React.useRef();
  const testDetailRef = React.useRef();
  const versionRef = React.useRef();

  const cleanDynamicData = dynamicData => {
    const validFields = dynamicData.filter(element => element.key.length > 0 && element.value.length > 0);
    const result = {};
    validFields.forEach(element => {
      result[element.key] = element.value;
    });

    return result;
  };

  const resolveData = () => {
    const scn_test_name = testNameRef.current && testNameRef.current.value;
    const scn_threads = threadNumberRef.current && threadNumberRef.current.value;
    const scn_tag = tagRef.current && tagRef.current.value;
    const scn_test_detail = testDetailRef.current && testDetailRef.current.value;
    const scn_version = versionRef.current && versionRef.current.value;

    const scn_environment_info = cleanDynamicData(environmentInfo);
    const scn_metadata = cleanDynamicData(metadata);

    resolve({
      scn_test_name,
      scn_threads,
      scn_tag,
      scn_test_detail,
      scn_version,
      scn_environment_info,
      scn_metadata,
      environmentInfo,
      metadata,
    });
  };

  const back = () => goAt(STEP1);

  const onSubmit = event => {
    event.preventDefault();
    resolveData();
  };

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <div className={styles.Footer}>
          <Button type="secondary" text="Back" onClick={back} />
          <Button type="secondary" className="StepperCancelButton" text="Cancel" onClick={onCancel} />
          <Button text="Create" submit />
        </div>
      }
    >
      <div className={styles.InputRow}>
        <div className={`${styles.InputContainer} ${styles.MediumInput}`}>
          <input
            className={styles.Input}
            name="scn_test_name"
            id="scn_test_name"
            ref={testNameRef}
            defaultValue={data.scn_test_name}
            required
            type="text"
          />
          <label htmlFor="scn_test_name" className={`${styles.InputLabel}`}>
            Test Name
          </label>
        </div>
        <div className={`${styles.InputContainer} ${styles.SmallInput}`}>
          <input
            className={styles.Input}
            name="scn_threads"
            id="scn_threads"
            ref={threadNumberRef}
            defaultValue={data.scn_threads}
            required
            type="text"
          />
          <label htmlFor="scn_threads" className={`${styles.InputLabel}`}>
            Threads Number
          </label>
        </div>
        <div className={`${styles.InputContainer} ${styles.SmallInput}`}>
          <input
            className={styles.Input}
            name="scn_tag"
            id="scn_tag"
            ref={tagRef}
            defaultValue={data.scn_tag}
            required
            type="text"
          />
          <label htmlFor="scn_tag" className={`${styles.InputLabel}`}>
            Tag
          </label>
        </div>
      </div>

      <div className={styles.InputRow}>
        <div className={`${styles.InputContainer} ${styles.LargeInput}`}>
          <input
            className={styles.Input}
            name="scn_test_detail"
            id="scn_test_detail"
            ref={testDetailRef}
            defaultValue={data.scn_test_detail}
            required
            type="text"
          />
          <label htmlFor="scn_test_detail" className={`${styles.InputLabel}`}>
            Test Detail
          </label>
        </div>
        <div className={`${styles.InputContainer} ${styles.SmallInput}`}>
          <input
            className={styles.Input}
            name="scn_version"
            id="scn_version"
            ref={versionRef}
            defaultValue={data.scn_version}
            required
            type="text"
          />
          <label htmlFor="scn_version" className={`${styles.InputLabel}`}>
            Version
          </label>
        </div>
      </div>

      <div className={styles.AdditionalInfoContainer}>
        <DynamicField data={environmentInfo} setData={setEnvironmentInfo} title="Environment Info"></DynamicField>
        <DynamicField data={metadata} setData={setMetadata} title="Metadata"></DynamicField>
      </div>
    </StepperContent>
  );
};

TestInfoStep.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default TestInfoStep;
