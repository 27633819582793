import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { debounce } from 'lodash';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import { metrics } from '../../../../../enums';
import { CheckBox, NoData, Selector } from '../../../../shared/components';

import styles from './EndpointsHeatMap.module.scss';

const settingsMetrics = {
  [metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label]: {
    color: ['#568A49', '#629E51', '#E5AC0E', '#E0752D', '#BF1B00'],
    metric: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor,
    formatter: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter,
    maxValue: 'auto',
    minValue: 'auto',
  },
  [metrics.TOTAL_SUCCESSFUL_TPS.label]: {
    color: ['#BF1B00', '#E0752D', '#E5AC0E', '#629E51', '#568A49'],
    metric: metrics.TOTAL_SUCCESSFUL_TPS.accessor,
    formatter: metrics.TOTAL_SUCCESSFUL_TPS.formatter,
    maxValue: 'auto',
    minValue: 'auto',
  },
  [metrics.FAILED_REQUESTS_PERCENTAGE.label]: {
    color: ['#568A49', '#629E51', '#E5AC0E', '#E0752D', '#BF1B00'],
    metric: metrics.FAILED_REQUESTS_PERCENTAGE.accessor,
    formatter: metrics.FAILED_REQUESTS_PERCENTAGE.formatter,
    maxValue: 100,
    minValue: 0,
  },
};

const metricsKeys = Object.keys(settingsMetrics);

const EndpointsHeatMap = ({ data, scenario }) => {
  const history = useHistory();
  const [leftMargin, setLeftMargin] = useState(120);
  const [customData, setCustomData] = useState({ keys: [], data: [] });
  const [color, setColor] = useState({});
  const [metricSelected, setMetricSelected] = useState(Object.keys(settingsMetrics)[0]);
  const [endpointFilter, setEndpointFilter] = useState('');
  const [minValue, setMinValue] = useState('auto');
  const [maxValue, setMaxValue] = useState('auto');
  const [useRegexFilter, setUseRegexFilter] = useState(false);

  const size = customData.data.length === 0;

  const inputRegExpEndpoint = React.createRef();

  useEffect(() => {
    let processingData = [];
    data.data.forEach(value => {
      const threads = Object.keys(value).filter(element => element !== 'endpoint_label');
      const processingDataObject = {};
      threads.forEach(thread => {
        const metricValue = value[thread][settingsMetrics[metricSelected].metric];
        processingDataObject[thread] = metricValue || metricValue === 0 ? metricValue : undefined;
      });
      processingDataObject.endpoint_label = value.endpoint_label;
      processingData.push(processingDataObject);
    });

    if (endpointFilter !== '') {
      if (useRegexFilter) {
        try {
          const regex = new RegExp(endpointFilter);
          processingData = processingData.filter(element => regex.test(element.endpoint_label));
          // eslint-disable-next-line no-empty
        } catch (err) {}
      } else {
        processingData = processingData.filter(element => {
          const label = element.endpoint_label.toLowerCase();
          return label.startsWith(endpointFilter.toLowerCase());
        });
      }
    }

    setCustomData({ keys: data.keys, data: processingData });
    setColor(settingsMetrics[metricSelected].color);
    setMinValue(settingsMetrics[metricSelected].minValue);
    setMaxValue(settingsMetrics[metricSelected].maxValue);
  }, [data, metricSelected, endpointFilter, useRegexFilter]);

  useEffect(() => {
    const getLeftMargin = () => {
      const labelsContainer = document.querySelector('.test-heat-map svg > g > g:nth-child(2)');
      if (labelsContainer) {
        return labelsContainer.getBBox().width;
      }
      return 300;
    };
    setTimeout(() => {
      setLeftMargin(getLeftMargin());
    }, 100);
  }, [customData]);

  const onChangeMetricHeatMap = value => {
    setMetricSelected(value);
  };

  const debounceSetFilter = useCallback(debounce(setEndpointFilter, 500), []);

  const onFilterChange = event => {
    debounceSetFilter(event.target.value);
  };

  const toggleUseRegexFilter = () => {
    setEndpointFilter('');
    inputRegExpEndpoint.current.value = '';
    setUseRegexFilter(currentUseRef => !currentUseRef);
  };

  const goToEndpointDetailed = useCallback(
    ({ yKey: endpointLabel }) => {
      const encodedScenario = rison.encode({
        ...scenario,
        endpointLabel,
      });

      history.push(`${history.location.pathname}/endpoint?scenario=${encodeURIComponent(encodedScenario)}`);
    },
    [history, scenario]
  );

  return (
    <>
      <div>
        <Selector title="Metrics" options={metricsKeys} selected={metricSelected} onChange={onChangeMetricHeatMap} />
      </div>
      <div className={styles.FilterContainer}>
        <input
          ref={inputRegExpEndpoint}
          onChange={onFilterChange}
          className={`form-control form-control-light ${styles.FilterInput}`}
          placeholder="Filter by endpoint label"
        />
        <div className={styles.CheckBoxContainer} onClick={toggleUseRegexFilter} onKeyDown={toggleUseRegexFilter}>
          <CheckBox checked={useRegexFilter} />
          <span>Use regex</span>
        </div>
      </div>
      {size ? (
        <div style={{ height: '200px' }}>
          <NoData />
        </div>
      ) : (
        <>
          <div className={`${styles.Wrapper}`}>
            <div
              className={`${styles.HeatMap} test-heat-map box-container`}
              style={{ height: `${customData.data.length * 30}px` }}
            >
              <ResponsiveHeatMap
                minValue={minValue}
                maxValue={maxValue}
                data={customData.data}
                keys={customData.keys}
                indexBy="endpoint_label"
                margin={{ top: 20, right: 0, bottom: 50, left: leftMargin }}
                forceSquare={false}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'top',
                  tickSize: 5,
                  tickPadding: 3,
                  tickRotation: 0,
                  legend: metrics.THREADS.label,
                  legendPosition: 'middle',
                  legendOffset: 35,
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                padding={1}
                colors={color}
                cellOpacity={1}
                cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                labelTextColor="#000000"
                animate
                motionStiffness={80}
                motionDamping={9}
                tooltipFormat={settingsMetrics[metricSelected].formatter}
                hoverTarget="rowColumn"
                cellHoverOthersOpacity={0.6}
                onClick={goToEndpointDetailed}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

EndpointsHeatMap.propTypes = {
  data: PropTypes.object.isRequired,
  scenario: PropTypes.object.isRequired,
};

export default EndpointsHeatMap;
