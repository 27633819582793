import React from 'react';
import { connect } from 'react-redux';

import { BreadcrumbSpinner } from '../../../shared/components';

const TransactionBreadcrumb = ({ transaction }) => {
  if (!transaction.name) {
    return <BreadcrumbSpinner />;
  }

  return transaction.name;
};

const mapStateToProps = state => ({
  transaction: state.transactions.currentTransaction,
});

export default connect(mapStateToProps)(TransactionBreadcrumb);
