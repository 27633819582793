import { userActions } from './userActions';

const initialState = {
  user: null,
  error: null,
  isSigningUp: false,
  successSigningUp: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.START_SIGNUP:
      return { ...state, user: null, isSigningUp: true, successSigningUp: false };
    case userActions.SUCCESS_SIGNUP:
      return { ...state, isSigningUp: false, successSigningUp: true, user: action.payload };
    case userActions.ERROR_SIGN_UP:
      return { ...state, error: action.payload, isSigningUp: false };
    default:
      return state;
  }
};

export default userReducer;
