import axios from 'axios';

class PasswordService {
  static async changePassword(currentPassword, newPassword) {
    const passwordValidation = {
      currentPassword,
      newPassword,
    };

    const response = await axios.post('resetPassword', passwordValidation);

    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  }
}

export default PasswordService;
