import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Button, Hint } from '../../shared/components';
import { alerts } from '../../shared/utils';
import UserService from '../services/userService';

import styles from './UserProfile.module.scss';

const UpdateUserInfoForm = ({ user, onUpdate }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email);

  const validate = () => {
    let valid = true;

    if (username.length === 0 || email.length === 0) {
      setErrorMessage('Values cannot be empty.');
      valid = false;
    }

    return valid;
  };

  const cleanError = () => {
    setErrorMessage('');
  };

  const updateUserInfo = async e => {
    e.preventDefault();
    setIsLoading(true);
    const valid = validate();

    if (valid) {
      try {
        await UserService.update({
          username: username.trim(),
          email: email.trim(),
        });
        onUpdate();
        alerts.success('User updated successfully.', () => {
          cleanError();
        });
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    cleanError();
  }, [username, email]);

  return (
    <form onSubmit={updateUserInfo}>
      <h5 className={`${styles.TitleForm} mb-0`}>Login Information</h5>
      <h5 className={styles.Role}>Roles: {user.roles.join(', ')}</h5>
      <div className={styles.Input}>
        <label htmlFor="user-name-input">
          Username{' '}
          <Hint
            content={
              <>
                Modifying the username here <strong>WILL NOT</strong> change it in Kibana in case you are using{' '}
                <strong>Elasticsearch security features</strong> or <strong>OpenDistro</strong>.
              </>
            }
          />
        </label>

        <input
          id="user-name-input"
          className="form-control"
          type="text"
          value={username}
          onChange={e => {
            setUsername(e.target.value);
          }}
          required
        />
      </div>
      <div className={styles.Input}>
        <label htmlFor="email-input">Email</label>
        <input
          id="email-input"
          className="form-control"
          type="email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          required
        />
      </div>

      {!errorMessage || errorMessage === '' ? null : <div className={styles.ErrorMessage}>{errorMessage}</div>}

      <div className="text-center">
        <Button
          className={styles.updateButton}
          text="Save"
          icon="save"
          isLoading={isLoading}
          loadingMessage="Saving..."
          submit
        />
      </div>
    </form>
  );
};

UpdateUserInfoForm.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default UpdateUserInfoForm;
