import bearerTokenInterceptor from './interceptors/bearerTokenInterceptor';
import unauthorizedInterceptor from './interceptors/unauthorizedInterceptor';
import setUpDefaults from './defaults';

const setupInterceptors = store => {
  unauthorizedInterceptor(store);
  bearerTokenInterceptor();
};

const httpService = {
  setup: store => {
    setupInterceptors(store);
    setUpDefaults();
  },
};

export default httpService;
