import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { alerts } from '../shared/utils';
import { SessionActions, UserActions } from '../user';

import LoginForm from './LoginForm/LoginForm';
import SignUpForm from './SignUpForm/SignUpForm';

import styles from './LandingPage.module.scss';

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      flipNow: false,
      signUpExternalError: null,
    };
  }

  flip = () => {
    this.setState(state => ({ flipNow: !state.flipNow }));
  };

  onLogin = (username, password) => {
    const { login } = this.props;
    login(username, password);
  };

  onSignUp = async (username, email, password) => {
    const { signUp } = this.props;
    try {
      await signUp(username, email, password);

      alerts.success('User created correctly, please go to your email and verify the account.', () => {
        this.setState(prevState => ({
          flipNow: !prevState.flipNow,
        }));
      });
    } catch (error) {
      this.setState({
        signUpExternalError: error,
      });
    }
  };

  cleanSignUpError = () => {
    this.setState({
      signUpExternalError: null,
    });
  };

  render() {
    const { loginExternalError, cleanLoginError } = this.props;
    const { signUpExternalError, flipNow } = this.state;
    return (
      <div className={styles.LandingPage}>
        <div className={styles.FlipContainer} id="login">
          <div className={styles.Flipper + (flipNow ? ' flipNow' : '')}>
            <LoginForm
              onLogin={this.onLogin}
              onFlip={this.flip}
              externalError={loginExternalError}
              cleanLoginError={cleanLoginError}
            />
            <SignUpForm
              onSignUp={this.onSignUp}
              onFlip={this.flip}
              externalError={signUpExternalError}
              cleanExternalError={this.cleanSignUpError}
            />
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.defaultProps = {
  loginExternalError: null,
};

LandingPage.propTypes = {
  login: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  loginExternalError: PropTypes.string,
  cleanLoginError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loginExternalError: state.session.error,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(SessionActions.login(username, password)),
  signUp: (username, email, password) => dispatch(UserActions.signUp(username, email, password)),
  cleanLoginError: () => dispatch(SessionActions.cleanErrorLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
