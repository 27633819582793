import axios from 'axios';

const bearerTokenInterceptor = () => {
  axios.interceptors.request.use(
    request => {
      const token = window.localStorage.accessToken;

      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export default bearerTokenInterceptor;
