import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './CheckBox.module.scss';

const CheckBox = ({ checked, hover }) => {
  return (
    <div className={`${styles.CheckBox} ${checked && styles.CheckBox_checked} ${hover && styles.CheckBox_hover}`}>
      <div className={styles.Check}>{checked && <i className="fa fa-check" aria-hidden="true"></i>}</div>
    </div>
  );
};

CheckBox.defaultProps = {
  checked: false,
  hover: false,
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  hover: PropTypes.bool,
};

export default CheckBox;
