import { sessionActions } from './sessionActions';

const initialState = {
  user: null,
  isLogging: false,
  error: null,
  redirectPath: '/explorer',
  selectedDateRange: null,
  tables: {},
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionActions.START_LOGIN:
      return { ...state, logging: true };
    case sessionActions.SUCCESS_LOGIN:
      return { ...state, user: action.payload, isLogging: false };
    case sessionActions.ERROR_LOGIN:
      return { ...state, error: action.payload, isLogging: false };
    case sessionActions.CLEAN_ERROR_LOGIN:
      return { ...state, error: null, isLogging: false };
    case sessionActions.SET_CURRENT_USER:
      return { ...state, user: action.payload };
    case sessionActions.LOGOUT:
      return { ...state, user: null };
    case sessionActions.SET_REDIRECT_PATH:
      return { ...state, redirectPath: action.payload };
    case sessionActions.UPDATE_DATE_RAGE:
      return { ...state, selectedDateRange: action.payload };
    case sessionActions.UPDATE_TABLES_STATE:
      return { ...state, tables: action.payload };
    default:
      return state;
  }
};

export default sessionReducer;
