import { isArray } from 'lodash';

import { alphabet } from '../../../../../enums';

const getObjectWithSameThreads = (array, threads) => array.find(element => element.threads === threads);

// eslint-disable-next-line consistent-return
const mergeScalabilityData = (fullData, incomingData) => {
  if (!fullData) return incomingData;

  if (isArray(fullData)) {
    incomingData.forEach(element => {
      const object = getObjectWithSameThreads(fullData, element.threads);

      if (object) {
        Object.assign(object, element);
      } else {
        fullData.push(element);
      }
    });
    return fullData;
  }
};

const processScenarioScalabilityData = (scenarioData, index) => {
  const scenarioName = `Scenario ${alphabet[index]}`;
  const resData = {};
  scenarioData.forEach(threadGroup => {
    const { threads } = threadGroup;
    delete threadGroup.threads;
    Object.keys(threadGroup).forEach(metric => {
      if (!resData[metric]) {
        resData[metric] = [];
      }
      resData[metric].push({
        threads,
        [scenarioName]: threadGroup[metric],
      });
    });
  });
  return resData;
};

const processScenarioStatisticsData = (scenarioData, index) => {
  const scenarioName = `Scenario ${alphabet[index]}`;
  const resData = [];
  scenarioData.forEach(threadGroup => {
    const { threads } = threadGroup;
    delete threadGroup.threads;
    resData.push({
      threads,
      [scenarioName]: { ...threadGroup },
    });
  });

  return resData;
};

const mergeStatisticsData = (fullData, incomingData) => {
  incomingData.forEach(threadGroup => {
    const object = getObjectWithSameThreads(fullData, threadGroup.threads);
    if (object) {
      Object.assign(object, threadGroup);
    } else {
      fullData.push(threadGroup);
    }
  });
};

export { mergeScalabilityData, processScenarioScalabilityData, processScenarioStatisticsData, mergeStatisticsData };
