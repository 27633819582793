import React, { useEffect, useState } from 'react';
import { Icon } from 'react-fa';
import { PropTypes } from 'prop-types';

import { Button } from '../../shared/components';
import VerifyAccountService from '../services/VerifyAccountService';

import logo from '../../../assets/images/icon.png';

import styles from './ConfirmAccount.module.scss';

const ConfirmAccount = ({ match }) => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const verifyToken = async () => {
      const { token } = match.params;

      try {
        const username = await VerifyAccountService.verifyToken(token);
        setMessage(`${username}, thanks for verifying your account.`);
      } catch (err) {
        setError(true);
        setMessage(err.message);
      }
    };

    verifyToken();
  }, [error.message, match.params]);

  return (
    <div className={styles.MainContent}>
      <div className={styles.Content}>
        <div className={styles.TitleContainer}>
          <img src={logo} className={styles.Logo} alt="logo" />
          <h1 className={styles.Title}>Performance Explorer</h1>
        </div>
        <div className={styles.MessageContainer}>
          {message.length > 0 && <Icon className={styles.Icon} name={error ? 'warning' : 'check'} />}
          <span className={styles.Message}>{message}</span>
        </div>
        <div className={styles.ButtonContainer}>
          <Button
            text="Home"
            onClick={() => {
              window.location = '/';
            }}
          />
        </div>
      </div>
    </div>
  );
};

ConfirmAccount.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ConfirmAccount;
