import React from 'react';
import { PropTypes } from 'prop-types';

import { parseIfNumber } from '../../../utils/utils';

import styles from './ScenarioField.module.scss';

const ScenarioField = ({ label, fieldName, value, isUndefined }) => {
  return (
    <div className={styles.Field} title={fieldName}>
      <div className={styles.LabelContainer}>
        <span className={styles.Label} title={fieldName}>
          {label}
        </span>

        <div className={`${styles.ValueContainer} ${isUndefined && styles.IsUndefined}`}>
          <span className={styles.Value}>{parseIfNumber(value)}</span>
        </div>
      </div>
    </div>
  );
};

ScenarioField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  isUndefined: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ScenarioField;
