import React, { useEffect, useState } from 'react';
import { groupBy, sumBy } from 'lodash';
import { PropTypes } from 'prop-types';

import { metrics } from '../../../../../../enums';
import { calculatePercentage, parseIfNumber, parseMilliseconds } from '../../../../../shared/utils/utils';

import styles from './EndpointInfo.module.scss';

const EndpointInfo = ({ scalabilityData, responseCodeData, selectedThreadGroup }) => {
  const [currentScalabilityData, setCurrentScalabilityData] = useState({});
  const [currentResponseCodeData, setCurrentResponseCodeData] = useState({});

  useEffect(() => {
    const selectedData = scalabilityData.find(el => String(el.threads) === selectedThreadGroup);
    if (selectedData) {
      setCurrentScalabilityData(selectedData);
    }
  }, [scalabilityData, selectedThreadGroup]);

  useEffect(() => {
    let selectedData = responseCodeData[selectedThreadGroup];
    const totalSamples = sumBy(selectedData, metrics.SAMPLES.accessor);
    selectedData = groupBy(selectedData, metrics.STATUS.accessor);

    const successfulSamples = sumBy(selectedData.OK, metrics.SAMPLES.accessor);
    const failedSamples = sumBy(selectedData.KO, metrics.SAMPLES.accessor);
    setCurrentResponseCodeData({
      totalSamples,
      successfulSamples,
      failedSamples,
      successfulSamplesPercentage: calculatePercentage(successfulSamples, totalSamples),
      failedSamplesPercentage: calculatePercentage(failedSamples, totalSamples),
    });
  }, [responseCodeData, scalabilityData, selectedThreadGroup]);

  const [averageSuccessfulResponseTime, averageSuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor]
  );
  const [minSuccessfulResponseTime, minSuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.MIN_SUCCESSFUL_RESPONSE_TIME.accessor]
  );
  const [maxSuccessfulResponseTime, maxSuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.MAX_SUCCESSFUL_RESPONSE_TIME.accessor]
  );
  const [percentile90SuccessfulResponseTime, percentile90SuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.accessor]
  );
  const [percentile95SuccessfulResponseTime, percentile95SuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.accessor]
  );
  const [percentile99SuccessfulResponseTime, percentile99SuccessfulResponseTimeUnits] = parseMilliseconds(
    currentScalabilityData[metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.accessor]
  );

  return (
    <>
      <div className={styles.InfoContainer}>
        <div className={`${styles.LargeMetric} box-container`}>
          <div
            className={styles.LargeMetricValue}
            title={`${averageSuccessfulResponseTime}${averageSuccessfulResponseTimeUnits}`}
          >
            {averageSuccessfulResponseTime}
            {averageSuccessfulResponseTimeUnits}
          </div>
          <div className={styles.LargeMetricLabel}>{metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label}</div>
        </div>

        <div className={`${styles.LargeMetric} box-container`}>
          <div
            className={styles.LargeMetricValue}
            title={`${parseIfNumber(currentScalabilityData[metrics.TOTAL_SUCCESSFUL_TPS.accessor])}${
              metrics.TOTAL_SUCCESSFUL_TPS.unit
            }`}
          >
            {parseIfNumber(currentScalabilityData[metrics.TOTAL_SUCCESSFUL_TPS.accessor])}
            {metrics.TOTAL_SUCCESSFUL_TPS.unit}
          </div>
          <div className={styles.LargeMetricLabel}>{metrics.TOTAL_SUCCESSFUL_TPS.label}</div>
        </div>

        <div className={`${styles.RequestsContainer} box-container`}>
          <div className={styles.RequestsBox}>
            <div className={styles.RequestsCount}>{parseIfNumber(currentResponseCodeData.totalSamples)}</div>
            <div className={styles.RequestsTitle}>Requests</div>
          </div>
          <div className={styles.RequestsBox}>
            <div className={`${styles.RequestsCount}`}>{parseIfNumber(currentResponseCodeData.successfulSamples)}</div>
            <div className={styles.RequestsTitle}>
              <span className={`${styles.Label} ${styles.OK}`}>Successful</span>
              <span className={styles.Percentage}>
                <span className={styles.Percentage}>
                  {parseIfNumber(currentResponseCodeData.successfulSamplesPercentage)}%
                </span>
              </span>
            </div>
          </div>

          <div className={styles.RequestsBox}>
            <div className={`${styles.RequestsCount}`}>{parseIfNumber(currentResponseCodeData.failedSamples)}</div>
            <div className={styles.RequestsTitle}>
              <span className={`${styles.Label} ${styles.KO}`}>Failed</span>
              <span className={styles.Percentage}>
                {parseIfNumber(currentResponseCodeData.failedSamplesPercentage)}%
              </span>
            </div>
          </div>
        </div>
      </div>

      <h4 className="page-subtitle">Successful response time</h4>

      <div className={styles.ResponseTimeMetrics}>
        <div className={`${styles.Metric} box-container`}>
          <div className={styles.MetricValue} title={`${minSuccessfulResponseTime}${minSuccessfulResponseTimeUnits}`}>
            {minSuccessfulResponseTime}
            {minSuccessfulResponseTimeUnits}
          </div>
          <div className={styles.MetricLabel} title={metrics.MIN_SUCCESSFUL_RESPONSE_TIME.label}>
            {metrics.MIN_SUCCESSFUL_RESPONSE_TIME.label}
          </div>
        </div>
        <div className={`${styles.Metric} box-container`}>
          <div className={styles.MetricValue} title={`${maxSuccessfulResponseTime}${maxSuccessfulResponseTimeUnits}`}>
            {maxSuccessfulResponseTime}
            {maxSuccessfulResponseTimeUnits}
          </div>
          <div className={styles.MetricLabel} title={metrics.MAX_SUCCESSFUL_RESPONSE_TIME.label}>
            {metrics.MAX_SUCCESSFUL_RESPONSE_TIME.label}
          </div>
        </div>
        <div className={`${styles.Metric} box-container`}>
          <div
            className={styles.MetricValue}
            title={`${percentile90SuccessfulResponseTime}${percentile90SuccessfulResponseTimeUnits}`}
          >
            {percentile90SuccessfulResponseTime}
            {percentile90SuccessfulResponseTimeUnits}
          </div>
          <div className={styles.MetricLabel} title={metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.label}>
            {metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.label}
          </div>
        </div>
        <div className={`${styles.Metric} box-container`}>
          <div
            className={styles.MetricValue}
            title={`${percentile95SuccessfulResponseTime}${percentile95SuccessfulResponseTimeUnits}`}
          >
            {percentile95SuccessfulResponseTime}
            {percentile95SuccessfulResponseTimeUnits}
          </div>
          <div className={styles.MetricLabel} title={metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.label}>
            {metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.label}
          </div>
        </div>
        <div className={`${styles.Metric} box-container`}>
          <div
            className={styles.MetricValue}
            title={`${percentile99SuccessfulResponseTime}${percentile99SuccessfulResponseTimeUnits}`}
          >
            {percentile99SuccessfulResponseTime}
            {percentile99SuccessfulResponseTimeUnits}
          </div>
          <div className={styles.MetricLabel} title={metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.label}>
            {metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.label}
          </div>
        </div>
      </div>
    </>
  );
};

EndpointInfo.propTypes = {
  scalabilityData: PropTypes.array.isRequired,
  responseCodeData: PropTypes.object.isRequired,
  selectedThreadGroup: PropTypes.string.isRequired,
};

export default EndpointInfo;
