import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import httpService from './axios/index';
import App from './App';
import history from './history';
import { unregister } from './registerServiceWorker';
import store from './store';

import './index.scss';
import 'react-widgets/dist/css/react-widgets.css';

unregister();

httpService.setup(store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
