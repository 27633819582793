import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import { DatePicker, ScenarioFields, Spinner } from '../../../shared/components';
import useQuery from '../../../shared/hooks/useQuery/useQuery';
import { parseIfNumber, sanitizeDateRangeObject } from '../../../shared/utils/utils';
import setExplorerState from '../../store/explorerActions';

import EndpointsHeatMap from './EndpointsHeatMap/EndpointsHeatMap';
import EndpointsStatistics from './EndpointsStatistics/EndpointsStatistics';
import ScalabilityModel from './ScalabilityModel/ScalabilityModel';
import TestsService from './services/testsService';

import styles from './Tests.module.scss';

const Tests = ({ initExplorerState, history }) => {
  const [queryScenario, setQueryScenario] = useQuery('scenario', '');
  const urlParams = useParams();

  const [isScalabilityModelLoading, setIsScalabilityModelLoading] = useState(true);
  const [scalabilityModelData, setScalabilityModelData] = useState([]);

  const [isEndpointsHeatMapLoading, setIsEndpointsHeatMapLoading] = useState(true);
  const [endpointsHeatMapData, setEndpointsHeatMapData] = useState([]);

  const [isEndpointsStatisticsLoading, setIsEndpointsStatisticsLoading] = useState(true);
  const [endpointsStatistics, setEndpointsStatistics] = useState({});

  const [isScenarioInfoLoading, setIsScenarioInfoLoading] = useState(true);
  const [scenarioInfo, setScenarioInfo] = useState({});

  const [scenario, setScenario] = useState({
    fields: [],
    values: {},
    dateRange: {},
  });
  const [dateSelection, setDateSelection] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });

  const { projectId, applicationId, transactionId } = urlParams;

  useEffect(() => {
    const getScenarioInfo = async () => {
      setIsScenarioInfoLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getScenarioInfo(projectId, applicationId, transactionId, scenario);

        setScenarioInfo(data);
        setIsScenarioInfoLoading(false);
      }
    };
    getScenarioInfo();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    const getScalabilityData = async () => {
      setIsScalabilityModelLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getScalabilityData(projectId, applicationId, transactionId, scenario);
        setScalabilityModelData(data);
        setIsScalabilityModelLoading(false);
      }
    };
    getScalabilityData();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    const getEndpointsResponseTimeData = async () => {
      setIsEndpointsHeatMapLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getEndpointsResponseTimeData(projectId, applicationId, transactionId, scenario);
        setEndpointsHeatMapData(data);
        setIsEndpointsHeatMapLoading(false);
      }
    };
    getEndpointsResponseTimeData();
  }, [applicationId, projectId, transactionId, scenario]);

  useEffect(() => {
    const getEndpointsStatistics = async () => {
      setIsEndpointsStatisticsLoading(true);
      if (scenario.fields.length > 0) {
        const data = await TestsService.getEndpointsStatistics(projectId, applicationId, transactionId, scenario);
        setEndpointsStatistics(data);
        setIsEndpointsStatisticsLoading(false);
      }
    };
    getEndpointsStatistics();
  }, [applicationId, projectId, transactionId, scenario]);

  const isScenarioObjectValid = scenarioObject =>
    scenarioObject.fields && scenarioObject.values && scenarioObject.dateRange;

  const getTransactionLink = () => {
    return `/explorer/project/${projectId}/application/${applicationId}/transaction/${transactionId}`;
  };

  useEffect(() => {
    if (queryScenario.length > 0) {
      const decodedScenario = rison.decode(decodeURIComponent(queryScenario));

      if (isScenarioObjectValid(decodedScenario)) {
        setScenario(decodedScenario);
        setDateSelection(sanitizeDateRangeObject(decodedScenario.dateRange));
        initExplorerState(urlParams);
        return;
      }
    }

    history.push(getTransactionLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryScenario]);

  useEffect(() => {
    if (scenario.fields.length > 0) setQueryScenario(rison.encode(scenario));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  const onDateSelectionChange = range => {
    setScenario(currentScenario => {
      return { ...currentScenario, dateRange: range };
    });
    setDateSelection(range);
  };

  return (
    <>
      <h4 className="page-title">Scenario</h4>
      <div className={styles.Header}>
        <DatePicker dateSelection={dateSelection} onSelectionChange={onDateSelectionChange} />
      </div>

      <div className={styles.ScenarioMainContainer}>
        <div className={`${styles.TestsNumberContainer} box-container`}>
          <span className={styles.TestsNumber}>
            {isScenarioInfoLoading ? '0' : parseIfNumber(scenarioInfo.count) || 0}
          </span>
          <span className={styles.Testsdescription}>Executions</span>
        </div>
        <div className={`${styles.ScenarioContainer} box-container`}>
          <div className={styles.Content}>
            <ScenarioFields scenario={scenario} />
          </div>
        </div>
      </div>

      <h4 className="page-subtitle">Scalability Model</h4>
      <div className={`${styles.ChartContainer} box-container`}>
        <div className={styles.ScalabilityModelWrapper}>
          {isScalabilityModelLoading ? <Spinner /> : <ScalabilityModel data={scalabilityModelData} />}
        </div>
      </div>

      <h4 className="page-subtitle">Summary by Endpoint</h4>
      <div className={`${styles.ChartContainer}`}>
        <div>
          {isEndpointsHeatMapLoading ? (
            <Spinner />
          ) : (
            <EndpointsHeatMap data={endpointsHeatMapData} scenario={scenario} />
          )}
        </div>
      </div>

      <h4 className="page-subtitle">Endpoints Statistics</h4>
      <div className={`${styles.ChartContainer} box-container`}>
        {isEndpointsStatisticsLoading ? (
          <Spinner />
        ) : (
          <EndpointsStatistics data={endpointsStatistics} scenario={scenario} />
        )}
      </div>
    </>
  );
};

Tests.propTypes = {
  initExplorerState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
});

export default connect(null, mapDispatchToProps)(Tests);
