/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icon from 'react-fa';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { uniqueId } from 'lodash';
import { PropTypes } from 'prop-types';

import { appColors } from '../../../../enums';

import styles from './TableButton.module.scss';

const TableButton = React.forwardRef(
  ({ onClick, icon: iconName, spin, className, isLoading, isLink, to, tooltip, ...rest }, ref) => {
    const id = uniqueId('hint');
    const icon = (iconName || isLoading) && (
      <Icon spin={isLoading ? true : spin} className={styles.Icon} name={isLoading ? 'refresh' : iconName} />
    );
    let button;

    if (isLink) {
      button = (
        <Link
          data-tip
          data-for={id}
          type="button"
          to={to}
          className={`table-action-button ${className}`}
          onClick={e => {
            e.stopPropagation();
            onClick(e);
          }}
          ref={ref}
          {...rest}
        >
          {icon}
        </Link>
      );
    } else {
      button = (
        <button
          data-tip
          data-for={id}
          type="button"
          className={`table-action-button ${className}`}
          onClick={e => {
            e.stopPropagation();
            onClick(e);
          }}
          {...rest}
        >
          {icon}
        </button>
      );
    }

    return (
      <>
        {button}
        {tooltip && (
          <ReactTooltip
            id={id}
            className={styles.Tooltip}
            place="bottom"
            effect="solid"
            backgroundColor={appColors.red}
          >
            <p className={styles.TooltipParagraph}>{tooltip}</p>
          </ReactTooltip>
        )}
      </>
    );
  }
);

TableButton.defaultProps = {
  icon: '',
  onClick: () => {},
  className: '',
  spin: false,
  isLoading: false,
  isLink: false,
  to: '',
  tooltip: undefined,
};

TableButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  spin: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isLink: PropTypes.bool,
  to: PropTypes.string,
  tooltip: PropTypes.string,
};

export default TableButton;
