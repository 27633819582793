import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import * as SessionActions from '../store/sessionActions';

import ChangePasswordForm from './ChangePasswordForm';
import UpdateUserInfoForm from './UpdateUserInfoForm';

import styles from './UserProfile.module.scss';

const UserProfile = ({ user, refreshCurrentUser }) => {
  return (
    <div className={styles.MainContent}>
      <div className={`${styles.UserProfileContainer} box-container`}>
        <div className={styles.Body}>
          <div className={styles.UserInfoForm}>
            <UpdateUserInfoForm user={user} onUpdate={refreshCurrentUser} />
          </div>
        </div>
      </div>
      <div className={`${styles.UserProfileContainer} box-container`}>
        <div className={styles.Body}>
          <div className={styles.ChangePasswordContainer}>
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  refreshCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
  refreshCurrentUser: () => dispatch(SessionActions.refreshCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
