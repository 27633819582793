import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import { metrics, tables } from '../../../../../enums';
import { Table, TableButton } from '../../../../shared/components';
import { genericTableFilterMethod, parseIfNumber } from '../../../../shared/utils/utils';

import './EndpointsStatisticsTable.scss';
import styles from './EndpointsStatistics.module.scss';

const EndpointsStatistics = ({ data, scenario }) => {
  const history = useHistory();
  const [threadsGroups] = useState(Object.keys(data) || []);
  const [selectedThreadsGroup, setSelectedThreadsGroup] = useState(String(Object.keys(data)[0]) || '');

  const changeThreadGroup = threadsGroup => setSelectedThreadsGroup(threadsGroup);

  const getFooter = (field, formatter) => {
    const value = data[selectedThreadsGroup] && data[selectedThreadsGroup][0] && data[selectedThreadsGroup][0][field];
    if (value || value === 0) {
      return formatter ? formatter(value) : value;
    }
    return undefined;
  };

  const getLinkToEndpointDetail = useCallback(
    endpoint => {
      const encodedScenario = rison.encode({
        ...scenario,
        endpointLabel: endpoint.original.endpoint_label,
      });

      return `${history.location.pathname}/endpoint?scenario=${encodeURIComponent(encodedScenario)}`;
    },
    [history.location.pathname, scenario]
  );

  const responseTimeFormatter = metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter;

  const columns = [
    {
      Header: 'Label',
      accessor: 'endpoint_label',
      style: { textAlign: 'left' },
      filterable: true,
      filterMethod: genericTableFilterMethod('endpoint_label'),
      Footer: 'Total',
    },
    {
      Header: 'Requests',
      style: { textAlign: 'left' },
      columns: [
        {
          Header: 'Total',
          accessor: metrics.SAMPLES.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.SAMPLES.accessor),
          Cell: cell => parseIfNumber(cell.value),
          Footer: getFooter(metrics.SAMPLES.accessor),
        },
        {
          Header: 'Failed',
          accessor: metrics.FAILED_REQUESTS.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.FAILED_REQUESTS.accessor),
          Cell: cell => parseIfNumber(cell.value),
          Footer: getFooter(metrics.FAILED_REQUESTS.accessor),
        },
        {
          Header: 'Failed %',
          accessor: metrics.FAILED_REQUESTS_PERCENTAGE.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          Cell: cell => `${parseIfNumber(cell.value)}%`,
          filterMethod: genericTableFilterMethod(metrics.FAILED_REQUESTS_PERCENTAGE.accessor),
          Footer: getFooter(metrics.FAILED_REQUESTS_PERCENTAGE.accessor, metrics.FAILED_REQUESTS_PERCENTAGE.formatter),
        },
      ],
    },
    {
      Header: 'Successful Response Time',
      style: { textAlign: 'left' },
      columns: [
        {
          Header: 'Average',
          accessor: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
        {
          Header: 'Minimum',
          accessor: metrics.MIN_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.MIN_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.MIN_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
        {
          Header: 'Maximum',
          accessor: metrics.MAX_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.MAX_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.MAX_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
        {
          Header: '90th Percentile',
          accessor: metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.PERCENTILE90_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
        {
          Header: '95th Percentile',
          accessor: metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.PERCENTILE95_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
        {
          Header: '99th Percentile',
          accessor: metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.accessor,
          style: { textAlign: 'left' },
          filterable: true,
          filterMethod: genericTableFilterMethod(metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.accessor),
          Cell: cell => responseTimeFormatter(cell.value),
          Footer: getFooter(metrics.PERCENTILE99_SUCCESSFUL_RESPONSE_TIME.accessor, responseTimeFormatter),
        },
      ],
    },
    {
      Header: metrics.TOTAL_SUCCESSFUL_TPS.label,
      accessor: metrics.TOTAL_SUCCESSFUL_TPS.accessor,
      style: { textAlign: 'left' },
      width: 180,
      filterable: true,
      filterMethod: genericTableFilterMethod(metrics.TOTAL_SUCCESSFUL_TPS.accessor),
      Cell: cell => metrics.TOTAL_SUCCESSFUL_TPS.formatter(cell.value),
      Footer: getFooter(metrics.TOTAL_SUCCESSFUL_TPS.accessor, metrics.TOTAL_SUCCESSFUL_TPS.formatter),
    },
    {
      Header: 'Actions',
      width: 80,
      resizable: false,
      className: 'table-action-cell',
      sortable: false,
      Cell: testsScenario => (
        <>
          <TableButton isLink icon="eye" to={getLinkToEndpointDetail(testsScenario)} tooltip="Open endpoint detail" />
        </>
      ),
    },
  ];

  return (
    <>
      <h5 className="title-form">Virtual Users</h5>
      {threadsGroups.length > 0 && (
        <DropdownList
          className={`${styles.VirtualUsersDropdown}`}
          data={threadsGroups}
          value={selectedThreadsGroup}
          textField="virtualUsers"
          onChange={changeThreadGroup}
          globalKeyEvents
        />
      )}
      <Table
        id={tables.ENDPOINTS_STATISTICS}
        className="EndpointsStatisticsTable"
        data={data[selectedThreadsGroup] && data[selectedThreadsGroup].slice(1)}
        columns={columns}
        defaultPageSize={10}
        storeFilters={false}
      />
    </>
  );
};

EndpointsStatistics.propTypes = {
  data: PropTypes.object.isRequired,
  scenario: PropTypes.object.isRequired,
};

export default EndpointsStatistics;
