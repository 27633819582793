import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './UploadedFile.module.scss';

const UploadedFile = ({ file, onFileRemoved }) => {
  const onRemove = e => {
    e.stopPropagation();
    onFileRemoved(file);
  };

  return (
    <div title={file.path} className={styles.Container}>
      <span className={styles.RemoveFileButton} onKeyDown={onRemove} onClick={onRemove}>
        x
      </span>
      <span className={styles.FilePath}>{file.path}</span>
    </div>
  );
};

UploadedFile.propTypes = {
  file: PropTypes.object.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
};

export default UploadedFile;
