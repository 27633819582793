/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import logo from '../../../assets/images/logo.svg';
import { Button } from '../../shared/components';

import styles from '../LoginForm/LoginForm.module.scss';

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      error: '',
      isValid: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.signUpSuccess) {
      return { ...state, flipNow: !state.flipNow };
    }
    return { ...state };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSigningUp && !this.props.isSigningUp && this.props.successSigningUp) {
      this.cleanForm();
    }
  }

  isValidForm = (username, password, passwordConfirmation) => {
    const { cleanExternalError } = this.props;
    cleanExternalError();

    const isValid = username !== '' && password !== '' && password === passwordConfirmation;

    if (!isValid) {
      this.setState({
        error:
          "Input error. Please don't leave any field in blank. Also, the password and password confirmation fields must be the same.",
        isValid: false,
      });
    } else {
      this.setState({
        error: null,
        isValid: true,
      });
    }
  };

  isValidEmail = email => {
    const isValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!isValid) {
      this.setState({
        error: 'Verify the email, it must be a valid email.',
        isValid: false,
      });
    } else {
      this.setState({
        error: null,
        isValid: true,
      });
    }
  };

  checkEmail = (email, emailConfirmation) => {
    const { cleanExternalError } = this.props;
    cleanExternalError();

    const isValid = email === emailConfirmation;

    if (!isValid) {
      this.setState({
        error: 'Must be the same previous email.',
        isValid: false,
      });
    } else {
      this.setState({
        error: null,
        isValid: true,
      });
    }
  };

  showError = error => (error ? <div className={styles.alertDanger}>{error}</div> : null);

  showWarning = error => (error ? <div className={styles.alertWarning}>{error}</div> : null);

  handleChangeUsername = e => {
    const { password, passwordConfirmation } = this.state;
    const value = e.target.value.trim();
    this.isValidForm(value, password, passwordConfirmation);
    this.setState({ username: value });
  };

  handleChangeUserEmail = e => {
    const value = e.target.value.trim();
    this.isValidEmail(value);
    this.setState({ email: value });
  };

  handleChangeUserEmailConfirmation = e => {
    const { email } = this.state;
    const value = e.target.value.trim();
    this.isValidEmail(value);
    this.checkEmail(value, email);
    this.setState({ emailConfirmation: value });
  };

  handleChangePassword = e => {
    const { username, passwordConfirmation } = this.state;
    const value = e.target.value.trim();
    this.isValidForm(username, value, passwordConfirmation);
    this.setState({ password: value });
  };

  handleChangePasswordConfirmation = e => {
    const { username, password } = this.state;
    const value = e.target.value.trim();
    this.isValidForm(username, password, value);
    this.setState({ passwordConfirmation: value });
  };

  cleanForm = () => {
    this.setState({
      username: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      error: '',
    });
  };

  render() {
    const { username, email, emailConfirmation, password, passwordConfirmation, error, isValid } = this.state;
    const { externalError, onSignUp, onFlip, isSigningUp } = this.props;
    return (
      <div className="back">
        <br />
        <form>
          <img src={logo} alt="logo" className="main-logo" />
          <input
            type="text"
            value={username}
            placeholder="Username"
            onChange={this.handleChangeUsername}
            className="col-md-12 form-control"
            required
          />
          <input
            className="form-control"
            type="email"
            value={email}
            placeholder="Email"
            onChange={this.handleChangeUserEmail}
            required
          />
          <input
            type="email"
            value={emailConfirmation}
            placeholder="Confirm Email"
            onChange={this.handleChangeUserEmailConfirmation}
            className="form-control"
            required
          />
          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={this.handleChangePassword}
            className="col-md-12 form-control"
            required
          />
          <input
            type="password"
            value={passwordConfirmation}
            placeholder="Confirm Password"
            onChange={this.handleChangePasswordConfirmation}
            className="mb-2 form-control"
            required
          />
          {this.showWarning(error)}
          {this.showError(externalError)}
          <Button
            text="Sign up"
            onClick={e => {
              e.preventDefault();
              return onSignUp(username, email, password);
            }}
            isLoading={isSigningUp}
            disabled={!isValid}
            submit
          />
          <button className={styles.BtnCreateAccount} type="button" id="goBack" onClick={onFlip}>
            Go back to sign in
          </button>
        </form>
      </div>
    );
  }
}

SignUpForm.defaultProps = {
  externalError: null,
};

SignUpForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  onFlip: PropTypes.func.isRequired,
  externalError: PropTypes.string,
};

const mapStateToProps = state => ({
  isSigningUp: state.user.isSigningUp,
  successSigningUp: state.user.successSigningUp,
});

export default connect(mapStateToProps)(SignUpForm);
