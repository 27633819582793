import ApplicationsService from '../services/applicationsService';

export const applicationsActions = {
  START_FETCH_APPLICATIONS: '[APPLICATIONS] Start fetching applications',
  SUCCESS_FETCH_APPLICATIONS: '[APPLICATIONS] Success fetching applications',
  ERROR_FETCH_APPLICATIONS: '[APPLICATIONS] Error fetching applications',
  CLEAR_APPLICATIONS: '[APPLICATIONS] Clear applications',
  SET_CURRENT_APPLICATION: '[APPLICATIONS] Set current application',
};

export function startFetchApplications() {
  return {
    type: applicationsActions.START_FETCH_APPLICATIONS,
  };
}

export function successFetchApplications(applications) {
  return {
    type: applicationsActions.SUCCESS_FETCH_APPLICATIONS,
    payload: applications,
  };
}

export function errorFetchApplications(error) {
  return {
    type: applicationsActions.ERROR_FETCH_APPLICATIONS,
    payload: error,
  };
}

export function clearApplications() {
  return {
    type: applicationsActions.CLEAR_APPLICATIONS,
  };
}

export function fetchApplications(projectId) {
  return dispatch => {
    dispatch(startFetchApplications());
    return ApplicationsService.getApplicationsByProjectId(projectId)
      .then(applications => dispatch(successFetchApplications(applications)))
      .catch(error => dispatch(errorFetchApplications(error)));
  };
}

export function setCurrentApplication(projectId, applicationId) {
  return dispatch => {
    return ApplicationsService.getApplicationById(projectId, applicationId)
      .then(application =>
        dispatch({
          type: applicationsActions.SET_CURRENT_APPLICATION,
          payload: application,
        })
      )
      .catch(() => {});
  };
}
