import qs from 'query-string';

export const setQueryStringWithoutPageReload = qsValue => {
  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const setQueryStringValue = (key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString);

  const newQsValue = qs.stringify({ ...values, [key]: value }, { strict: false });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};
