/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Roles from '../../../../containers/auth/Roles';
import { roles, tables } from '../../../../enums';
import { Button, Spinner, Table, TableButton } from '../../../shared/components';
import { alerts } from '../../../shared/utils';
import { formatDateToLocale } from '../../../shared/utils/utils';
import * as sessionActions from '../../../user/store/sessionActions';
import setExplorerState from '../../store/explorerActions';
import { ApplicationsActions, ApplicationsService } from '../Application';

const Project = ({ applications, isFetchingApplications, getApplications, initExplorerState, clearTableFilters }) => {
  const [isLoading, setIsLoading] = useState(true);

  const urlParams = useParams();
  const { projectId } = urlParams;

  useEffect(() => {
    initExplorerState(urlParams);
    getApplications(projectId);
    setIsLoading(false);
  }, [getApplications, initExplorerState, projectId, urlParams]);

  const getApplicationUrl = applicationId => {
    return `/explorer/project/${projectId}/application/${applicationId}`;
  };

  const deleteApplication = async application => {
    try {
      await ApplicationsService.deleteApplication(projectId, application._id);
      alerts.success(alerts.empathizeVars`Your Application ${application.name} was deleted.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getApplications(projectId);
  };

  const handleApplicationDeletion = selectedApplication => {
    alerts.deleteWarningDangerZone({
      subjectToDelete: selectedApplication.name,
      entity: 'application',
      message:
        'This action deletes all the data of transactions' +
        ' and tests of this application in MongoDB and Elasticsearch.',
      onConfirm: () => {
        deleteApplication(selectedApplication);
      },
    });
  };

  const createNewApplication = async name => {
    try {
      await ApplicationsService.createApplication(name, projectId);
      alerts.success(alerts.empathizeVars`Your application ${name} was created.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getApplications(projectId);
  };

  const onNewApplication = async () => {
    const applicationName = await alerts.entityCreatorForm('application');
    if (applicationName) createNewApplication(applicationName);
  };

  let viewProjectTable = null;

  const columns = [
    {
      Header: 'Name',
      headerClassName: 'justify-content-start',
      accessor: 'name',
      filterable: true,
      id: 'name',
      Cell: application => (
        <span className="table-featured-cell" title={application.value}>
          {application.value}
        </span>
      ),
    },
    {
      Header: 'Transactions',
      accessor: 'transactions_number',
      headerClassName: 'disabled-filter justify-content-start',
      id: 'transactions',
      filterable: false,
    },
    {
      Header: 'Last update',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      id: 'lastUpdate',
      accessor: ({ updatedAt, createdAt }) => {
        let date = createdAt;
        if (updatedAt) {
          date = updatedAt;
        }
        return date;
      },
      Cell: ({ value }) => formatDateToLocale(new Date(value)),
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Actions',
      width: 250,
      resizable: false,
      headerClassName: 'disabled-filter',
      className: 'table-action-cell',
      sortable: false,
      Cell: application => (
        <>
          <TableButton isLink icon="eye" to={getApplicationUrl(application.original._id)} tooltip="Open application" />
          <Roles admit={[roles.DEVELOPER]}>
            <TableButton
              icon="trash"
              onClick={() => handleApplicationDeletion(application.original)}
              tooltip="Delete application"
            />
          </Roles>
        </>
      ),
    },
  ];
  viewProjectTable = (
    <Table
      id={tables.APPLICATIONS}
      data={applications === '' ? [] : applications}
      columns={columns}
      defaultFilterMethod={(filter, row) => String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())}
      defaultSorted={[
        {
          id: 'lastUpdate',
          desc: true,
        },
      ]}
      hoverableRows
    />
  );

  return (
    <>
      <div className="page-header">
        <h4 className="page-title">Applications</h4>
        <div className="header-actions">
          <Button text="Clear filters" type="secondary" onClick={() => clearTableFilters(tables.APPLICATIONS)} />
          <Roles admit={[roles.DEVELOPER]}>
            <Button text="New application" icon="plus" onClick={onNewApplication} />
          </Roles>
        </div>
      </div>
      <div className="box-container">{isFetchingApplications || isLoading ? <Spinner /> : viewProjectTable}</div>
    </>
  );
};

Project.defaultProps = {
  applications: [],
  isFetchingApplications: false,
  getApplications: () => [],
};

Project.propTypes = {
  applications: PropTypes.array,
  isFetchingApplications: PropTypes.bool,
  getApplications: PropTypes.func,
  initExplorerState: PropTypes.func.isRequired,
  clearTableFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  applications: state.applications.applications,
  isFetchingApplications: state.applications.isFetchingApplications,
});

const mapDispatchToProps = dispatch => ({
  getApplications: projectId => dispatch(ApplicationsActions.fetchApplications(projectId)),
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
  clearTableFilters: tableId => dispatch(sessionActions.clearTableFilters(tableId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
