import axios from 'axios';

class ApplicationsService {
  static async getApplicationsByProjectId(projectId) {
    const response = await axios.get(`projects/${projectId}/applications`);
    const { applications } = response.data;

    return applications;
  }

  static async getApplicationById(projectId, applicationId) {
    const response = await axios.get(`projects/${projectId}/applications/${applicationId}`);
    const { application } = response.data;
    return application;
  }

  static async createApplication(name, projectId) {
    const newApplication = {
      name,
    };

    const response = await axios.post(`projects/${projectId}/applications`, newApplication);
    if (!response.data.ok) {
      throw response.data;
    }
    return response.data.application;
  }

  static async deleteApplication(projectId, applicationId) {
    const response = await axios.delete(`projects/${projectId}/applications/${applicationId}`);

    if (!response.data.ok) {
      throw response.data;
    } else {
      return true;
    }
  }
}

export default ApplicationsService;
