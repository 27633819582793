import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './DynamicField.module.scss';

const DynamicField = ({ title, data, setData }) => {
  const onChange = event => {
    const { value } = event.target;
    const fieldid = Number(event.target.getAttribute('fieldid'));
    const isKey = event.target.getAttribute('iskey');

    setData(currentData => {
      const item = currentData.find(element => {
        return element.id === fieldid;
      });

      if (isKey) {
        item.key = value;
      } else {
        item.value = value;
      }

      return [...currentData];
    });
  };

  const getLastId = () => {
    const lastItem = data[data.length - 1];
    return lastItem.id;
  };

  const addField = () => {
    const lastId = getLastId();
    setData(currentData => [...currentData, { id: lastId + 1, key: '', value: '' }]);
  };

  return (
    <div className={styles.DynamicFieldsContainer}>
      <h6 className={styles.DynamicFieldsTitle}>{title}</h6>
      <div className={styles.DynamicFieldsWrapper}>
        {data.map(field => (
          <React.Fragment key={field.id}>
            <div className={styles.DynamicField}>
              <input
                className={styles.Input}
                type="text"
                placeholder="Key"
                value={field.key}
                fieldid={field.id}
                iskey="true"
                onChange={onChange}
              />
              <input
                className={styles.Input}
                type="text"
                placeholder="Value"
                value={field.value}
                fieldid={field.id}
                onChange={onChange}
              />
            </div>
            <div className={styles.DynamicFieldSeparator}></div>
          </React.Fragment>
        ))}
        <div className={styles.AddField} onClick={addField} onKeyDown={addField}>
          <i className="fa fa-plus" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

DynamicField.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

export default DynamicField;
