import React from 'react';
import { PropTypes } from 'prop-types';

import { alphabet } from '../../../../../enums';

import styles from './ScenariosTitle.module.scss';

const ScenariosTitle = ({ count }) => {
  return (
    <div className={styles.Field}>
      <div className={styles.LabelContainer}>
        <span className={styles.Label}>Scenarios</span>
      </div>
      {Array(count)
        .fill()
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={`${styles.ValueContainer}`}>
            <span className={styles.Value}>Scenario {alphabet[index]}</span>
            {index === 0 && <span className={`${styles.BaselineLabel}`}>(baseline)</span>}
          </div>
        ))}
    </div>
  );
};

ScenariosTitle.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ScenariosTitle;
