import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import NavItemsList from './NavItemsList/NavItemsList';
import NavUserOptions from './NavUserOptions/NavUserOptions';

const Navbar = ({ user, signOut }) => (
  <>
    <NavItemsList />
    <NavUserOptions username={user.username} signOut={signOut} />
  </>
);

Navbar.propTypes = {
  signOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default Navbar;
