import React from 'react';
import { PropTypes } from 'prop-types';

import { metrics } from '../../../../../../enums';
import fields from '../fields';

import styles from './ScalabilityModelTooltip.module.scss';

const ScalabilityModelTooltip = ({ label: virtualUsers, payload }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.HeaderContainer}>
        <div className={styles.Title}>{metrics.THREADS.label}</div>
        <div className={styles.Value}>{virtualUsers}</div>
      </div>
      {payload.length > 0 &&
        payload.map(({ name, value }) => {
          const { formatter, color } = fields.find(field => field.name === name);
          return (
            <div key={name} className={styles.ContentContainer}>
              <div className={styles.Title} style={{ color }}>
                {name}
              </div>
              <div className={styles.Value}>{formatter(value)}</div>
            </div>
          );
        })}
    </div>
  );
};

ScalabilityModelTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
};

export default ScalabilityModelTooltip;
