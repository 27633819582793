import axios from 'axios';
import { mergeWith } from 'lodash';

import {
  mergeScalabilityData,
  mergeStatisticsData,
  processScenarioScalabilityData,
  processScenarioStatisticsData,
} from '../utils';

class TestsService {
  static async getTestsByTransactionId(projectId, applicationId, transactionId) {
    const response = await axios.get(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests`
    );

    return response.data.tests;
  }

  static async createTest(zipFile, testInfo) {
    const formData = new FormData();
    formData.append('file', zipFile);
    formData.append('testInfo', JSON.stringify(testInfo));

    const response = await axios.post(`tests/jmeter/zipped`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }

  static async deleteTest(projectId, applicationId, transactionId, testId) {
    const response = await axios.delete(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/${testId}`
    );

    if (!response.data.ok) {
      throw response.data;
    } else {
      return true;
    }
  }

  static async getAllScenarios(projectId, applicationId, transactionId) {
    const response = await axios.get(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/scenarios`
    );

    return response.data.scenarios;
  }

  static async findOrCreateFavoriteScenario(projectId, applicationId, transactionId, scenario, name, description) {
    const response = await axios.post(
      `/projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/scenarios`,
      { scenario: scenario.values, values: scenario.values, fields: scenario.fields, name, description }
    );

    return response.data;
  }

  static async deleteFavoriteScenario(projectId, applicationId, transactionId, scenarioId) {
    const response = await axios.delete(
      `/projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/scenarios/${scenarioId}`
    );

    return response.data;
  }

  static async groupTests(projectId, applicationId, transactionId, fields, dateRange) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/group`,
      { fields, dateRange }
    );

    return response.data.tests;
  }

  static async deleteGroupTests(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/delete-group`,
      { scenario }
    );
    return response.data.data;
  }

  static async getScalabilityData(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/scalability`,
      { scenario }
    );

    return response.data.data;
  }

  static async getEndpointsResponseTimeData(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/endpoints-summary`,
      { scenario }
    );

    return response.data.data;
  }

  static async getEndpointsStatistics(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/endpoints-statistics`,
      { scenario }
    );
    return response.data.data;
  }

  static async getScenarioInfo(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/scenario-info`,
      { scenario }
    );

    return response.data.info;
  }

  static async getScenariosInfo(projectId, applicationId, transactionId, scenarios) {
    const fullData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const value of scenarios.values) {
      const scenario = {
        fields: scenarios.fields,
        values: value,
        dateRange: scenarios.dateRange,
      };
      // eslint-disable-next-line no-await-in-loop
      const scenarioData = await TestsService.getScenarioInfo(projectId, applicationId, transactionId, scenario);

      fullData.push(scenarioData);
    }

    return fullData;
  }

  static async getScenariosScalabilityData(projectId, applicationId, transactionId, scenarios) {
    let fullData = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [index, value] of scenarios.values.entries()) {
      const scenario = {
        fields: scenarios.fields,
        values: value,
        dateRange: scenarios.dateRange,
      };
      // eslint-disable-next-line no-await-in-loop
      let scenarioData = await TestsService.getScalabilityData(projectId, applicationId, transactionId, scenario);

      scenarioData = processScenarioScalabilityData(scenarioData, index);
      fullData = mergeWith(fullData, scenarioData, mergeScalabilityData);
    }

    return fullData;
  }

  static async getScenariosStatistics(projectId, applicationId, transactionId, scenarios) {
    const fullData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [index, value] of scenarios.values.entries()) {
      const scenario = {
        fields: scenarios.fields,
        values: value,
        dateRange: scenarios.dateRange,
      };
      // eslint-disable-next-line no-await-in-loop
      let scenarioData = await TestsService.getScalabilityData(projectId, applicationId, transactionId, scenario);

      scenarioData = processScenarioStatisticsData(scenarioData, index);
      mergeStatisticsData(fullData, scenarioData);
    }

    return fullData;
  }

  static async getEndpointDetailedResponseCode(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/endpoint-detailed/response-code`,
      { scenario }
    );

    return response.data.data;
  }

  static async getEndpointDetailedScalabilityData(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/endpoint-detailed/scalability`,
      { scenario }
    );

    return response.data.data;
  }

  static async getEndpointDetailedResponseCodeDetailed(projectId, applicationId, transactionId, scenario) {
    const response = await axios.post(
      `projects/${projectId}/applications/${applicationId}/transactions/${transactionId}/tests/endpoint-detailed/detailed-response-code`,
      { scenario }
    );

    return response.data.data;
  }
}

export default TestsService;
