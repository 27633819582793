import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Button, Modal } from '../../../../shared/components';

import styles from './CreateFavoriteScenariosModal.module.scss';

const CreateFavoriteScenariosModal = ({ isOpen, favoriteScenario, onCloseModal, handlerFavoriteScenarioModal }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const handlerName = nameFavoriteScenario => {
    setName(nameFavoriteScenario);
  };
  const handlerDescription = descriptionFavoriteScenario => {
    setDescription(descriptionFavoriteScenario);
  };

  useEffect(() => {
    if (!isOpen) {
      setName('');
      setDescription('');
    }
  }, [isOpen]);

  const onFormSubmit = event => {
    event.preventDefault();
    handlerFavoriteScenarioModal(favoriteScenario, name, description, true);
  };

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} contentLabel="Modal">
      <h3 className={styles.ModalTitle}>Create Favorite Scenario</h3>
      <p>Provide a name and description:</p>
      <form onSubmit={onFormSubmit}>
        <input
          className="form-control mb-2"
          placeholder="Name"
          onChange={e => handlerName(e.target.value)}
          value={name}
          required
        ></input>

        <textarea
          className={`form-control mb-2 ${styles.TextArea}`}
          placeholder="Description"
          onChange={e => handlerDescription(e.target.value)}
          value={description}
          required
        ></textarea>
        <Button className={styles.CreateButton} text="Create" submit />
      </form>
    </Modal>
  );
};

CreateFavoriteScenariosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  favoriteScenario: PropTypes.object.isRequired,
  handlerFavoriteScenarioModal: PropTypes.func.isRequired,
};

export default CreateFavoriteScenariosModal;
