import React from 'react';
import { connect } from 'react-redux';

import { BreadcrumbSpinner } from '../../../shared/components';

const ProjectBreadcrumb = ({ project }) => {
  if (!project.name) {
    return <BreadcrumbSpinner />;
  }

  return project.name;
};

const mapStateToProps = state => ({
  project: state.projects.currentProject,
});

export default connect(mapStateToProps)(ProjectBreadcrumb);
