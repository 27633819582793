import { metrics } from '../../../../../enums';
import { parseIfNumber } from '../../../../shared/utils/utils';

const fields = [
  {
    name: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label,
    dataKey: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor,
    unit: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.unit,
    formatter: metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter,
    orientation: 'left',
    tickFormatter: value => parseIfNumber(value),
    color: '#568A49',
    type: 'monotone',
    strokeWidth: 2,
    width: 90,
  },
  {
    name: metrics.TOTAL_SUCCESSFUL_TPS.label,
    dataKey: metrics.TOTAL_SUCCESSFUL_TPS.accessor,
    unit: metrics.TOTAL_SUCCESSFUL_TPS.unit,
    formatter: metrics.TOTAL_SUCCESSFUL_TPS.formatter,
    orientation: 'right',
    tickFormatter: value => parseIfNumber(value),
    color: '#447EBC',
    type: 'monotone',
    strokeWidth: 2,
    width: 90,
  },
  {
    name: metrics.FAILED_REQUESTS_PERCENTAGE.label,
    dataKey: metrics.FAILED_REQUESTS_PERCENTAGE.accessor,
    unit: metrics.FAILED_REQUESTS_PERCENTAGE.unit,
    formatter: metrics.FAILED_REQUESTS_PERCENTAGE.formatter,
    orientation: 'right',
    tickFormatter: value => parseIfNumber(value),
    color: '#9A0000',
    type: 'monotone',
    strokeWidth: 2,
    domain: [0, 100],
  },
];

export default fields;
