const defaultScenarios = [
  {
    id: 1,
    label: 'By Tag',
    fields: ['scn_tag'],
  },
  {
    id: 2,
    label: 'By Version',
    fields: ['scn_version'],
  },
  {
    id: 3,
    label: 'By Build Number and Job Name',
    fields: ['scn_job_name', 'scn_build_number'],
  },
];

export default defaultScenarios;
