const appColors = {
  primary: '#9E9E9E',
  secondary: '#E8E8E8',
  green: '#00BD51',
  blue: '#009CEE',
  gold: '#B79967',
  red: '#E61717',
};

export default appColors;
