import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { endOfDay, format, startOfDay } from 'date-fns';
import { PropTypes } from 'prop-types';

import { appColors } from '../../../../enums';

import { inputRanges, staticRanges } from './ranges';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './DatePicker.module.scss';

const DatePicker = ({ dateSelection, onSelectionChange }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef();
  const datePickerButtonRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isDatePickerOpen &&
        !datePickerRef.current.contains(event.target) &&
        !datePickerButtonRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    if (isDatePickerOpen) document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDatePickerOpen]);

  const formatDateDisplay = (date, defaultText) => {
    if (!date) return defaultText;
    return format(date, 'MMM d, yyyy');
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(current => !current);
  };

  const fixRangeDayOffset = range => {
    const { startDate } = range.selection;
    const { endDate } = range.selection;
    range.selection.startDate = startOfDay(startDate);
    range.selection.endDate = endOfDay(endDate);
  };

  const handleSelect = range => {
    fixRangeDayOffset(range);
    onSelectionChange(range.selection);
  };

  return (
    <>
      <div className={styles.Container}>
        <div
          className={styles.HeaderContainer}
          ref={datePickerButtonRef}
          onClick={toggleDatePicker}
          onKeyDown={toggleDatePicker}
        >
          <div className={`${styles.DateRange}`}>
            <span>{formatDateDisplay(dateSelection.startDate, ' - ')}</span>
            <i className={`${styles.RightIcon} fa fa-long-arrow-right`} aria-hidden="true"></i>
            <span>{formatDateDisplay(dateSelection.endDate, ' - ')}</span>
          </div>
          <div className={styles.DateButton}>
            <i className={`${styles.CalendarIcon} fa fa-calendar-o`} aria-hidden="true"></i>
          </div>
        </div>
        <div
          ref={datePickerRef}
          className={`${styles.DateRangePickerContainer} ${isDatePickerOpen ? styles.DatePickerOpened : ''}`}
        >
          <DateRangePicker
            rangeColors={[appColors.primary]}
            ranges={[dateSelection]}
            staticRanges={staticRanges}
            inputRanges={inputRanges}
            onChange={handleSelect}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            direction="horizontal"
          />
        </div>
      </div>
    </>
  );
};

DatePicker.propTypes = {
  dateSelection: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};

export default DatePicker;
