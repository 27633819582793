/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { PropTypes } from 'prop-types';

import * as sessionActions from '../../../user/store/sessionActions';

import 'react-table/react-table.css';
import './Table.scss';

const Table = ({
  id,
  tables,
  updateTableState,
  data,
  columns,
  defaultSorted,
  defaultPageSize,
  storeFilters,
  className,
  hoverableRows,
  ...rest
}) => {
  const state = tables[id] || {};

  const handleSortedChange = sorted => {
    if (storeFilters) {
      updateTableState(id, {
        ...state,
        sorted,
      });
    }
  };

  const handleFilteredChange = filtered => {
    if (storeFilters) {
      updateTableState(id, {
        ...state,
        filtered,
      });
    }
  };

  const handlePageSizeChange = pageSize => {
    updateTableState(id, {
      ...state,
      pageSize,
    });
  };

  return (
    <ReactTable
      className={`${hoverableRows ? 'HoverableRows' : ''} ${className}`}
      data={data}
      columns={columns}
      onFilteredChange={handleFilteredChange}
      onSortedChange={handleSortedChange}
      onPageSizeChange={handlePageSizeChange}
      defaultPageSize={state.pageSize || defaultPageSize}
      defaultSorted={state.sorted || defaultSorted}
      defaultFiltered={state.filtered}
      {...rest}
    />
  );
};

Table.defaultProps = {
  defaultSorted: [],
  defaultPageSize: 5,
  minRows: 2,
  showPagination: true,
  storeFilters: true,
  className: '',
  hoverableRows: false,
  data: [],
};

Table.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  tables: PropTypes.object.isRequired,
  updateTableState: PropTypes.func.isRequired,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  showPagination: PropTypes.bool,
  storeFilters: PropTypes.bool,
  hoverableRows: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  tables: state.session.tables,
});

const mapDispatchToProps = dispatch => ({
  updateTableState: (tableId, newState) => dispatch(sessionActions.updateTableState(tableId, newState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
