import axios from 'axios';

class TokenService {
  static async hasExpiredToken(accessToken) {
    const response = await axios.get(`verifyToken/${accessToken}`);
    return response.data.expired;
  }

  static async getUserByToken(accessToken) {
    const response = await axios.get('myself');

    const { user } = response.data;

    return {
      ...user,
      token: accessToken,
    };
  }
}

export default TokenService;
