import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { filter, find, isEqual } from 'lodash';
import { PropTypes } from 'prop-types';
import rison from 'rison-node';

import Roles from '../../../../containers/auth/Roles';
import { roles, tables } from '../../../../enums';
import {
  Button,
  CheckBox,
  DatePicker,
  FavoriteButton,
  Spinner,
  Tab,
  Table,
  TableButton,
  Tabs,
} from '../../../shared/components';
import { alerts } from '../../../shared/utils';
import { formatDateToLocale, genericTableFilterMethod, parseIfNumber } from '../../../shared/utils/utils';
import * as sessionActions from '../../../user/store/sessionActions';
import setExplorerState from '../../store/explorerActions';
import { TestsService } from '../Tests';
import { fetchFavoriteScenarios, fetchTests } from '../Tests/store/testsActions';

import CreateFavoriteScenariosModal from './CreateFavoriteScenariosModal/CreateFavoriteScenariosModal';
import CreateTestModal from './CreateTestModal/CreateTestModal';
import FavoriteScenarios from './FavoriteScenarios/FavoriteScenarios';
import defaultScenarios from './ScenarioBuilder/defaultScenarios';
import ScenarioBuilder from './ScenarioBuilder/ScenarioBuilder';

import styles from './Transaction.module.scss';

const Transaction = ({
  initExplorerState,
  getTestsAction,
  getFavoriteScenariosAction,
  tests,
  testFields,
  isFetchingTests,
  favoriteScenarios,
  history,
  selectedDateRange,
  updateDateRange,
}) => {
  const sessionSavedScenario =
    localStorage.getItem('selectedScenario') && JSON.parse(localStorage.getItem('selectedScenario'));

  const maxScenariosToCompare = 2;

  const [currentScenario, setCurrentScenario] = useState(sessionSavedScenario || defaultScenarios[0]);
  const [newTestModal, setNewTestModal] = useState(false);
  const [createFavoriteScenarioModal, setCreateFavoriteScenarioModal] = useState(false);
  const [createFavoriteScenario, setCreateFavoriteScenario] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [scenariosToCompare, setScenariosToCompare] = useState([]);
  const [dateSelection, setDateSelection] = useState(
    selectedDateRange || {
      startDate: startOfMonth(subMonths(startOfDay(new Date()), 2)),
      endDate: endOfMonth(endOfDay(new Date())),
      key: 'selection',
    }
  );
  const urlParams = useParams();
  const { projectId, applicationId, transactionId } = urlParams;

  const changeDateRange = range => {
    updateDateRange(range);
    setDateSelection(range);
  };

  const getTests = useCallback(() => {
    getTestsAction(projectId, applicationId, transactionId, currentScenario, dateSelection);
  }, [applicationId, currentScenario, dateSelection, getTestsAction, projectId, transactionId]);

  const getFavoriteScenarios = useCallback(() => {
    getFavoriteScenariosAction(projectId, applicationId, transactionId);
  }, [applicationId, getFavoriteScenariosAction, projectId, transactionId]);

  const openCreateFavoriteScenarioModal = scenario => {
    setCreateFavoriteScenario(scenario);
    setCreateFavoriteScenarioModal(true);
  };

  const onCreateFavoriteScenarioClose = () => {
    setCreateFavoriteScenario({});
    setCreateFavoriteScenarioModal(false);
  };

  const onSuccessCreateFavoriteScenario = name => {
    setCreateFavoriteScenario({});
    setCreateFavoriteScenarioModal(false);
    alerts.success(alerts.empathizeVars`Scenario ${name} now is one of your favorites.`);
  };

  const onErrorCreateFavoriteScenario = errorMessage => {
    alerts.error(errorMessage);
    setCreateFavoriteScenario({});
    setCreateFavoriteScenarioModal(false);
  };

  const handleTestsDeletion = useCallback(
    selectedTests => {
      const deleteGroupTests = async testsToDelete => {
        try {
          const scenario = {
            fields: currentScenario.fields,
            values: testsToDelete,
            dateRange: dateSelection,
          };
          await TestsService.deleteGroupTests(projectId, applicationId, transactionId, scenario);
          alerts.success('Tests were deleted.');
        } catch (err) {
          alerts.error('Tests deletion failed.');
        }
        getTests();
      };

      alerts.deleteWarningDangerZone({
        entity: 'these tests',
        message: 'This action deletes all the tests of this scenario in MongoDB and Elasticsearch.',
        onConfirm: () => {
          deleteGroupTests(selectedTests);
        },
      });
    },
    [applicationId, currentScenario.fields, dateSelection, getTests, projectId, transactionId]
  );

  const handleFavoriteScenarioDeletion = useCallback(
    selectedFavoriteScenario => {
      const deleteFavoriteScenario = async favoriteScenarioToDelete => {
        try {
          await TestsService.deleteFavoriteScenario(
            projectId,
            applicationId,
            transactionId,
            favoriteScenarioToDelete._id
          );
          alerts.success(`Favorite Scenario deleted.`);
        } catch (err) {
          alerts.error('Favorite Scenario deletion failed.');
        }
        getFavoriteScenarios();
      };

      alerts.deleteWarning({
        entity: 'favorite scenario',
        subjectToDelete: selectedFavoriteScenario.name,
        message:
          'This action will not modify anything related to the tests data, ' +
          'only remove the selected scenario from the favorites.',
        onConfirm: () => {
          deleteFavoriteScenario(selectedFavoriteScenario);
        },
      });
    },
    [applicationId, getFavoriteScenarios, projectId, transactionId]
  );

  const handlerFindOrCreateFavoriteScenario = useCallback(
    (testsScenario, name, description, isCreating = false) => {
      const findOrCreate = async () => {
        try {
          if (Object.values(testsScenario).includes(undefined)) {
            onErrorCreateFavoriteScenario('Some scenario field does not have any value.');
          } else {
            await TestsService.findOrCreateFavoriteScenario(
              projectId,
              applicationId,
              transactionId,
              testsScenario,
              name,
              description
            );

            onSuccessCreateFavoriteScenario(name);
            getFavoriteScenarios();
          }
        } catch (error) {
          if (error.code === 409) {
            alerts.warning(error.message);
          } else {
            onErrorCreateFavoriteScenario('An error ocurred during the creation of favorite scenario.');
          }
        }
      };
      if (isCreating) findOrCreate(testsScenario);
    },
    [applicationId, getFavoriteScenarios, projectId, transactionId]
  );

  const getLinkToTests = useCallback(
    (testsScenario, currentFields, dateRange = undefined) => {
      const scenario = {
        fields: currentFields,
        values: testsScenario,
        dateRange: dateRange || dateSelection,
      };

      const encodedScenario = rison.encode(scenario);
      return `${history.location.pathname}/tests?scenario=${encodeURIComponent(encodedScenario)}`;
    },
    [dateSelection, history.location.pathname]
  );

  const getLinkToCompare = useCallback(() => {
    const scenarios = {
      fields: currentScenario.fields,
      values: scenariosToCompare,
      dateRange: dateSelection,
    };
    const encodedScenarios = rison.encode(scenarios);
    return `${history.location.pathname}/compare?scenarios=${encodeURIComponent(encodedScenarios)}`;
  }, [currentScenario.fields, dateSelection, history.location.pathname, scenariosToCompare]);

  const buildColumns = useCallback(() => {
    const isScenarioSelectedToCompare = scenario => !!find(scenariosToCompare, scenario);

    const handleSelectScenarioToCompare = scenario => {
      if (isScenarioSelectedToCompare(scenario)) {
        setScenariosToCompare(currentScenariosToCompare =>
          filter(currentScenariosToCompare, o => !isEqual(o, scenario))
        );
      } else if (scenariosToCompare.length < maxScenariosToCompare) {
        setScenariosToCompare(currentScenariosToCompare => [...currentScenariosToCompare, scenario]);
      }
    };

    const newColumns = [];

    newColumns.push({
      Header: 'Compare',
      headerClassName: 'disabled-filter',
      filterable: false,
      className: styles.ActionCell,
      width: 80,
      resizable: false,
      sortable: false,
      Cell: scenario => (
        <div
          className={`${styles.CompareCheckBoxContainer} ${
            scenariosToCompare.length >= maxScenariosToCompare && !isScenarioSelectedToCompare(scenario.original)
              ? styles.CompareCheckBoxDisabled
              : ''
          }`}
          onClick={e => {
            e.stopPropagation();
            handleSelectScenarioToCompare(scenario.original);
          }}
          onKeyDown={() => handleSelectScenarioToCompare(scenario.original)}
        >
          <CheckBox checked={isScenarioSelectedToCompare(scenario.original)} />
        </div>
      ),
    });

    currentScenario.fields.forEach(field => {
      const foundField = testFields.find(f => f.field_name === field);
      if (foundField) {
        const fieldLabel = foundField.label;
        newColumns.push({
          Header: <span title={parseIfNumber(fieldLabel)}>{parseIfNumber(fieldLabel)}</span>,
          headerClassName: 'justify-content-start',
          accessor: field,
          Cell: cell => <span title={parseIfNumber(cell.value)}>{parseIfNumber(cell.value)}</span>,
          filterMethod: genericTableFilterMethod(field),
        });
      }
    });

    newColumns.push(
      {
        Header: 'Tests',
        id: 'tests',
        width: 60,
        resizable: false,
        headerClassName: 'disabled-filter',
        filterable: false,
        style: { textAlign: 'center' },
        Cell: cell => parseIfNumber(cell.value),
        accessor: 'count',
      },
      {
        Header: 'Last Test',
        id: 'last_start_date',
        width: 110,
        headerClassName: 'disabled-filter',
        resizable: false,
        accessor: 'last_start_date',
        filterable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const lastTest = formatDateToLocale(new Date(value)).split(' - ');
          return (
            <div className={styles.LastDateCell}>
              <div className={styles.LastDateDate}> {lastTest[0]}</div>
              <div className={styles.LastDateHour}> {lastTest[1]}</div>
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        headerClassName: 'disabled-filter',
        className: 'table-action-cell',
        filterable: false,
        width: 150,
        resizable: false,
        sortable: false,
        Cell: testsScenario => (
          <>
            <TableButton
              isLink
              icon="eye"
              to={getLinkToTests(testsScenario.original, currentScenario.fields)}
              tooltip="Open scenario"
            />
            <FavoriteButton
              scenario={{ values: testsScenario.original, fields: currentScenario.fields }}
              openModal={openCreateFavoriteScenarioModal}
              handlerDeleteFavoriteScenario={handleFavoriteScenarioDeletion}
            />
            <Roles admit={[roles.DEVELOPER]}>
              <TableButton
                icon="trash"
                onClick={() => handleTestsDeletion(testsScenario.original)}
                tooltip="Delete tests"
              />
            </Roles>
          </>
        ),
      }
    );

    setColumns(newColumns);
  }, [
    currentScenario.fields,
    getLinkToTests,
    handleFavoriteScenarioDeletion,
    handleTestsDeletion,
    scenariosToCompare,
    testFields,
  ]);

  useEffect(() => {
    initExplorerState(urlParams);
  }, [initExplorerState, urlParams]);

  useEffect(() => {
    buildColumns();
  }, [buildColumns, tests]);

  useEffect(() => {
    if (testFields.length > 0) {
      setScenariosToCompare([]);
      getTests();
      setIsLoading(false);
    }
  }, [getTests, testFields.length]);

  const onTestCreation = () => {
    setNewTestModal(false);
    getTests();
  };

  const testTable = (
    <div>
      <Table
        id={tables.TESTS}
        data={tests}
        columns={columns}
        filterable
        defaultSorted={[
          {
            id: 'last_start_date',
            desc: true,
          },
        ]}
        hoverableRows
      />
      {scenariosToCompare.length > 0 && (
        <Button
          className={styles.compareButton}
          text="Compare"
          disabled={scenariosToCompare.length <= 1}
          to={getLinkToCompare()}
          isLink
        />
      )}
    </div>
  );

  return (
    <div>
      <div className={styles.Header}>
        <h4 className="page-title">Transactions</h4>
        <div className={styles.Actions}>
          <DatePicker dateSelection={dateSelection} onSelectionChange={changeDateRange} />
          <Roles admit={[roles.DEVELOPER]}>
            <div className={styles.UploadTestButton}>
              <Button text="Upload test" onClick={() => setNewTestModal(true)} />
            </div>
          </Roles>
        </div>
      </div>
      <Tabs>
        <Tab tabTitle="Scenario Builder">
          <div className={`${styles.BoxContainer}`} style={{ marginTop: 0 }}>
            <ScenarioBuilder currentScenario={currentScenario} setCurrentScenario={setCurrentScenario} />
            {isFetchingTests || isLoading ? <Spinner /> : testTable}
          </div>
        </Tab>
        <Tab tabTitle="Favorite Scenarios">
          <div className="table-favorite-scenarios">
            <FavoriteScenarios
              favoriteScenarios={favoriteScenarios}
              getLinkToTests={getLinkToTests}
              openModal={openCreateFavoriteScenarioModal}
              handlerDeleteFavoriteScenario={handleFavoriteScenarioDeletion}
            />
          </div>
        </Tab>
      </Tabs>
      <CreateTestModal
        isOpen={newTestModal}
        onCloseModal={() => setNewTestModal(false)}
        onTestCreation={onTestCreation}
      />
      <CreateFavoriteScenariosModal
        isOpen={createFavoriteScenarioModal}
        favoriteScenario={createFavoriteScenario}
        onCloseModal={onCreateFavoriteScenarioClose}
        handlerFavoriteScenarioModal={handlerFindOrCreateFavoriteScenario}
      />
    </div>
  );
};

Transaction.defaultProps = {
  selectedDateRange: undefined,
};

Transaction.propTypes = {
  initExplorerState: PropTypes.func.isRequired,
  getTestsAction: PropTypes.func.isRequired,
  getFavoriteScenariosAction: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  isFetchingTests: PropTypes.bool.isRequired,
  testFields: PropTypes.array.isRequired,
  favoriteScenarios: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  selectedDateRange: PropTypes.object,
  updateDateRange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  transaction: state.transactions.currentTransaction,
  tests: state.tests.tests,
  testFields: state.testFields.testFields,
  isFetchingTests: state.tests.isFetchingTests,
  favoriteScenarios: state.tests.favoriteScenarios,
  selectedDateRange: state.session.selectedDateRange,
});

const mapDispatchToProps = dispatch => ({
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
  getTestsAction: (projectId, applicationId, transactionId, currentScenario, dateRange) =>
    dispatch(fetchTests(projectId, applicationId, transactionId, currentScenario, dateRange)),
  getFavoriteScenariosAction: (projectId, applicationId, transactionId) =>
    dispatch(fetchFavoriteScenarios(projectId, applicationId, transactionId)),
  updateDateRange: date => dispatch(sessionActions.updateDateRange(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
