import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Roles from '../../../../containers/auth/Roles';
import { roles, tables } from '../../../../enums';
import { Button, Spinner, Table, TableButton } from '../../../shared/components';
import { alerts } from '../../../shared/utils';
import { formatDateToLocale, parseIfNumber } from '../../../shared/utils/utils';
import * as sessionActions from '../../../user/store/sessionActions';
import setExplorerState from '../../store/explorerActions';
import { TransactionsActions, TransactionsService } from '../Transaction';

const Application = ({
  application,
  transactions,
  isFetchingTransactions,
  getTransactions,
  initExplorerState,
  clearTableFilters,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const urlParams = useParams();
  const { projectId, applicationId } = urlParams;

  useEffect(() => {
    initExplorerState(urlParams);
    getTransactions(projectId, applicationId);
    setIsLoading(false);
  }, [applicationId, getTransactions, initExplorerState, projectId, urlParams]);

  const getTransactionUrl = transactionId => {
    return `/explorer/project/${projectId}/application/${application._id}/transaction/${transactionId}`;
  };

  const deleteTransaction = async transaction => {
    try {
      await TransactionsService.deleteTransactions(projectId, application._id, transaction._id);
      alerts.success(alerts.empathizeVars`Your transaction ${transaction.name} was deleted.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getTransactions(projectId, application._id);
  };

  const handleTransactionDeletion = selectedTransaction => {
    alerts.deleteWarningDangerZone({
      subjectToDelete: selectedTransaction.name,
      entity: 'transaction',
      message: 'This action deletes all the tests of this transaction in MongoDB and Elasticsearch.',
      onConfirm: () => {
        deleteTransaction(selectedTransaction);
      },
    });
  };

  const createNewTransaction = async name => {
    try {
      await TransactionsService.createTransaction(name, projectId, application._id);
      alerts.success(alerts.empathizeVars`Your transaction ${name} was created.`);
    } catch (err) {
      alerts.error(err.message);
    }
    getTransactions(projectId, application._id);
  };

  const onNewTransaction = async () => {
    const transactionName = await alerts.entityCreatorForm('transaction');
    if (transactionName) createNewTransaction(transactionName);
  };

  const columns = [
    {
      Header: 'Name',
      headerClassName: 'justify-content-start',
      accessor: 'name',
      filterable: true,
      id: 'name',
      Cell: transaction => (
        <span className="table-featured-cell" title={transaction.value}>
          {transaction.value}
        </span>
      ),
      filterMethod: (filter, transaction) => {
        const transactionName = transaction.name && transaction.name.toLowerCase();
        return transactionName.includes(filter.value.toLowerCase());
      },
    },
    {
      Header: 'Tests',
      accessor: 'tests_number',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      id: 'tests',
      Cell: row => {
        if (row.value) {
          return parseIfNumber(row.value);
        }
        return 0;
      },
    },
    {
      Header: 'Last update',
      headerClassName: 'disabled-filter justify-content-start',
      filterable: false,
      id: 'lastUpdate',
      accessor: 'updatedAt',
      Cell: ({ value }) => {
        const lastUpdate = new Date(value);
        return formatDateToLocale(lastUpdate);
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      Header: 'Actions',
      width: 250,
      resizable: false,
      headerClassName: 'disabled-filter',
      className: 'table-action-cell',
      sortable: false,
      Cell: transaction => (
        <>
          <TableButton isLink icon="eye" to={getTransactionUrl(transaction.original._id)} tooltip="Open transaction" />
          <Roles admit={[roles.DEVELOPER]}>
            <TableButton
              icon="trash"
              onClick={() => handleTransactionDeletion(transaction.original)}
              tooltip="Delete transaction"
            />
          </Roles>
        </>
      ),
    },
  ];

  const viewApplicationTable = (
    <Table
      id={tables.TRANSACTIONS}
      data={transactions === '' ? [] : transactions}
      columns={columns}
      defaultSorted={[
        {
          id: 'lastUpdate',
          desc: true,
        },
      ]}
      hoverableRows
    />
  );

  return (
    <div>
      <div className="page-header">
        <h4 className="page-title">Transactions</h4>
        <div className="header-actions">
          <Button text="Clear filters" type="secondary" onClick={() => clearTableFilters(tables.TRANSACTIONS)} />
          <Roles admit={[roles.DEVELOPER]}>
            <Button text="New transaction" icon="plus" onClick={onNewTransaction} />
          </Roles>
        </div>
      </div>
      <div className="box-container">{isFetchingTransactions || isLoading ? <Spinner /> : viewApplicationTable}</div>
    </div>
  );
};

Application.propTypes = {
  application: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
  isFetchingTransactions: PropTypes.bool.isRequired,
  getTransactions: PropTypes.func.isRequired,
  initExplorerState: PropTypes.func.isRequired,
  clearTableFilters: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  transactions: state.transactions.transactions,
  application: state.applications.currentApplication,
  isFetchingTransactions: state.transactions.isFetchingTransactions,
});

const mapDispatchToProps = dispatch => ({
  getTransactions: (projectId, applicationId) =>
    dispatch(TransactionsActions.fetchTransactions(projectId, applicationId)),
  initExplorerState: urlParams => dispatch(setExplorerState(urlParams)),
  clearTableFilters: tableId => dispatch(sessionActions.clearTableFilters(tableId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
