import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { PropTypes } from 'prop-types';

import { TableButton } from '..';

const FavoriteButton = ({ scenario, openModal, favoriteScenarios, handlerDeleteFavoriteScenario }) => {
  const [existingFavoriteScenario, setExistingFavoriteScenario] = useState();

  const handlerFindOrCreateFavorite = e => {
    e.stopPropagation();

    if (!existingFavoriteScenario) {
      openModal(scenario);
    } else {
      handlerDeleteFavoriteScenario(existingFavoriteScenario);
    }
  };

  const areScenariosValuesEqual = (fields, scenarioValues1, scenarioValues2) => {
    return fields.map(field => get(scenarioValues1, field) === get(scenarioValues2, field)).every(Boolean);
  };

  useEffect(() => {
    const favoriteScenario = favoriteScenarios.find(element => {
      return (
        isEqual(scenario.fields, element.fields) &&
        areScenariosValuesEqual(scenario.fields, element.values, scenario.values)
      );
    });

    setExistingFavoriteScenario(favoriteScenario);
  }, [favoriteScenarios, scenario.fields, scenario.values]);

  return (
    <TableButton
      icon={existingFavoriteScenario ? 'star' : 'star-o'}
      onClick={handlerFindOrCreateFavorite}
      tooltip={existingFavoriteScenario ? 'Remove from favorites' : 'Add to favorites'}
    />
  );
};

FavoriteButton.defaultProps = {
  openModal: () => {},
  scenario: {},
  handlerDeleteFavoriteScenario: () => {},
  favoriteScenarios: [],
};

FavoriteButton.propTypes = {
  openModal: PropTypes.func,
  scenario: PropTypes.object,
  handlerDeleteFavoriteScenario: PropTypes.func,
  favoriteScenarios: PropTypes.array,
};

const mapStateToProps = state => ({
  favoriteScenarios: state.tests.favoriteScenarios,
});

export default connect(mapStateToProps)(FavoriteButton);
