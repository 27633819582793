import { applicationsActions } from './applicationsActions';

const initialState = {
  applications: [],
  currentApplication: {},
  isFetchingApplications: false,
  fetchApplicationsError: null,
};

const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case applicationsActions.START_FETCH_APPLICATIONS:
      return { ...state, isFetchingApplications: true };
    case applicationsActions.SUCCESS_FETCH_APPLICATIONS:
      return { ...state, applications: action.payload, isFetchingApplications: false };
    case applicationsActions.ERROR_FETCH_APPLICATIONS:
      return { ...state, fetchApplicationsError: action.payload, isFetchingApplications: false };
    case applicationsActions.CLEAR_APPLICATIONS:
      return { ...state, applications: [] };
    case applicationsActions.SET_CURRENT_APPLICATION:
      return {
        ...state,
        currentApplication: action.payload,
      };
    default:
      return state;
  }
};

export default applicationsReducer;
