import { transactionsActions } from './transactionsActions';

const initialState = {
  transactions: [],
  currentTransaction: {},
  isFetchingTransactions: false,
  fetchTransactionsError: null,
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionsActions.START_FETCH_TRANSACTIONS:
      return { ...state, isFetchingTransactions: true };
    case transactionsActions.SUCCESS_FETCH_TRANSACTIONS:
      return { ...state, transactions: action.payload, isFetchingTransactions: false };
    case transactionsActions.ERROR_FETCH_TRANSACTIONS:
      return { ...state, fetchTransactionsError: action.payload, isFetchingTransactions: false };
    case transactionsActions.CLEAR_TRANSACTIONS:
      return { ...state, transactions: [] };
    case transactionsActions.SET_CURRENT_TRANSACTION:
      return { ...state, currentTransaction: action.payload };
    default:
      return state;
  }
};

export default transactionsReducer;
