import React from 'react';
import { isEmpty, orderBy } from 'lodash';
import { PropTypes } from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { alphabet, colors, metrics as metricsLabels } from '../../../../../enums';
import { NoData, Spinner } from '../../../../shared/components';
import { parseIfNumber } from '../../../../shared/utils/utils';

import CompareScalabilityModelTooltip from './CompareScalabilityModelTooltip/CompareScalabilityModelTooltip';

import styles from './CompareScalabilityModel.module.scss';

const metrics = [
  {
    name: metricsLabels.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label,
    fieldName: metricsLabels.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor,
    unit: metricsLabels.AVERAGE_SUCCESSFUL_RESPONSE_TIME.unit,
    formatter: metricsLabels.AVERAGE_SUCCESSFUL_RESPONSE_TIME.formatter,
    increaseIsPositive: false,
  },
  {
    name: metricsLabels.TOTAL_SUCCESSFUL_TPS.label,
    fieldName: metricsLabels.TOTAL_SUCCESSFUL_TPS.accessor,
    unit: metricsLabels.TOTAL_SUCCESSFUL_TPS.unit,
    formatter: metricsLabels.TOTAL_SUCCESSFUL_TPS.formatter,
    increaseIsPositive: true,
  },
  {
    name: metricsLabels.FAILED_REQUESTS_PERCENTAGE.label,
    fieldName: metricsLabels.FAILED_REQUESTS_PERCENTAGE.accessor,
    unit: metricsLabels.FAILED_REQUESTS_PERCENTAGE.unit,
    formatter: metricsLabels.FAILED_REQUESTS_PERCENTAGE.formatter,
    increaseIsPositive: false,
  },
];

const CompareScalabilityModel = ({ scenarios, data, isDataLoading }) => {
  const bars = Array(scenarios.values.length)
    .fill()
    // eslint-disable-next-line react/no-array-index-key
    .map((_, index) => <Bar key={index} dataKey={`Scenario ${alphabet[index]}`} fill={colors[index]} />);

  const visualization = isEmpty(data) ? (
    <div className={styles.PlaceholderWrapper}>
      <NoData />
    </div>
  ) : (
    <>
      <h4 className="page-subtitle mb-4">Scalability Model</h4>
      <div className={styles.ScalabilityModelWrapper}>
        {metrics.map(({ name, fieldName, unit, formatter, increaseIsPositive }) => (
          <div className={styles.boxWrapper} key={name}>
            <h5 className={styles.boxTitle}>{name}</h5>
            <div className={styles.box}>
              <ResponsiveContainer>
                <BarChart
                  data={orderBy(data[fieldName], [metricsLabels.THREADS.accessor], ['asc'])}
                  margin={{ top: 10, right: 10, left: 35, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey={metricsLabels.THREADS.accessor}
                    label={metricsLabels.THREADS.label}
                    tickFormatter={value => parseIfNumber(value)}
                  />
                  <YAxis tickFormatter={value => parseIfNumber(value)} unit={unit} />
                  <Tooltip
                    content={CompareScalabilityModelTooltip}
                    formatter={formatter}
                    increaseIsPositive={increaseIsPositive}
                  />
                  <Legend />
                  {bars}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <>
      <div>
        {isDataLoading ? (
          <div className={styles.PlaceholderWrapper}>
            <Spinner />
          </div>
        ) : (
          visualization
        )}
      </div>
    </>
  );
};

CompareScalabilityModel.propTypes = {
  scenarios: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
};

export default CompareScalabilityModel;
