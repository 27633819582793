import React, { useEffect, useState } from 'react';
import { DropdownList } from 'react-widgets';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';

import { metrics } from '../../../../../enums';
import { NoData, Spinner } from '../../../../shared/components';
import { calculatePercentageChange } from '../../../../shared/utils/utils';

import MetricCard from './MetricCard/MetricCard';

import styles from './CompareMetrics.module.scss';

const CompareMetrics = ({ data, isDataLoading }) => {
  const [selectedThreads, setSelectedThreads] = useState('');
  const [threadsGroups, setThreadGroups] = useState([]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const firstKey = Object.keys(data)[0];
      let groups = data[firstKey].map(element => element.threads);
      groups = groups.sort((a, b) => a - b);
      setThreadGroups(groups);
      setSelectedThreads(groups[0]);
    }
  }, [data]);

  const onThreadGroupChange = threadGroup => {
    setSelectedThreads(threadGroup);
  };

  const getValuesToCompare = accessor => {
    if (isEmpty(data)) return undefined;
    let fieldData = data[accessor].find(element => element.threads === selectedThreads);

    if (fieldData) {
      fieldData = { ...fieldData };

      delete fieldData.threads;
      if (Object.keys(fieldData).length > 1) {
        const values = Object.values(fieldData);
        return values;
      }
    }

    return undefined;
  };

  const calculateChangePercentage = accessor => {
    const values = getValuesToCompare(accessor);
    if (!values) return undefined;

    const [baseline, value] = values;

    return calculatePercentageChange(baseline, value);
  };

  const content = isEmpty(data) ? (
    <div className={styles.PlaceholderWrapper}>
      <NoData />
    </div>
  ) : (
    <>
      <div className={styles.SelectorContainer}>
        <h4 className="page-subtitle mb-4">Overview</h4>
        <h5 className="title-form">Virtual Users</h5>
        <DropdownList
          className={`${styles.VirtualUsersDropdown} mb-4 whiteDropdown`}
          data={threadsGroups}
          value={selectedThreads}
          textField="virtualUsers"
          onChange={onThreadGroupChange}
          globalKeyEvents
        />
      </div>
      <div className={styles.CardsWrapper}>
        <MetricCard
          title={metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.label}
          value={calculateChangePercentage(metrics.AVERAGE_SUCCESSFUL_RESPONSE_TIME.accessor)}
          increaseIsPositive={false}
        />
        <MetricCard
          title={metrics.TOTAL_SUCCESSFUL_TPS.label}
          value={calculateChangePercentage(metrics.TOTAL_SUCCESSFUL_TPS.accessor)}
          increaseIsPositive
        />
        <MetricCard
          title={metrics.FAILED_REQUESTS_PERCENTAGE.label}
          value={calculateChangePercentage(metrics.FAILED_REQUESTS_PERCENTAGE.accessor)}
          increaseIsPositive={false}
        />
      </div>
    </>
  );

  return (
    <>
      <div>
        {isDataLoading ? (
          <div className={styles.PlaceholderWrapper}>
            <Spinner />
          </div>
        ) : (
          content
        )}
      </div>
    </>
  );
};

CompareMetrics.propTypes = {
  data: PropTypes.object.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
};

export default CompareMetrics;
