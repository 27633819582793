import { ApplicationsActions } from '../../Explorer/modules/Application';
import { ProjectsActions } from '../../Explorer/modules/Project';
import { TestsActions } from '../../Explorer/modules/Tests';
import { TransactionsActions } from '../../Explorer/modules/Transaction';
import LoginService from '../services/loginService';
import UserService from '../services/userService';

export const sessionActions = {
  START_LOGIN: '[SESSION] Start Login',
  SUCCESS_LOGIN: '[SESSION] Success login',
  ERROR_LOGIN: '[SESSION] Error login',
  SET_CURRENT_USER: '[SESSION] Set current user',
  LOGOUT: '[SESSION] Logout current user',
  SET_REDIRECT_PATH: '[SESSION] Set redirect path',
  CLEAN_ERROR_LOGIN: '[SESSION] Clean error login',
  UPDATE_DATE_RAGE: '[SESSION] Update date range',
  UPDATE_TABLES_STATE: '[SESSION] Update table state',
};

export function startLogin() {
  return {
    type: sessionActions.START_LOGIN,
  };
}

export function successLogin(user) {
  return {
    type: sessionActions.SUCCESS_LOGIN,
    payload: user,
  };
}

export function errorLogin(error) {
  return {
    type: sessionActions.ERROR_LOGIN,
    payload: error,
  };
}

export function cleanErrorLogin() {
  return {
    type: sessionActions.CLEAN_ERROR_LOGIN,
  };
}

export function setCurrentUser(user) {
  return {
    type: sessionActions.SET_CURRENT_USER,
    payload: user,
  };
}

export function clearToken() {
  window.localStorage.accessToken = '';
  return {
    type: sessionActions.LOGOUT,
  };
}

export function setRedirectPath(redirectPath) {
  return {
    type: sessionActions.SET_REDIRECT_PATH,
    payload: redirectPath,
  };
}

export function logout(redirectPath = '/explorer') {
  return dispatch => {
    dispatch(setRedirectPath(redirectPath));
    dispatch(clearToken());
    dispatch(ProjectsActions.clearProjects());
    dispatch(ApplicationsActions.clearApplications());
    dispatch(TestsActions.clearTests());
    dispatch(TransactionsActions.clearTransactions());
  };
}

export function refreshCurrentUser() {
  return dispatch => {
    return UserService.getCurrentUser()
      .then(user => dispatch(setCurrentUser(user)))
      .catch(() => dispatch(logout()));
  };
}

export function login(username, password) {
  return dispatch => {
    dispatch(startLogin());
    return LoginService.login(username, password)
      .then(user => {
        window.localStorage.accessToken = user.token;
        dispatch(successLogin(user));
      })
      .catch(err => {
        dispatch(errorLogin(err.error));
      });
  };
}

export function updateDateRange(date) {
  return {
    type: sessionActions.UPDATE_DATE_RAGE,
    payload: date,
  };
}

export function updateTablesState(newState) {
  return {
    type: sessionActions.UPDATE_TABLES_STATE,
    payload: newState,
  };
}

export function updateTableState(tableId, newState) {
  return (dispatch, getState) => {
    const { tables } = getState().session;

    dispatch(
      updateTablesState({
        ...tables,
        [tableId]: newState,
      })
    );
  };
}

export function clearTableFilters(tableId) {
  return (dispatch, getState) => {
    const { tables } = getState().session;
    const tableCurrentState = tables[tableId] || {};

    dispatch(
      updateTablesState({
        ...tables,
        [tableId]: {
          ...tableCurrentState,
          sorted: undefined,
          filtered: undefined,
        },
      })
    );
  };
}
