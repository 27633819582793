import { testsActions } from './testsActions';

const initialState = {
  tests: [],
  isFetchingTests: false,
  fetchTestsError: null,
  favoriteScenarios: [],
  fetchFavoriteScenariosError: null,
};

const testsReducer = (state = initialState, action) => {
  switch (action.type) {
    case testsActions.START_FETCH_TESTS:
      return { ...state, isFetchingTests: true };
    case testsActions.SUCCESS_FETCH_TESTS:
      return { ...state, tests: action.payload, isFetchingTests: false };
    case testsActions.ERROR_FETCH_TESTS:
      return { ...state, fetchTestsError: action.payload, isFetchingTests: false };
    case testsActions.CLEAR_TESTS:
      return { ...state, tests: [] };
    case testsActions.START_FETCH_FAVORITE_SCENARIOS:
      return { ...state };
    case testsActions.SUCCESS_FETCH_FAVORITE_SCENARIOS:
      return { ...state, favoriteScenarios: action.payload };
    case testsActions.ERROR_FETCH_FAVORITE_SCENARIOS:
      return { ...state, fetchFavoriteScenariosError: action.payload };
    default:
      return state;
  }
};

export default testsReducer;
