/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import logo from '../../../assets/images/logo-white.svg';
import { SessionActions } from '../../user';
// eslint-disable-next-line import/no-cycle
import Navbar from '../components/Navbar/Navbar';

const withNavbar = WrappedComponent => {
  const component = ({ user, logoutUser, ...rest }) => {
    return (
      <>
        <header className="main-header">
          <Link to="/explorer">
            <img src={logo} alt="logo" />
          </Link>
          <Navbar user={user} signOut={logoutUser} />
        </header>
        <div className="main-container">
          <WrappedComponent {...rest} />
        </div>
      </>
    );
  };

  component.defaultProps = {
    user: {},
  };

  component.propTypes = {
    user: PropTypes.object,
    logoutUser: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => ({
    user: state.session.user,
  });

  const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(SessionActions.logout()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(component);
};

withNavbar.propTypes = {
  signOut: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default withNavbar;
