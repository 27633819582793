import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { PropTypes } from 'prop-types';

import Roles from '../../../../../containers/auth/Roles';
import { roles, tables } from '../../../../../enums';
import { Button } from '../../../../shared/components';
import { alerts } from '../../../../shared/utils';
import * as sessionActions from '../../../../user/store/sessionActions';
import { TestFieldsService } from '../../Tests';
import { fetchTestFields } from '../../Tests/store/testFieldsActions';

import AddField from './AddField/AddField';
import ScnField from './ScnField/ScnField';

import styles from './ScenarioBuilder.module.scss';

const buttonStates = {
  apply: {
    text: 'Apply',
    icon: 'arrow-circle-down',
  },
  refresh: {
    text: 'Refresh',
    icon: 'refresh',
  },
};

const ScenarioBuilder = ({
  currentScenario,
  setCurrentScenario,
  isFetchingTestFields,
  testFields,
  clearTableFilters,
  getTestFields,
  project,
}) => {
  const [fields, setFields] = useState([]);
  const [buttonContent, setButtonContent] = useState(buttonStates.refresh);
  const [isFieldsContainerOpen, setIsFieldsContainerOpen] = useState(false);
  const dropdownLikeRef = useRef();
  const fieldsContainerRef = useRef();
  const isScenarioChanged = !(buttonContent === buttonStates.refresh);

  useEffect(() => setFields([...testFields]), [testFields]);

  const setScenarioFields = () => {
    setFields(currentFields => {
      const updatedFields = currentFields.map(field => {
        field.checked = currentScenario.fields.includes(field.field_name);
        return field;
      });

      return [...updatedFields];
    });
  };

  useEffect(setScenarioFields, [currentScenario, testFields]);

  const changeScenario = useCallback(
    scenario => {
      localStorage.setItem('selectedScenario', JSON.stringify(scenario));
      setCurrentScenario(scenario);
    },
    [setCurrentScenario]
  );

  const getChecked = allFields => allFields.filter(field => field.checked);
  const getCheckedFields = useCallback(allFields => getChecked(allFields).map(field => field.field_name), []);

  const onApplyScenario = useCallback(() => {
    const checkedFields = getCheckedFields(fields);
    if (checkedFields.length > 0) {
      const customScenario = {
        label: 'Custom Scenario',
        fields: checkedFields,
      };
      changeScenario(customScenario);
    } else {
      changeScenario({ ...currentScenario });
    }
    setButtonContent(buttonStates.refresh);
  }, [getCheckedFields, fields, changeScenario, currentScenario]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isFieldsContainerOpen &&
        !fieldsContainerRef.current.contains(event.target) &&
        !dropdownLikeRef.current.contains(event.target)
      ) {
        setIsFieldsContainerOpen(false);
        if (isScenarioChanged) onApplyScenario(true);
      }
    };

    if (isFieldsContainerOpen) document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFieldsContainerOpen, isScenarioChanged, onApplyScenario]);

  const handleFieldClick = fieldId => {
    setFields(currentFields => {
      const field = currentFields.find(element => {
        return element._id === fieldId;
      });

      field.checked = !field.checked;

      const checkedFields = getCheckedFields(currentFields);
      if (isEqual(checkedFields.sort(), currentScenario.fields.sort())) {
        setButtonContent(buttonStates.refresh);
      } else {
        setButtonContent(buttonStates.apply);
      }

      return [...currentFields];
    });
  };

  const onFieldDelete = async (fieldId, fieldName) => {
    try {
      await TestFieldsService.delete(fieldId, project._id);

      const newFields = currentScenario.fields.filter(field => field !== fieldName);
      changeScenario({ ...currentScenario, fields: newFields });

      getTestFields(project._id);
    } catch (error) {
      alerts.error('An error ocurred during the deletion of the test field.');
    }
  };

  const onInputClick = () => {
    if (isFieldsContainerOpen && isScenarioChanged) onApplyScenario(true);
    setIsFieldsContainerOpen(!isFieldsContainerOpen);
  };

  return (
    <>
      <span className={`${styles.Columns} ${styles.openList}`}>
        <div className={styles.formGroup}>
          <span className="form-group-label">Discriminated tests by</span>
          <div
            className={styles.DropdownLikeContainer}
            onClick={onInputClick}
            onKeyDown={onInputClick}
            ref={dropdownLikeRef}
          >
            <div className={styles.DropdownLike}>
              <div className={styles.DropdownLikeWrapper}>
                {!isFetchingTestFields &&
                  fields.length > 0 &&
                  getChecked(fields).map(field => (
                    <span key={field._id} className={styles.FieldLabel} title={field.label}>
                      {field.label}
                    </span>
                  ))}
              </div>
              <Icon className={`${styles.ArrowIcon} icon`} name={isFieldsContainerOpen ? 'angle-up' : 'angle-down'} />
            </div>
          </div>
          <div
            className={`${styles.FieldsContainer} ${isFieldsContainerOpen ? `${styles.FieldsContainerOpen}` : ''}`}
            ref={fieldsContainerRef}
          >
            <Roles admit={[roles.DEVELOPER]} className={`${styles.Columns2}`}>
              <AddField />
            </Roles>
            <div className={styles.FieldsWrapper}>
              {!isFetchingTestFields &&
                fields.length > 0 &&
                fields.map(field => (
                  <ScnField
                    key={field._id}
                    fieldId={field._id}
                    label={field.label}
                    fieldName={field.field_name}
                    checked={field.checked}
                    editable={!field.default}
                    handleClick={handleFieldClick}
                    onDelete={onFieldDelete}
                  />
                ))}
            </div>
            <div className={styles.ScenarioActions}>
              <Button icon={buttonContent.icon} text={buttonContent.text} onClick={() => onApplyScenario(true)} />
            </div>
          </div>
        </div>
      </span>
      <span className={`${styles.Controls}`}>
        <Button
          text="Clear Filters"
          className={styles.ClearFilters}
          type="secondary"
          onClick={() => clearTableFilters(tables.TESTS)}
        />
        <Button
          title="Refresh"
          icon={buttonStates.refresh.icon}
          type="secondary"
          className={styles.RefreshButton}
          onClick={() => onApplyScenario(true)}
        />
      </span>
      <span className={`${styles.Bg} ${isFieldsContainerOpen ? `${styles.BgVisible}` : ''}`}></span>
    </>
  );
};

ScenarioBuilder.propTypes = {
  currentScenario: PropTypes.object.isRequired,
  setCurrentScenario: PropTypes.func.isRequired,
  testFields: PropTypes.array.isRequired,
  isFetchingTestFields: PropTypes.bool.isRequired,
  clearTableFilters: PropTypes.func.isRequired,
  getTestFields: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  testFields: state.testFields.testFields,
  isFetchingTestFields: state.testFields.isFetchingTestFields,
  project: state.projects.currentProject,
});

const mapDispatchToProps = dispatch => ({
  clearTableFilters: tableId => dispatch(sessionActions.clearTableFilters(tableId)),
  getTestFields: projectId => dispatch(fetchTestFields(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioBuilder);
