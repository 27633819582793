import SignUpService from '../services/signUpService';

export const userActions = {
  START_SIGNUP: '[USER] Start Sign Up',
  SUCCESS_SIGNUP: '[USER] Success Sign Up',
  ERROR_SIGN_UP: '[USER] Error Sign Up',
};

export function startSignUp() {
  return {
    type: userActions.START_SIGNUP,
  };
}

export function successSignUp(user) {
  return {
    type: userActions.SUCCESS_SIGNUP,
    payload: user,
  };
}

export function errorSignUp(error) {
  return {
    type: userActions.ERROR_SIGN_UP,
    payload: error,
  };
}

export function signUp(username, email, password) {
  return dispatch => {
    dispatch(startSignUp());
    return SignUpService.signUp(username, email, password)
      .then(user => {
        dispatch(successSignUp(user));
      })
      .catch(err => {
        dispatch(errorSignUp(err));
        throw err;
      });
  };
}
