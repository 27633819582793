/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { PageNotFound, ServiceUnavailable } from '../../modules/errors';
import LandingPage from '../../modules/LandingPage/LandingPage';
import PrivateRoute from '../../modules/shared/router/PrivateRoute';
import hasRoles from '../../modules/shared/utils/hasRoles';
import ConfirmAccount from '../../modules/user/ConfirmAccount/ConfirmAccount';

import routes from './routes';

import styles from '../../App.module.scss';

const Pages = ({ user, redirectPath }) => {
  const location = useLocation();

  return (
    <div className={styles.App}>
      <Switch>
        <Route path="/503" component={ServiceUnavailable} />
        <Route exact path="/" render={props => (user ? <Redirect to={redirectPath} /> : <LandingPage {...props} />)} />

        {routes.map(
          route =>
            hasRoles(route.roles) && <PrivateRoute key={route.path} path={route.path} component={route.component} />
        )}

        <Route path="/confirmAccount/:token" component={ConfirmAccount} />
        <Route path="/404" component={PageNotFound} />
        {!user && location.pathname !== '/' && <Redirect to="/" />}
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
};

Pages.defaultProps = {
  user: null,
  redirectPath: '/explorer',
};

Pages.propTypes = {
  user: PropTypes.object,
  redirectPath: PropTypes.string,
};

const mapStateToProps = state => ({
  user: state.session.user,
  redirectPath: state.session.redirectPath,
});

export default connect(mapStateToProps)(Pages);
