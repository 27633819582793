import React, { useState } from 'react';
import Icon from 'react-fa';
import { PropTypes } from 'prop-types';

import { CheckBox } from '../../../../../shared/components';

import styles from './ScnField.module.scss';

const ScnField = ({ label, fieldName, checked, handleClick, onDelete, fieldId, editable }) => {
  const [hover, setHover] = useState(false);
  const [isDeleteLoading, setIsDeleteIsLoading] = useState(false);

  const onClick = () => {
    handleClick(fieldId);
  };

  const onClickDelete = async e => {
    e.stopPropagation();
    setIsDeleteIsLoading(true);
    onDelete(fieldId, fieldName, checked);
  };

  return (
    <div
      className={styles.Wrapper}
      title={label}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className={styles.Container}>
        <div className={styles.CheckBoxContainer}>
          <CheckBox checked={checked} hover={hover} />
        </div>
        <div className={styles.Content}>
          <span className={styles.Label}>{label}</span>
          <span title={fieldName} className={styles.FieldName}>
            ({fieldName})
          </span>
        </div>
      </div>
      {editable && (
        <span
          title={`Delete ${label} field`}
          onClick={onClickDelete}
          onKeyDown={onClickDelete}
          className={styles.DeleteIcon}
        >
          <Icon spin={isDeleteLoading} name={isDeleteLoading ? 'spinner' : 'times'} />
        </span>
      )}
    </div>
  );
};

ScnField.defaultProps = {
  checked: false,
  editable: false,
};

ScnField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fieldId: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

export default ScnField;
