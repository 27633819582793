import React from 'react';
import Icon from 'react-fa';
import { isUndefined } from 'lodash';
import { PropTypes } from 'prop-types';

import { parseIfNumber } from '../../../../../shared/utils/utils';

import styles from './MetricCard.module.scss';

const MetricCard = ({ title, value, increaseIsPositive }) => {
  const increase = value > 0;

  const getColor = () => {
    if (isUndefined(value)) return '#adadad';
    if (value === 0) return '#9E9E9E';
    if ((increaseIsPositive && increase) || (!increaseIsPositive && !increase)) return '#14cc45';
    if ((increaseIsPositive && !increase) || (!increaseIsPositive && increase)) return '#eb2617';

    return '#000';
  };

  const getIcon = () => {
    if (isUndefined(value)) return 'times';
    if (value === 0) return 'arrow-right';
    if (increase) return 'arrow-up';
    if (!increase) return 'arrow-down';

    return 'arrow-up';
  };

  return (
    <div className={styles.Card}>
      <div className={styles.Content}>
        <div className={styles.Arrow} style={{ color: getColor() }}>
          <Icon name={getIcon()} />
        </div>
        <div className={styles.Info}>
          {isUndefined(value) ? (
            <span className={styles.NoData}>No data to compare</span>
          ) : (
            <>
              <span className={styles.Value}>{parseIfNumber(Math.abs(value))}</span>
              <span className={styles.Percentage}>%</span>
            </>
          )}
        </div>
      </div>
      <div className={styles.Title}>{title}</div>
    </div>
  );
};

MetricCard.defaultProps = {
  increaseIsPositive: true,
  value: undefined,
};

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  increaseIsPositive: PropTypes.bool,
};

export default MetricCard;
