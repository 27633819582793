/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PackageModal from 'react-modal';
import { PropTypes } from 'prop-types';

PackageModal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: '17px',
    textAlign: 'center',
    position: 'fixed',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'visible',
    borderRadius: '5px',
  },
};

const Modal = ({ children, isOpen, onCloseModal, ...rest }) => {
  return (
    <PackageModal ariaHideApp={false} isOpen={isOpen} onRequestClose={onCloseModal} style={customStyles} {...rest}>
      {children}
    </PackageModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Modal;
