const tables = {
  TESTS: 'tests',
  PROJECTS: 'projects',
  APPLICATIONS: 'applications',
  TRANSACTIONS: 'transactions',
  CLIENTS: 'clients',
  COMPARE_METRICS: 'compare-metrics',
  RESPONSE_CODE_DETAILED: 'response-code-detailed',
  ENDPOINTS_STATISTICS: 'endpoints-statistics',
  FAVORITE_SCENARIOS: 'favorite-scenarios',
};

export default tables;
