import React from 'react';
import Icon from 'react-fa';

import { Button } from '../../shared/components';

import styles from './PageNotFound.module.scss';

const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <div className={styles.big}>
          <Icon name="exclamation-triangle" />
        </div>
      </div>
      <div className="">
        <h1 className={styles.code}>404</h1>
      </div>
      <div className={styles.message}>Page not found</div>
      <Button
        text="Home"
        onClick={() => {
          window.location = '/';
        }}
      />
    </div>
  );
};

export default PageNotFound;
