const colors = [
  '#C15C17',
  '#447EBC',
  '#AA4465',
  '#C8963E',
  '#17a2b8',
  '#721121',
  '#8884d8',
  '#C97C5D',
  '#7A6263',
  '#82ca9d',
  '#567568',
  '#004E98',
  '#21897E',
  '#D1462F',
  '#ECD444',
  '#ECBA82',
  '#CCA43B',
  '#F19A3E',
  '#131200',
  '#8E3B46',
  '#6E2594',
  '#694873',
  '#6F1A07',
  '#1D8A99',
  '#372549',
  '#B75D69',
];

export default colors;
