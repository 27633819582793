/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { TabPanel } from 'react-tabs';
import { PropTypes } from 'prop-types';

const Tab = ({ children, tabTitle, ...otherProps }) => {
  return <TabPanel {...otherProps}>{children}</TabPanel>;
};

Tab.defaultProps = {
  disabled: false,
};

Tab.propTypes = {
  tabTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Tab.tabsRole = 'TabPanel';

export default Tab;
