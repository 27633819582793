import { parseIfNumber, parseMilliseconds } from '../modules/shared/utils/utils';

const metrics = {
  THREADS: {
    label: 'Virtual Users',
    accessor: 'threads',
  },
  AVERAGE_SUCCESSFUL_RESPONSE_TIME: {
    label: 'Avg. Successful Response time',
    accessor: 'averageSuccessfulResponseTime',
    unit: 'ms',
    formatter: value => {
      const [parsedValue, units] = parseMilliseconds(value);
      return `${parseIfNumber(parsedValue)}${units}`;
    },
  },
  TOTAL_SUCCESSFUL_TPS: {
    label: 'Successful Requests/s',
    accessor: 'totalSuccessfulThroughputTps',
    unit: 'rps',
    formatter: value => `${parseIfNumber(value)}rps`,
  },
  FAILED_REQUESTS_PERCENTAGE: {
    label: 'Failed Requests (%)',
    accessor: 'failedSamplesPercentage',
    unit: '%',
    formatter: value => `${parseIfNumber(value)}%`,
  },
  SAMPLES: {
    label: 'Requests',
    accessor: 'samples',
  },
  FAILED_REQUESTS: {
    label: '',
    accessor: 'failedSamples',
  },
  MIN_SUCCESSFUL_RESPONSE_TIME: {
    label: 'Minimum',
    accessor: 'minSuccessfulResponseTime',
  },
  MAX_SUCCESSFUL_RESPONSE_TIME: {
    label: 'Maximum',
    accessor: 'maxSuccessfulResponseTime',
  },
  PERCENTILE90_SUCCESSFUL_RESPONSE_TIME: {
    label: '90th Percentile',
    accessor: 'percentile90SuccessfulResponseTime',
  },
  PERCENTILE95_SUCCESSFUL_RESPONSE_TIME: {
    label: '95th Percentile',
    accessor: 'percentile95SuccessfulResponseTime',
  },
  PERCENTILE99_SUCCESSFUL_RESPONSE_TIME: {
    label: '99th Percentile',
    accessor: 'percentile99SuccessfulResponseTime',
  },
  RESPONSE_CODE: {
    label: 'Response Code',
    accessor: 'responseCode',
  },
  RESPONSE_MESSAGE: {
    label: 'Response Message',
    accessor: 'responseMessage',
  },
  FAILURE_MESSAGE: {
    label: 'Failure Message',
    accessor: 'failureMessage',
  },
  STATUS: {
    label: 'Status',
    accessor: 'status',
  },
};

export default metrics;
