import React from 'react';
import { PropTypes } from 'prop-types';

import { parseIfNumber } from '../../../../shared/utils/utils';

import styles from './MultipleScenariosFields.module.scss';

const MultipleScenariosFields = ({ label, fieldName, values }) => {
  return (
    <div className={styles.Field} title={fieldName}>
      <div className={styles.LabelContainer}>
        <span className={styles.Label}>{label}</span>
      </div>
      {values.map((value, index) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`${styles.ValueContainer} ${!value && styles.IsUndefined}`}
          >
            <span className={styles.Value}>{parseIfNumber(value) || 0}</span>
          </div>
        );
      })}
    </div>
  );
};

MultipleScenariosFields.defaultProps = {
  fieldName: '',
};

MultipleScenariosFields.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  values: PropTypes.array.isRequired,
};

export default MultipleScenariosFields;
