const sanitizeDateRangeObject = dateRange => {
  return {
    startDate: new Date(dateRange.startDate),
    endDate: new Date(dateRange.endDate),
    key: dateRange.key,
  };
};

const calculatePercentageChange = (baseline, value) => {
  return Math.round(((value - baseline) / (baseline || 100)) * 100);
};

const calculatePercentage = (value, total) => Number(((value / total) * 100).toFixed(2));

const isNumber = value => !Number.isNaN(Number(value));

const parseIfNumber = value => {
  if (!isNumber(value)) {
    return value || null;
  }

  const options = {};

  if (value > 1 || value < 0) {
    options.maximumFractionDigits = 1;
  }

  return Number(value).toLocaleString('en-US', options);
};

const parseMilliseconds = value => {
  if (value > 60000) {
    return [Number(value / 60000).toFixed(1), 'min'];
  }
  if (value > 1000) {
    return [Number(value / 1000).toFixed(1), 's'];
  }
  return [Number(value).toFixed(1), 'ms'];
};

const formatDateToLocale = date =>
  `${date.toLocaleDateString()} - ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}`;

const genericTableFilterMethod = accessor => (filter, row) => {
  if (!row[accessor]) return false;

  if (!isNumber(row[accessor])) {
    const rowName = row[accessor] && row[accessor].toLowerCase();
    return rowName.includes(filter.value.toLowerCase());
  }

  const strNumber = (row[accessor] || row[accessor] === 0) && String(row[accessor]);
  return strNumber.startsWith(filter.value.toLowerCase());
};

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export {
  sanitizeDateRangeObject,
  calculatePercentageChange,
  calculatePercentage,
  parseMilliseconds,
  parseIfNumber,
  isNumber,
  formatDateToLocale,
  genericTableFilterMethod,
  capitalize,
};
